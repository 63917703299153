import React from 'react';
import { NavDropdown } from '@orchard/frontend-react-components';
import { useAppConfig } from '@theorchard/suite-config';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { PROFILE_LINK, SWITCH_USER_LINK, LOGOFF_LINK } from 'src/constants';
import IdentityContext from 'src/context';
import { formatMessage } from 'src/utils';
import { getSwitchKeySessId } from 'src/utils/auth';

const CLASS_NAME = 'LinkedAccountsDropdown';

export const LinkedMenuItem = ({
    accountName,
    accountId,
    href,
    checkmark,
    isSubaccount,
    vendorName,
}) => (
    <NavDropdown.Item className={`${CLASS_NAME}-linked-menu-item`} href={href}>
        <div className={`${CLASS_NAME}-linked-menu-item-row`}>
            <div className={`${CLASS_NAME}-account-title`}>
                {isSubaccount && (
                    <div className={`${CLASS_NAME}-vendor-name`}>
                        {vendorName}
                    </div>
                )}
                <div className={`${CLASS_NAME}-account-name`}>
                    <div className={`${CLASS_NAME}-vendor-or-subaccount`}>
                        {accountName}
                    </div>
                    &nbsp;:&nbsp;
                    <div className={`${CLASS_NAME}-vendor-or-subaccount-id`}>
                        {accountId}
                    </div>
                </div>
            </div>
            {checkmark && <div className={`${CLASS_NAME}-checkmark`} />}
        </div>
    </NavDropdown.Item>
);
LinkedMenuItem.propTypes = {
    accountName: PropTypes.string,
    accountId: PropTypes.number,
    isSubaccount: PropTypes.bool,
    vendorName: PropTypes.string,
    href: PropTypes.string,
    checkmark: PropTypes.bool,
};

const LinkedAccountsDropdown = ({
    className,
    linkedAccounts,
    title,
    accountName,
    accountId,
    isSubaccount,
    vendorName,
    isEmbedOnPhp,
}) => {
    const config = useAppConfig();
    const { identity } = React.useContext(IdentityContext);

    const NavDropDownButton = (
        <NavDropdown.Item
            id="NavDropdown-logout"
            onClick={() => {
                if (isEmbedOnPhp) window.location.href = LOGOFF_LINK;
            }}
        >
            {formatMessage('header.dropdown.logOut')}
        </NavDropdown.Item>
    );

    return (
        <NavDropdown title={title} id="header-dropdown" className={className}>
            {linkedAccounts.length > 0 && (
                <LinkedMenuItem
                    accountName={accountName}
                    accountId={accountId}
                    isSubaccount={isSubaccount}
                    vendorName={vendorName}
                    href={PROFILE_LINK}
                    checkmark
                />
            )}
            {linkedAccounts.length > 0 &&
                linkedAccounts.map(
                    ({
                        vcId,
                        vendorId,
                        company,
                        subaccountId,
                        subaccountName,
                    }) => (
                        <LinkedMenuItem
                            key={`account-${vcId}`}
                            accountId={subaccountId || vendorId}
                            accountName={subaccountName || company}
                            isSubaccount={Boolean(subaccountId)}
                            vendorName={company}
                            href={SWITCH_USER_LINK(
                                vcId,
                                getSwitchKeySessId(config, identity)
                            )}
                        />
                    )
                )}
            <NavDropdown.Item href={PROFILE_LINK}>
                {formatMessage('header.dropdown.myAccount')}
            </NavDropdown.Item>

            {!isEmbedOnPhp ? (
                <LinkContainer to={LOGOFF_LINK}>
                    {NavDropDownButton}
                </LinkContainer>
            ) : (
                NavDropDownButton
            )}
        </NavDropdown>
    );
};

LinkedAccountsDropdown.propTypes = {
    className: PropTypes.string.isRequired,
    linkedAccounts: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.node.isRequired,
    isSubaccount: PropTypes.bool,
    vendorName: PropTypes.string,
    accountName: PropTypes.string,
    accountId: PropTypes.number,
    isEmbedOnPhp: PropTypes.bool,
};

LinkedAccountsDropdown.defaultProps = {
    linkedAccounts: [],
};

export default LinkedAccountsDropdown;
