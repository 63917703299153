import { Sentry } from '@theorchard/suite-frontend';
import { OWS_USERS_SESSION_URL, OWS_FEATURES_URL } from 'src/constants/urls';
import GET_USER_APPLICATIONS_QUERY from 'src/queries/get-user-applications-query';
import api, { getGqlClient } from 'src/utils/api';

export const getUserFeatures = () =>
    api
        .get(OWS_FEATURES_URL, { formatResponse: false })
        .then(({ body: userFeatures }) => userFeatures);

export const getUserSession = (queryParams = '') =>
    api.get(`${OWS_USERS_SESSION_URL}${queryParams}`).then(({ body }) => body);

export const getUserApplications = identityId => {
    const gqlClient = getGqlClient();

    return gqlClient
        ?.query({
            query: GET_USER_APPLICATIONS_QUERY,
            variables: { identityId },
        })
        .then(result => result?.data?.identityById?.applications)
        .catch(error => {
            Sentry.captureException(error);
            return [];
        });
};

export const services = {
    getUserFeatures,
    getUserSession,
    getUserApplications,
};

export const getUser = async () => {
    const [featureFlags, user] = await Promise.all([
        services.getUserFeatures(),
        services.getUserSession('?extra=identity'),
    ]);

    return {
        featureFlags,
        user,
    };
};
