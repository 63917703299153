import { SuitePlugin } from '@theorchard/suite-frontend';
import { WorkstationConfig } from 'src/types';
import { WorkstationAuthClient } from './authClient';
import { ImpersonatedAuthClient } from './impersonatedClient';
import { onInit } from './init';
import { Wrapper } from './wrapper';

export const WorkstationPlugin: SuitePlugin = {
    name: 'Workstation',
    Wrapper,
    onInit,
    onAuthClientCreate: async config => {
        const workstationConfig = config as WorkstationConfig;

        if (ImpersonatedAuthClient.isImpersonated(workstationConfig))
            return new ImpersonatedAuthClient(workstationConfig);

        return new WorkstationAuthClient(workstationConfig);
    },
};
