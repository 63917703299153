import React from 'react';
import PropTypes from 'prop-types';
import { formatMessage } from '../../utils';

export const TERM_GROUP = 'errors';
export const DEFAULT_TERM = 'default';
export const UNKNOWN_CODE = 'CLIENT';
export const UNKNOWN_TEXT = 'ERROR';

export const extractMessage = error => {
    if (typeof error === 'string') return error;

    const {
        message: msg,
        error: inner = {},
        statusCode = UNKNOWN_CODE,
        statusText = UNKNOWN_TEXT,
    } = error;

    const { message = msg || `${statusCode} ${statusText}` } = inner;

    return message;
};

const ErrorComponent = ({ error = '', term = DEFAULT_TERM }) => (
    <div className="ErrorMessage">
        <div className="error-text">
            {formatMessage(`${TERM_GROUP}.${term}`)}
        </div>
        {error && <div className="error-details">{extractMessage(error)}</div>}
    </div>
);

ErrorComponent.propTypes = {
    term: PropTypes.string,
    error: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            message: PropTypes.string,
        }),
    ]),
};

export default ErrorComponent;
