import { FEATURE_HEADER_ICON_ENDPOINT } from 'src/constants/permissions';
import {
    OWS_ASSETS_URL,
    IMAGES_DEV_CDN_URL,
    IMAGES_CDN_URL,
} from 'src/constants/urls';
import api from 'src/utils/api';
import { isDev } from 'src/utils/environment';

const vendorIconUrl = (vendorId, assetId) =>
    `${OWS_ASSETS_URL}/image/vendor/${vendorId}/asset/${assetId || 0}`;

const vendorIconPath = (vendorId, assetId) =>
    `${isDev() ? IMAGES_DEV_CDN_URL : IMAGES_CDN_URL}` +
    `/vendor/vendor_icon/${vendorId}/${vendorId}/${assetId}.png`;

const imageLocations = (entityType, entityIds, imageFormat = 'cover') =>
    `${OWS_ASSETS_URL}/image/${entityType}/${imageFormat}/location?ids=${entityIds.join(
        ','
    )}`;

export const getVendorIconUrl = async identity => {
    const { vendor: { vendorId, imageAssetId } = {} } = identity;

    if (!vendorId) return null;

    if (identity.hasFeatureFlag(FEATURE_HEADER_ICON_ENDPOINT)) {
        const { text } = await api.get(vendorIconUrl(vendorId, imageAssetId));
        if (text) return text;
    }

    if (imageAssetId) return vendorIconPath(vendorId, imageAssetId);

    return null;
};

export const getProductImageUrls = async (productIds, imageFormat) => {
    if (!productIds.length) return {};

    const { body } = await api.get(
        imageLocations('product', productIds, imageFormat)
    );
    return body;
};

export default {
    getProductImageUrls,
    getVendorIconUrl,
};
