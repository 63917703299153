import { VARIANT_ENABLED } from 'src/constants/permissions';
import Identity from './Identity';

// TBR: Support legacy implementation of feature flag checking
const addLegacyFeatureSupport = (featureFlags, overrides) => {
    window.getUserFeatures = () => ({
        ...(window.getUserFeaturesDefaults
            ? window.getUserFeaturesDefaults()
            : {}),
        ...featureFlags,
        ...(window.getUserFeaturesOverrides
            ? window.getUserFeaturesOverrides()
            : {}),
        ...overrides,
    });
};

export default class IdentityManager {
    static instance = new IdentityManager();

    userFeatureFlags = {};
    userRestrictedFeatures = [];

    constructor(identity) {
        this.identity = identity;
    }

    setFeatureFlags(featureFlags) {
        this.featureFlags = {
            ...featureFlags,
            ...this.userFeatureFlags,
        };

        addLegacyFeatureSupport(featureFlags, this.userFeatureFlags);
    }

    setOverrides(overrides) {
        const { userFeatureFlags = {}, userRestrictedFeatures = [] } =
            overrides;

        this.userFeatureFlags = userFeatureFlags;
        this.userRestrictedFeatures = userRestrictedFeatures;
    }

    setIdentity(identityData) {
        const { userRestrictedFeatures, featureFlags } = this;

        this.identity = new Identity(identityData, {
            featureFlags,
            userRestrictedFeatures,
        });
    }

    hasPermission(perms) {
        if (!this.identity) return false;
        return this.identity.hasPermission(perms);
    }

    hasFeatures(features) {
        if (!this.identity) return false;
        return this.identity.hasFeatureControl(features);
    }

    hasFeatureFlag(featureFlag, variant = VARIANT_ENABLED) {
        if (!featureFlag) return false;
        if (!this.featureFlags) return false;

        return this.featureFlags[featureFlag] === variant;
    }
}
