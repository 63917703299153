import { FeatureFlagDictionary } from '@orchard/frontend-identity';
import { merge } from 'lodash';
import {
    VARIANT_ENABLED,
    FEATURE_IS_GATED_DIY,
    USER_SERVICE_TIER_GATED_DIY,
} from 'src/constants/permissions';
import {
    VendorContactRole,
    Vendor,
    WorkstationIdentityData,
    WorkstationUser,
} from 'src/types';
import authenticationHelper from './authentication-helper';
import { ensureArray, toLookup } from './collection';

interface IdentityOptions {
    featureFlags?: FeatureFlagDictionary;
    userRestrictedFeatures?: string[];
}

export default class Identity {
    static flattenRoles(roles: VendorContactRole[]): Record<string, string> {
        return roles.reduce(
            (map, { role }) => ({
                ...map,
                ...role.vendorRolePermissions.reduce(
                    (
                        perms,
                        {
                            permission: {
                                resource: { resource },
                                privilege,
                            },
                        }
                    ) => ({ ...perms, [resource]: privilege }),
                    {}
                ),
            }),
            {}
        );
    }

    identityId?: string;
    vendor?: Vendor;
    roles: VendorContactRole[];
    featureFlags: FeatureFlagDictionary;
    permissions: Record<string, string>;
    restrictedFeatures: Record<string, boolean>;
    identity?: WorkstationIdentityData;

    id?: number;
    vendorId?: number;
    subaccountId?: string;

    /**
     * @deprecated Use hasPermission or hasFeatureFlag
     */
    hasAuthentication: (options: {
        rolesToCheck?: string[];
        featuresToCheck?: string[];
    }) => boolean;

    constructor(user: WorkstationUser = {}, config: IdentityOptions = {}) {
        const { vendContactRoles = [], vendor, subaccount } = user;
        const vendorRestrictedFeatures = vendor?.vendorRestrictedFeatures ?? {};
        const { featureFlags = {}, userRestrictedFeatures = [] } = config;

        merge(this, user);

        this.identityId = user.identity?.id;
        this.vendor = vendor;
        this.roles = vendContactRoles;
        this.featureFlags = featureFlags;
        this.permissions = Identity.flattenRoles(vendContactRoles);
        this.restrictedFeatures = toLookup(
            vendorRestrictedFeatures,
            toLookup(userRestrictedFeatures)
        );

        this.vendorId = vendor?.vendorId;
        this.subaccountId = subaccount ? subaccount.subaccountId : undefined;

        this.hasAuthentication = authenticationHelper(
            vendContactRoles,
            vendorRestrictedFeatures
        );
    }

    isAnonymous() {
        return !(this.id && this.id > 0);
    }

    hasPermission(perms: string | string[]) {
        if (!perms) return true;
        if (!this.permissions) return false;

        return ensureArray(perms).every(
            ({ permission, privilege }) =>
                this.permissions[permission] !== undefined &&
                (!privilege || this.permissions[permission] === privilege)
        );
    }

    hasFeatureControl(features: string | string[]) {
        if (!features) return true;
        return ensureArray(features).every(
            feature => !this.restrictedFeatures[feature]
        );
    }

    hasFeatureFlag(featureFlag: string, variant = VARIANT_ENABLED) {
        if (!featureFlag) return false;
        if (!this.featureFlags) return false;

        return this.featureFlags[featureFlag] === variant;
    }

    isGatedDIY() {
        if (this.hasFeatureFlag(FEATURE_IS_GATED_DIY)) return true;

        return Boolean(
            this.vendor?.serviceTier?.name === USER_SERVICE_TIER_GATED_DIY
        );
    }

    isAwalUser() {
        const hasDefaultBrand = this?.identity?.defaultBrand === 'awal';
        const hasCompanyBrandName = this?.vendor?.companyBrand?.name === 'awal';
        const hasEmail = this.vendor?.assignedToEmail?.includes('@awal');

        return hasDefaultBrand || hasCompanyBrandName || hasEmail;
    }
}
