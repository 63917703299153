export const HELPCENTER_URL = 'https://helpcenter.theorchard.com';

export const HELPCENTER_URL_QA =
    'https://helpcentertheorchardcom-orchard.paas-s.smehost.net';

export const ORCHARD_GO_URI =
    '/track-analytics-on-the-go-with-the-orchardgo-app';

export const HOME_PAGE_TITLE = 'Workstation';

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_CDN_URL = 'https://cdn.theorchard.io';

export const WORKSTATION_UTILS = 'workstation-utils';
export const FRONTEND_REACT_COMPONENTS = 'frontend-react-components';

export const SUPPORT_EMAIL = 'clientservices@theorchard.com';

export const SCOPED_MODULES = [
    WORKSTATION_UTILS,
    '@orchard/frontend-localization',
    'frontend-localization',
];
export const LAST_VENDOR_ID_BEFORE_ANALYTICS_DEPRECIATION = 70084;

export const EVENT_HELP_CLICKED = 'Click - Help';

export const COMPANY_BRAND_THEORCHARD = 'theorchard';
export const BRAND_ORCHARD = 'orchard';
export const BRAND_AWAL = 'awal';
