import React from 'react';
import { InsightsIcon } from '@orchard/frontend-react-components';
import { useAppConfig } from '@theorchard/suite-config';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getEnvironmentBrandedLinkInsights } from 'src/components/header/header';
import FirstIcon from 'src/components/splashScreen/firstIcon';
import SecondIcon from 'src/components/splashScreen/secondIcon';
import ThirdIcon from 'src/components/splashScreen/thirdIcon';
import {
    LAST_VENDOR_ID_BEFORE_ANALYTICS_DEPRECIATION,
    PERMITTED_VENDOR_IDS_AFTER_ANALYTICS_DEPRECIATION,
} from 'src/constants';
import { formatMessage } from 'src/utils';

const CLASSNAME = 'SplashScreen';
const CLASSNAME_CONTAINER = `${CLASSNAME}-container`;
const CLASSNAME_HEADER = `${CLASSNAME}-header`;
const CLASSNAME_INSIGHTS = `${CLASSNAME}-insights`;
const CLASSNAME_IMAGES = `${CLASSNAME}-images`;
const CLASSNAME_INFO = `${CLASSNAME}-info`;
const CLASSNAME_BUTTON = `${CLASSNAME}-button`;
export const CLASSNAME_FOOTER = `${CLASSNAME}-footer`;
export const physicalReportingLink =
    '/analytics/reporting/physical/report/product-view';

const InsightsButton = () => {
    const config = useAppConfig();

    return (
        <div>
            <a
                href={getEnvironmentBrandedLinkInsights(config)}
                target="_blank"
                rel="noopener noreferrer"
                className={CLASSNAME_BUTTON}
            >
                {formatMessage('splashScreen.goToInsights')}
            </a>
        </div>
    );
};

const SplashScreen = ({ identity }) => {
    const sourceOfStreamsDisabled =
        !identity.hasFeatureControl('Source of Stream');
    const isPhysicalReportingFeatureControlEnabled = identity.hasFeatureControl(
        'Workstation Physical Reporting'
    );
    const userIsNewerThanInsightsLaunch =
        identity.vendorId > LAST_VENDOR_ID_BEFORE_ANALYTICS_DEPRECIATION;

    const userHasAccessAfterInsightsLaunch =
        PERMITTED_VENDOR_IDS_AFTER_ANALYTICS_DEPRECIATION.includes(
            identity.vendorId
        );

    if (
        sourceOfStreamsDisabled ||
        (userIsNewerThanInsightsLaunch && !userHasAccessAfterInsightsLaunch)
    )
        return <Redirect to={physicalReportingLink} />;

    return (
        <div className={CLASSNAME_CONTAINER}>
            <div className={CLASSNAME}>
                <div className={CLASSNAME_INSIGHTS}>
                    <InsightsIcon />
                    <span>{formatMessage('header.insights')}</span>
                </div>
                <div className={CLASSNAME_HEADER}>
                    {formatMessage('splashScreen.mainTitle')}
                </div>
                <div className={CLASSNAME_IMAGES}>
                    <FirstIcon />
                    <SecondIcon />
                    <ThirdIcon />
                </div>
                <div className={CLASSNAME_INFO}>
                    <div>
                        <h3>
                            {formatMessage('splashScreen.firstBlockHeader')}
                        </h3>
                        <span>
                            {formatMessage('splashScreen.firstBlockBody')}
                        </span>
                    </div>
                    <div>
                        <h3>
                            {formatMessage('splashScreen.secondBlockHeader')}
                        </h3>
                        <span>
                            {formatMessage('splashScreen.secondBlockBody')}
                        </span>
                    </div>
                    <div>
                        <h3>
                            {formatMessage('splashScreen.thirdBlockHeader')}
                        </h3>
                        <span>
                            {formatMessage('splashScreen.thirdBlockBody')}
                        </span>
                    </div>
                </div>
                <InsightsButton />
            </div>
            <div className={CLASSNAME_FOOTER}>
                <a href="/analytics/overview">
                    {formatMessage('splashScreen.declineMessage')}
                </a>
                {isPhysicalReportingFeatureControlEnabled && (
                    <a
                        href={`${physicalReportingLink}?utm_source=workstation&utm_medium=link&utm_campaign=SplashPhysicalReporting`}
                    >
                        {formatMessage('subheader.analytics.physicalReporting')}
                    </a>
                )}
            </div>
        </div>
    );
};

SplashScreen.propTypes = {
    identity: PropTypes.object,
};

export default SplashScreen;
