import React from 'react';
import {
    LogoIcon,
    OverdriveIcon,
    Nav,
} from '@orchard/frontend-react-components';
import { useAppConfig } from '@theorchard/suite-config';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';
import { HEADER_CLASSNAME, INDEX_LINK } from 'src/constants/header';
import IdentityContext from 'src/context';
import { getVendorIconUrl } from 'src/services/assets';
import hooks from 'src/utils/hooks';
import HeaderVendorIconLoader from './header-vendor-icon-loader';

export const CLASSNAME = `${HEADER_CLASSNAME}-logo`;
export const CLASSNAME_IMG = `${HEADER_CLASSNAME}-logoImage`;

const HeaderLogo = ({ isEmbedOnPhp }) => {
    const config = useAppConfig();
    const { identity } = React.useContext(IdentityContext);
    const { loading, data } = hooks.useRequest(getVendorIconUrl, identity);

    if (loading || identity.isAnonymous()) return <HeaderVendorIconLoader />;

    const getBrandIcon = () =>
        ['overdrive', 'awal'].includes(config.brand) ? (
            <OverdriveIcon />
        ) : (
            <LogoIcon />
        );

    const navLinkContent = data ? (
        <img
            className={CLASSNAME_IMG}
            src={data + '?' + Date.now()}
            alt="logo"
        />
    ) : (
        getBrandIcon()
    );

    // Link needs to be treated as a "LegacyLink" on php pages (as we do with SitemapItems)
    if (isEmbedOnPhp)
        return (
            <Nav.Link className={CLASSNAME} href={window.location.origin}>
                {navLinkContent}
            </Nav.Link>
        );

    return (
        <LinkContainer to={INDEX_LINK} activeClassName="">
            <Nav.Link className={CLASSNAME}>{navLinkContent}</Nav.Link>
        </LinkContainer>
    );
};
HeaderLogo.propTypes = {
    isEmbedOnPhp: PropTypes.bool,
};

export default HeaderLogo;
