/* eslint-disable */
/*
*   NOTE
*   This file is auto generated by @theorchard/frontend-cli-i18n.
*   Any manual changes will be overwritten!
*/
import en from './default.json';

const locales = {
    'en': en,
};

window.ORCHARD_LOCALES = locales;

export default locales;
