import LocalizationMngr from '@orchard/frontend-localization';
import { LocalizationManager as SuiteLocalizationManager } from '@theorchard/suite-frontend';
import { upperFirst } from 'lodash';
import authenticationHelper from './authentication-helper';
import IdentityManager from './IdentityManager';

export { ensureArray } from './collection';

const LocalizationManager = new LocalizationMngr();

const getGoAppName = brand =>
    ['overdrive', 'awal'].includes(brand) ? 'AWALGo' : `${upperFirst(brand)}Go`;

const redirectOffReact = offReactPath => {
    window.location.href = `${window.location.origin}${offReactPath}`;
    return null;
};

const formatMessage = (key, args) =>
    LocalizationManager.formatMessage(key, args);

const getCurrentLocale = () => LocalizationManager.getCurrentLocale();

const registerTranslations = (locales, locale) => {
    LocalizationManager.registerTranslations(locales, locale);

    SuiteLocalizationManager.instance.appendTranslations(locales);
};

export {
    IdentityManager,
    LocalizationManager,
    authenticationHelper,
    getGoAppName,
    redirectOffReact,
    formatMessage,
    getCurrentLocale,
    registerTranslations,
};
