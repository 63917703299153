/* eslint-disable max-len */
import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="342"
            height="262"
            fill="none"
            viewBox="0 0 342 262"
        >
            <path
                fill="#fff"
                d="M35.513 149.907H56.352999999999994V175.95600000000002H35.513z"
            />
            <path
                fill="#4460A0"
                fillRule="evenodd"
                d="M45.608 175.957H31.884a1.581 1.581 0 01-1.581-1.582v-25.491c0-.874.708-1.582 1.581-1.582h25.492c.873 0 1.581.708 1.581 1.582v25.491c0 .874-.708 1.582-1.581 1.582h-7.302V164.86h3.724l.558-4.324h-4.282v-2.761c0-1.252.348-2.105 2.143-2.105l2.29-.001v-3.868c-.396-.053-1.755-.171-3.337-.171-3.302 0-5.562 2.016-5.562 5.717v3.189h-3.734v4.324h3.734v11.097z"
                clipRule="evenodd"
            />
            <path fill="#F49A00" d="M286 139.642H291.201V142.762H286z" />
            <path
                fill="#DF5B35"
                d="M292.241 139.642H297.442V142.762H292.241z"
            />
            <path
                fill="#E67534"
                d="M292.241 135.481H297.442V138.601H292.241z"
            />
            <path
                fill="#F5A01F"
                d="M292.241 131.321H297.442V134.441H292.241z"
            />
            <path
                fill="#762498"
                d="M298.481 139.642H303.682V142.762H298.481z"
            />
            <path
                fill="#7D2790"
                d="M298.481 135.481H303.682V138.601H298.481z"
            />
            <path
                fill="#2D5FB8"
                d="M304.723 139.642H309.92400000000004V142.762H304.723z"
            />
            <path
                fill="#1D66B6"
                d="M304.723 135.481H309.92400000000004V138.601H304.723z"
            />
            <path
                fill="#0D9F7F"
                d="M304.723 131.321H309.92400000000004V134.441H304.723z"
            />
            <path
                fill="#29AB70"
                d="M304.723 127.16H309.92400000000004V130.28H304.723z"
            />
            <path
                fill="#1DA1F2"
                fillRule="evenodd"
                d="M107.064 220.642c1.027-.126 2.66-.048 2.981.371.244.318-.008 1.75-.422 2.777-.417 1.021-1.037 1.742-1.38 2.021-.348.278-.604.17-.419-.248.192-.419 1.241-3.013.825-3.558-.385-.503-2.132-.321-2.944-.236l-.164.017c-.172.015-.31.031-.422.043-.349.039-.442.05-.495-.058-.134-.377 1.411-1.006 2.44-1.129zm-24.266.42c4.763 2.904 12.136 7.399 23.887 1.806h-.001c.507-.211.861.142.36.782-.505.645-4.538 4.344-11.317 4.344-6.774 0-11.964-4.628-13.547-6.548-.434-.499.066-.724.36-.541l.258.157z"
                clipRule="evenodd"
            />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M102.507 220.597a.801.801 0 01-.906.092c-1.072-.89-1.402-1.379-1.898-2.113-.093-.138-.192-.285-.303-.444-2.104 2.147-3.592 2.788-6.322 2.788-3.225 0-5.74-1.99-5.74-5.976 0-3.113 1.69-5.232 4.09-6.268 1.746-.769 4.072-1.007 6.088-1.213.388-.04.765-.079 1.124-.119v-.497l.001-.285c.007-.845.014-1.786-.465-2.493-.47-.706-1.365-.998-2.153-.998-1.461 0-2.767.75-3.085 2.304-.065.345-.318.685-.663.701l-3.723-.399c-.312-.07-.658-.324-.571-.804.858-4.509 4.93-5.869 8.576-5.869 1.867 0 4.305.496 5.777 1.91 1.716 1.601 1.704 3.695 1.691 5.988l-.002.609v5.976c0 1.604.593 2.404 1.219 3.248.075.101.151.203.226.307.248.345.303.761-.01 1.02-.783.652-2.174 1.866-2.94 2.546l-.011-.011zm-3.867-9.145v-1.034c-2.773 0-5.702.593-5.702 3.862 0 1.656.858 2.778 2.33 2.778 1.079 0 2.044-.663 2.654-1.742.72-1.266.72-2.459.718-3.864z"
                clipRule="evenodd"
            />
            <path
                fill="#000F17"
                d="M269 177.976C269 226.394 222.402 240 173.785 240 125.168 240 63 184.647 63 136.229S154.511 42 203.128 42C251.745 42 269 129.558 269 177.976z"
            />
            <path
                fill="red"
                fillRule="evenodd"
                d="M264.54 161.695s-.45-3.192-1.829-4.598c-1.749-1.845-3.71-1.854-4.608-1.961-6.437-.469-16.093-.469-16.093-.469h-.02s-9.656 0-16.093.469c-.9.107-2.859.116-4.61 1.961-1.379 1.406-1.827 4.598-1.827 4.598s-.46 3.75-.46 7.498v3.515c0 3.749.46 7.498.46 7.498s.448 3.192 1.827 4.597c1.751 1.846 4.049 1.788 5.073 1.981 3.68.356 15.64.466 15.64.466s9.666-.014 16.103-.483c.898-.109 2.859-.118 4.608-1.964 1.379-1.405 1.829-4.597 1.829-4.597s.46-3.749.46-7.498v-3.515c0-3.748-.46-7.498-.46-7.498z"
                clipRule="evenodd"
            />
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M238.168 177.667l-.001-13.417 10.542 6.709-10.541 6.708z"
                clipRule="evenodd"
            />
            <path
                fill="url(#paint0_linear_214_3551)"
                fillRule="evenodd"
                d="M280 65c7.731 0 13.999 6.267 13.999 14 0 7.73-6.268 13.999-13.999 13.999-7.731 0-14-6.27-14-14l.001.17v-.34c.09-7.576 6.199-13.704 13.767-13.827L280 65z"
                clipRule="evenodd"
            />
            <path
                fill="#FFFFFE"
                fillRule="evenodd"
                d="M287.283 84.377c-1.325 1.462-3.698 3.737-3.799 3.835a1.609 1.609 0 01-2.279-.052 1.617 1.617 0 01.051-2.285c.023-.023 2.391-2.292 3.637-3.67a3.194 3.194 0 00.833-2.351c-.055-.915-.496-1.773-1.241-2.417-.978-.846-2.91-1.034-4.118.051-.72.645-1.592 1.595-1.6 1.605a1.613 1.613 0 01-2.279.096 1.618 1.618 0 01-.096-2.283c.04-.044.985-1.074 1.822-1.825 2.295-2.062 6.054-2.103 8.38-.09 1.408 1.218 2.243 2.877 2.352 4.667a6.413 6.413 0 01-1.663 4.719zm-9.751.115c-1.493 0-2.984-.495-4.129-1.486-1.407-1.217-2.242-2.874-2.352-4.666a6.41 6.41 0 011.664-4.717c1.324-1.464 3.698-3.74 3.798-3.835a1.609 1.609 0 012.279.052 1.618 1.618 0 01-.051 2.284c-.024.023-2.39 2.291-3.637 3.669a3.204 3.204 0 00-.834 2.35c.057.916.497 1.774 1.241 2.419.98.848 2.911 1.033 4.12-.05.718-.647 1.591-1.597 1.6-1.606a1.609 1.609 0 012.278-.095c.656.603.699 1.626.096 2.283-.04.042-.986 1.072-1.822 1.823-1.165 1.048-2.709 1.575-4.251 1.575z"
                clipRule="evenodd"
            />
            <g filter="url(#filter0_d_214_3551)">
                <path
                    fill="#fff"
                    d="M273 70c0-16.569-13.431-30-30-30-16.569 0-30 13.431-30 30 0 16.569 13.431 30 30 30 16.569 0 30-13.431 30-30z"
                />
                <path
                    fill="#1ED760"
                    fillRule="evenodd"
                    d="M260.746 66.596c-9.671-5.743-25.621-6.271-34.853-3.47a2.805 2.805 0 11-1.628-5.37c10.596-3.216 28.212-2.595 39.345 4.014a2.806 2.806 0 01-2.864 4.826zm-.317 8.506a2.34 2.34 0 01-3.217.77c-8.062-4.956-20.355-6.391-29.894-3.496a2.343 2.343 0 01-2.919-1.558 2.343 2.343 0 011.56-2.918c10.896-3.306 24.441-1.705 33.701 3.986a2.34 2.34 0 01.769 3.216zm-3.67 8.168a1.87 1.87 0 01-2.572.623c-7.044-4.306-15.912-5.278-26.354-2.893a1.87 1.87 0 11-.832-3.645c11.427-2.612 21.23-1.488 29.137 3.344a1.87 1.87 0 01.621 2.572zM243 40c-16.568 0-30 13.431-30 30 0 16.57 13.432 30 30 30 16.569 0 30-13.43 30-30 0-16.569-13.431-30-30-30z"
                    clipRule="evenodd"
                />
            </g>
            <g filter="url(#filter1_d_214_3551)">
                <g clipPath="url(#clip0_214_3551)">
                    <path
                        fill="url(#paint1_angular_214_3551)"
                        d="M10.093 118.093H40.649V148.649H10.093z"
                    />
                    <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M6.222 113c-.675 0-1.222.547-1.222 1.222v38.297c0 .675.547 1.222 1.222 1.222H44.52c.675 0 1.222-.547 1.222-1.222v-38.297c0-.675-.547-1.222-1.222-1.222H6.222zm27.444 28.201c.288-.59.376-1.228.376-1.877.002-2.221.002-4.443.001-6.666v-2.223c.002-2.973.002-5.945-.001-8.919 0-.117-.007-.233-.019-.349-.049-.484-.38-.788-.862-.749a8.89 8.89 0 00-1.019.161l-.013.003c-.81.159-1.618.323-2.426.487l-.702.142-1.929.39-1.606.324-.858.173a1186.456 1186.456 0 00-2.862.575c-.304.06-.607.12-.906.198-.424.11-.74.363-.848.807a2.404 2.404 0 00-.066.576c-.003 4.606-.003 9.213-.003 13.82v.059c0 .058.001.115-.001.175-.025.608-.337.982-.931 1.111-.24.051-.48.1-.721.148l-.619.126c-.584.122-1.167.251-1.723.484-1.436.608-2.015 1.965-1.794 3.231.257 1.467 1.496 2.458 2.988 2.403a6.88 6.88 0 001.671-.268c1.035-.301 1.8-.922 2.232-1.921.264-.608.336-1.254.336-1.907l.002-7.289v-4.857-.052c0-.051 0-.102.002-.152.02-.446.176-.643.606-.762a2.197 2.197 0 01.075-.02l1.07-.216 1.069-.216 1.207-.244c.805-.163 1.61-.326 2.413-.487l.71-.143c.946-.191 1.89-.382 2.838-.568.243-.049.49-.089.738-.104.24-.013.412.13.474.364.022.093.038.191.038.288.003 2.823.003 5.646.002 8.47 0 .101-.008.203-.027.301-.085.445-.37.704-.796.802-.37.086-.742.162-1.114.239-.217.044-.434.089-.65.135-.542.117-1.08.256-1.584.505a2.926 2.926 0 00-1.618 3.145 2.905 2.905 0 003.025 2.389 7.18 7.18 0 001.664-.269c.975-.276 1.71-.854 2.16-1.773z"
                        clipRule="evenodd"
                    />
                    <path
                        fill="#E4E0DF"
                        fillRule="evenodd"
                        d="M40.648 113H10.093A5.093 5.093 0 005 118.093v30.555a5.093 5.093 0 005.093 5.093h30.555a5.093 5.093 0 005.093-5.093v-30.555A5.093 5.093 0 0040.648 113zm-33.95 5.093a3.395 3.395 0 013.395-3.396h30.555a3.395 3.395 0 013.395 3.396v30.555a3.395 3.395 0 01-3.395 3.395H10.093a3.395 3.395 0 01-3.395-3.395v-30.555z"
                        clipRule="evenodd"
                    />
                </g>
            </g>
            <g filter="url(#filter2_d_214_3551)">
                <g filter="url(#filter3_d_214_3551)">
                    <path
                        fill="#1DA1F2"
                        fillRule="evenodd"
                        d="M305 179.737a23.643 23.643 0 01-6.95 1.953 12.379 12.379 0 005.322-6.864 23.854 23.854 0 01-7.691 3.008c-2.203-2.412-5.35-3.917-8.834-3.917-6.681 0-12.1 5.557-12.1 12.411 0 .973.104 1.918.311 2.827-10.06-.518-18.977-5.455-24.951-12.973a12.644 12.644 0 00-1.638 6.247c0 4.304 2.137 8.104 5.385 10.331a11.88 11.88 0 01-5.485-1.549v.153c0 6.016 4.173 11.034 9.714 12.17a11.554 11.554 0 01-3.19.437c-.779 0-1.541-.075-2.279-.22 1.541 4.929 6.009 8.519 11.307 8.615-4.142 3.331-9.364 5.316-15.033 5.316-.976 0-1.943-.057-2.888-.17 5.357 3.515 11.722 5.571 18.555 5.571 22.267 0 34.44-18.91 34.44-35.313 0-.54-.01-1.08-.031-1.609a24.82 24.82 0 006.036-6.424"
                        clipRule="evenodd"
                    />
                </g>
            </g>
            <g filter="url(#filter4_d_214_3551)">
                <g
                    fillRule="evenodd"
                    clipRule="evenodd"
                    filter="url(#filter5_d_214_3551)"
                >
                    <path
                        fill="url(#paint2_linear_214_3551)"
                        d="M149.27 50h-33.54C102.085 50 91 61.087 91 74.734v33.547C91 121.913 102.085 133 115.73 133h33.54c13.63 0 24.73-11.087 24.73-24.734V74.734C173.985 61.087 162.9 50 149.27 50zm16.372 58.281c0 9.038-7.335 16.374-16.372 16.374h-33.54c-9.036 0-16.371-7.336-16.371-16.374V74.734c0-9.038 7.335-16.374 16.371-16.374h33.54c9.037 0 16.372 7.336 16.372 16.374v33.547zm-33.149-38.247c-11.838 0-21.462 9.625-21.462 21.465s9.624 21.466 21.462 21.466 21.461-9.626 21.461-21.466c0-11.84-9.623-21.465-21.461-21.465zm0 34.586c-7.244 0-13.118-5.875-13.118-13.12 0-7.246 5.874-13.121 13.118-13.121 7.244 0 13.118 5.875 13.118 13.12 0 7.246-5.874 13.121-13.118 13.121zm26.642-34.424a5.136 5.136 0 11-10.271 0 5.136 5.136 0 0110.271 0z"
                    />
                    <path
                        fill="url(#paint3_radial_214_3551)"
                        d="M149.27 50h-33.54C102.085 50 91 61.087 91 74.734v33.547C91 121.913 102.085 133 115.73 133h33.54c13.63 0 24.73-11.087 24.73-24.734V74.734C173.985 61.087 162.9 50 149.27 50zm16.372 58.281c0 9.038-7.335 16.374-16.372 16.374h-33.54c-9.036 0-16.371-7.336-16.371-16.374V74.734c0-9.038 7.335-16.374 16.371-16.374h33.54c9.037 0 16.372 7.336 16.372 16.374v33.547zm-33.149-38.247c-11.838 0-21.462 9.625-21.462 21.465s9.624 21.466 21.462 21.466 21.461-9.626 21.461-21.466c0-11.84-9.623-21.465-21.461-21.465zm0 34.586c-7.244 0-13.118-5.875-13.118-13.12 0-7.246 5.874-13.121 13.118-13.121 7.244 0 13.118 5.875 13.118 13.12 0 7.246-5.874 13.121-13.118 13.121zm26.642-34.424a5.136 5.136 0 11-10.271 0 5.136 5.136 0 0110.271 0z"
                    />
                </g>
            </g>
            <g filter="url(#filter6_d_214_3551)">
                <path
                    fill="#25F4EE"
                    d="M155.79 178.202v-2.296a16.9 16.9 0 00-2.408-.206 17.858 17.858 0 00-10.238 32.516 17.84 17.84 0 0112.646-30.014z"
                />
                <path
                    fill="#25F4EE"
                    fillRule="evenodd"
                    d="M164.371 196.399a8.167 8.167 0 01-8.148 7.809 8.164 8.164 0 01-6.586-3.387 8.279 8.279 0 003.763.941 8.167 8.167 0 008.148-7.866V155h9.729c-.008.826.062 1.652.207 2.465h-7.113v38.934zm20.398-27.888v2.164a13.486 13.486 0 01-7.358-4.384 13.283 13.283 0 007.358 2.22z"
                    clipRule="evenodd"
                />
                <path
                    fill="#FE2C55"
                    fillRule="evenodd"
                    d="M174.08 157.446a13.394 13.394 0 003.331 8.844 13.474 13.474 0 01-5.928-8.844h2.597zm-28.591 34.209a8.166 8.166 0 017.892-6.227 8.404 8.404 0 012.409.282v-7.527h.433c.806.006 1.611.069 2.408.188v9.899a8.628 8.628 0 00-2.408-.377 8.15 8.15 0 00-6.606 12.928 8.168 8.168 0 01-4.128-9.166z"
                    clipRule="evenodd"
                />
                <path
                    fill="#FE2C55"
                    d="M184.77 170.675v7.527a23.082 23.082 0 01-13.493-4.385v19.778c-.021 9.868-8.027 17.858-17.895 17.858a17.714 17.714 0 01-10.237-3.237 17.858 17.858 0 0030.936-12.156v-19.721a23.12 23.12 0 0013.511 4.328v-9.692a13.794 13.794 0 01-2.822-.3z"
                />
                <path
                    fill="#000"
                    d="M171.277 193.595v-19.777a23.09 23.09 0 0013.511 4.328v-7.527a13.495 13.495 0 01-7.376-4.328 13.478 13.478 0 01-5.928-8.845h-7.112v38.953a8.166 8.166 0 01-14.773 4.46 8.168 8.168 0 013.764-15.393 8.38 8.38 0 012.409.376v-7.527a17.838 17.838 0 00-12.646 30.108 17.709 17.709 0 0010.256 3.03c9.869 0 17.875-7.989 17.895-17.858z"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_214_3551"
                    width="143.2"
                    height="143.2"
                    x="171.4"
                    y="0.48"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="2.08" />
                    <feGaussianBlur stdDeviation="20.8" />
                    <feColorMatrix values="0 0 0 0 0.0146256 0 0 0 0 0.0938447 0 0 0 0 0.157731 0 0 0 0.3 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_214_3551"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_214_3551"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_214_3551"
                    width="50.741"
                    height="50.741"
                    x="0"
                    y="109"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_214_3551"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_214_3551"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_214_3551"
                    width="132.422"
                    height="132.422"
                    x="209.289"
                    y="134.125"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1.836" />
                    <feGaussianBlur stdDeviation="18.355" />
                    <feColorMatrix values="0 0 0 0 0.0146256 0 0 0 0 0.0938447 0 0 0 0 0.157731 0 0 0 0.3 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_214_3551"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_214_3551"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_214_3551"
                    width="69"
                    height="59.167"
                    x="241"
                    y="169.917"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_214_3551"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_214_3551"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_d_214_3551"
                    width="169.993"
                    height="169.993"
                    x="47.504"
                    y="8.678"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="2.175" />
                    <feGaussianBlur stdDeviation="21.748" />
                    <feColorMatrix values="0 0 0 0 0.0146256 0 0 0 0 0.0938447 0 0 0 0 0.157731 0 0 0 0.3 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_214_3551"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_214_3551"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter5_d_214_3551"
                    width="93"
                    height="93"
                    x="86"
                    y="46"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_214_3551"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_214_3551"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter6_d_214_3551"
                    width="62.107"
                    height="68.915"
                    x="130.484"
                    y="151"
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        result="hardAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0.0588235 0 0 0 0 0.0901961 0 0 0 1 0" />
                    <feBlend
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_214_3551"
                    />
                    <feBlend
                        in="SourceGraphic"
                        in2="effect1_dropShadow_214_3551"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_214_3551"
                    x1="266"
                    x2="266"
                    y1="65"
                    y2="92.999"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#36B2FE" />
                    <stop offset="1" stopColor="#2256FF" />
                </linearGradient>
                <radialGradient
                    id="paint1_angular_214_3551"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="translate(25.37 133.37) scale(15.2778)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.095" stopColor="#46A0FB" />
                    <stop offset="0.398" stopColor="#8261FF" />
                    <stop offset="0.665" stopColor="#FD5A6F" />
                    <stop offset="0.914" stopColor="#E25B84" />
                </radialGradient>
                <linearGradient
                    id="paint2_linear_214_3551"
                    x1="193.367"
                    x2="147.717"
                    y1="108.792"
                    y2="28.558"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D92E7F" />
                    <stop offset="0.714" stopColor="#8B3FBD" />
                    <stop offset="0.952" stopColor="#4A66D4" />
                </linearGradient>
                <radialGradient
                    id="paint3_radial_214_3551"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientTransform="rotate(-69.076 157.273 -17.32) scale(50.3542)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.151" stopColor="#FEDB80" />
                    <stop offset="0.462" stopColor="#EF7835" />
                    <stop offset="1" stopColor="#EF7835" stopOpacity="0.01" />
                </radialGradient>
                <clipPath id="clip0_214_3551">
                    <path
                        fill="#fff"
                        d="M5 119a6 6 0 016-6h28.74a6 6 0 016 6v28.741a6 6 0 01-6 6H11a6 6 0 01-6-6V119z"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Icon;
