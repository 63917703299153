import superagent from 'superagent';

export default class ManifestLoader {
    constructor(cdnUrl, timestamp) {
        this.cdnUrl = cdnUrl;
        this.deployTimestamp = timestamp || '';
        this.requests = {};
    }

    getUrl(name, entry, timestamp) {
        let file = entry.en || entry;

        if (file.startsWith('http')) return file;
        if (file.startsWith('/')) file = file.substr(1);

        return `${this.cdnUrl}/${name}/${
            timestamp ? `${timestamp}/` : ''
        }${file}`;
    }

    parse(name, manifest) {
        const parsed = {
            scripts: [],
            stylesheets: [],
        };

        Object.keys(manifest).forEach(key => {
            const url = this.getUrl(name, manifest[key], manifest.timestamp);

            if (key.endsWith('.css')) parsed.stylesheets.push(url);
            else if (key.endsWith('.js')) parsed.scripts.push(url);
        });

        return parsed;
    }

    get(name) {
        const existingRequest = this.requests[name];
        if (existingRequest) return existingRequest;

        const clearRequest = val => {
            delete this.requests[name];
            return val;
        };

        this.requests[name] = new Promise((resolve, reject) =>
            superagent
                .get(
                    `${this.cdnUrl}/${name}/dml.json?t=${this.deployTimestamp}`
                )
                .then(({ body, text }) =>
                    this.parse(name, body || JSON.parse(text))
                )
                .then(resolve, reject)
        ).then(clearRequest, error => Promise.reject(clearRequest(error)));

        return this.requests[name];
    }
}
