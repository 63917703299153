import { map, flatMap } from 'lodash';
import { TYPE_PRODUCT } from 'src/constants/catalog';
import assetService from 'src/services/assets';
import catalogService, { RESULTS } from 'src/services/catalog';
import ObjectCache from 'src/utils/object-cache';

const defaultQueryCache = new ObjectCache();
const defaultImageCache = new ObjectCache();

const createOptions = (response, term) =>
    flatMap(response, (result, type) =>
        map(result.results, data => ({
            type,
            subtype: data.productConfiguration,
            count: result.totalHits,
            // eslint-disable-next-line max-len
            title: `${data.releaseName || data.projectName}${
                data.deliveredVersion ? ` (${data.deliveredVersion})` : ''
            }`,
            subtitle: data.artistName,
            code: data.displayUpc || data.projectCode,
            imageUrl: data.imageUrl,
            value: data.releaseId || data.projectId,
            data,
            term,
        }))
    );

const fetchImages = async (response, imageCache) => {
    const {
        [TYPE_PRODUCT]: { [RESULTS]: results = {}, ...productReponse } = {},
    } = response;
    const productIds = map(results, ({ releaseId }) => parseInt(releaseId, 10));

    const notInCache = imageCache.notInCache(productIds);

    try {
        const imageLocations = notInCache.length
            ? await assetService.getProductImageUrls(notInCache)
            : {};

        imageCache.update(imageLocations);

        return {
            ...response,
            [TYPE_PRODUCT]: {
                ...productReponse,
                [RESULTS]: map(results, product => ({
                    ...product,
                    imageUrl: imageCache.get(product.releaseId),
                })),
            },
        };
    } catch (e) {
        // If fetching the image locations fails,
        // we would still want the user to see the search results.
        // eslint-disable-next-line no-console
        console.error(e);

        return response;
    }
};

const search = async (query, config = {}) => {
    const { term } = query;
    const {
        pageSize,
        pageOffset,
        identity,
        imageCache = defaultImageCache,
        queryCache = defaultQueryCache,
    } = config;

    const { vendorId, subaccountId } = identity;

    const cachedResult = queryCache.get(term);
    if (cachedResult) return cachedResult;

    const response = await catalogService.catalogSearch({
        term,
        pageSize,
        pageOffset,
        vendorId,
        subaccountId,
    });

    const updatedResponse = await fetchImages(response, imageCache);
    const options = createOptions(updatedResponse, term);

    queryCache.update({ [term]: options });

    return options;
};

const createGlobalSearchQuery = config => query => search(query, config);

export default createGlobalSearchQuery;
