/* eslint-disable object-shorthand */
/* eslint-disable quote-props */
import React from 'react';
import LocalizationManager, {
    createManager,
} from '@orchard/frontend-localization';
import * as Sentry from '@sentry/browser';
import * as jsGrassTokenManager from '@theorchard/grass-token-manager';
import * as suiteConfig from '@theorchard/suite-config';
import * as suiteFrontend from '@theorchard/suite-frontend';
import * as apolloCacheInmemory from 'apollo-cache-inmemory';
import * as apolloClient from 'apollo-client';
import * as apolloLinkContext from 'apollo-link-context';
import * as apolloLinkHttp from 'apollo-link-http';
import cacheServiceCacheModule from 'cache-service-cache-module';
import classnames from 'classnames';
import * as commonTags from 'common-tags';
import graphlTag from 'graphql-tag';
import intl from 'intl';
import lodash from 'lodash';
import lodashFp from 'lodash/fp';
import moment from 'moment';
import * as propTypes from 'prop-types';
import * as reactApollo from 'react-apollo';
import * as reactDOM from 'react-dom';
import * as reactIntl from 'react-intl';
import * as reactLoadable from 'react-loadable';
import * as reactRedux from 'react-redux';
import * as reactReduxNext from 'react-redux-next';
import * as reactRouter from 'react-router';
import * as reactRouterBootstrap from 'react-router-bootstrap';
import * as reactRouterDom from 'react-router-dom';
import * as reactWithHook from 'react-with-hook';
import * as redux from 'redux';
import * as reduxThunk from 'redux-thunk';
import { DEFAULT_LOCALE } from 'src/constants';
import scopedModule from 'src/utils/scoped-module';
import * as superagent from 'superagent';
import superagentCachePlugin from 'superagent-cache-plugin';
import unfetch from 'unfetch';

const scopedFrontendLocalization = scopedModule(
    (moduleName, { defaultLocale = DEFAULT_LOCALE } = {}) => {
        const mngr = new LocalizationManager(defaultLocale);

        return {
            createManager,
            configureLocalization: config => mngr.configure(config),
            getCurrentLocale: () => mngr.getCurrentLocale(),
            formatMessage: (key, args) => mngr.formatMessage(key, args),
            setCurrentLocale: locale => mngr.setCurrentLocale(locale),
            registerTranslations: (locales, currentLocale) =>
                mngr.registerTranslations(locales, currentLocale),
        };
    }
);

export default {
    react: React,
    'cache-service-cache-module': cacheServiceCacheModule,
    'apollo-cache-inmemory': apolloCacheInmemory,
    'apollo-client': apolloClient,
    'apollo-link-context': apolloLinkContext,
    'apollo-link-http': apolloLinkHttp,
    'graphql-tag': graphlTag,
    'common-tags': commonTags,
    intl: intl,
    'js-grass-tokenmanager': jsGrassTokenManager,
    classnames: classnames,
    lodash: lodash,
    'lodash/fp': lodashFp,
    moment: moment,
    'prop-types': propTypes,
    'react-apollo': reactApollo,
    'react-dom': reactDOM,
    'react-redux': reactRedux,
    'react-redux-next': reactReduxNext,
    'react-intl': reactIntl,
    'react-loadable': reactLoadable,
    'react-router': reactRouter,
    'react-router-bootstrap': reactRouterBootstrap,
    'react-router-dom': reactRouterDom,
    'react-with-hook': reactWithHook,
    redux: redux,
    'redux-thunk': reduxThunk,
    superagent: superagent,
    'superagent-cache-plugin': superagentCachePlugin,
    unfetch: unfetch,
    'frontend-localization': scopedFrontendLocalization,
    '@orchard/frontend-localization': scopedFrontendLocalization,
    '@theorchard/suite-config': suiteConfig,
    '@theorchard/grass-token-manager': jsGrassTokenManager,
    '@sentry/browser': Sentry,
    '@theorchard/suite-frontend': suiteFrontend,
};
