import React from 'react';
import { LoaderWrapper } from '@orchard/frontend-react-components';

export const CLASSNAME = 'HeaderDropdownLoader';
export const CLASSNAME_WRAPPER = `${CLASSNAME}-wrapper`;
export const CLASSNAME_ITEM = `${CLASSNAME}-item`;
const CLASSNAME_LINE_TOP = `${CLASSNAME}-line-top`;
const CLASSNAME_LINE_BOTTOM = `${CLASSNAME}-line-bottom`;

export const HeaderDropdownLoaderItem = () => (
    <div className={CLASSNAME_ITEM}>
        <div className={CLASSNAME_LINE_TOP} />
        <div className={CLASSNAME_LINE_BOTTOM} />
    </div>
);

const HeaderDropdownLoader = () => (
    <LoaderWrapper
        content={HeaderDropdownLoaderItem}
        className={CLASSNAME_WRAPPER}
    />
);

export default HeaderDropdownLoader;
