import React, { useState } from 'react';
import { CloseIcon } from '@orchard/frontend-react-components';
import cx from 'classnames';
import PropTypes from 'prop-types';

const CLASS_NAME = 'Advertising-banner';

const Banner = ({
    className,
    cookieName,
    dismissBanner,
    bannerDismissed,
    canClose,
    children,
}) => {
    const [hideBanner] = useState(
        document.cookie.includes(`${cookieName}=false`)
    );

    if (hideBanner || bannerDismissed) return null;

    return (
        <div className={cx(CLASS_NAME, className)}>
            {children}
            {canClose && (
                <div
                    className={`${CLASS_NAME}-closeBannerButton`}
                    onClick={() => dismissBanner(cookieName)}
                >
                    <CloseIcon />
                </div>
            )}
        </div>
    );
};

Banner.propTypes = {
    cookieName: PropTypes.string.isRequired,
    dismissBanner: PropTypes.func.isRequired,
    bannerDismissed: PropTypes.bool,
    className: PropTypes.string,
    canClose: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
    ]),
};

Banner.defaultProps = {
    canClose: true,
};

export default Banner;
