export const OWS_SEARCH_BASE_URI = 'search/api/v1/';
export const OWS_SEARCH_CATALOG_URL = `${OWS_SEARCH_BASE_URI}catalogsuggest`;
export const OWS_SEARCH_TOKEN = 'c9047afefcd6'; // TODO: WHAT IS THIS???

export const OWS_FEATURES_URL = 'features/features';
export const OWS_USERS_URL = 'users';
export const OWS_USERS_SESSION_URL = `${OWS_USERS_URL}/users/session/alw`;

export const OWS_ASSETS_URL = 'assets';

export const IMAGES_CDN_URL = 'https://images.theorchard.com';
export const IMAGES_DEV_CDN_URL = 'https://images.qaorch.com';

export const GRASS_SESSION_START_RELATIVE_ENDPOINT = '/login/startsession';
export const GRASS_SESSION_CLEAR_RELATIVE_ENDPOINT = '/logout/clearsession';
