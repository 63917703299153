import { useEffect, useState, useDebugValue } from 'react';

const useLazyRequest = request => {
    useDebugValue(request);

    const [status, setStatus] = useState({
        loading: false,
    });

    const update = (state, query) =>
        setStatus({
            loading: false,
            query,
            ...state,
        });

    useEffect(() => {
        if (!status.loading) return;

        request(status.query)
            .then(data => update({ data }, status.query))
            .catch(error => update({ error }, status.query));
    }, [request, status.loading, status.query]);

    return [query => setStatus({ query, loading: true }), status];
};

export default useLazyRequest;
