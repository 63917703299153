import { SCOPED_MODULES } from '../../constants';
import libs from '../../externals';

export class DependencyError extends Error {
    constructor(lib) {
        super(`Unknown external library requested: '${lib}'`);
    }
}

export class NoEntryPointError extends Error {
    constructor(name) {
        super(`No entry point found for module: "${name}"`);
    }
}

export class ModuleAlreadyDefinedError extends Error {
    constructor(name) {
        super(`Duplicate definition for module: "${name}".`);
    }
}

export class ModuleNotDefinedError extends Error {
    constructor(name) {
        super(`Module "${name}" did not register itself during load.
        Make sure the module is using the AMD pattern.`);
    }
}

const getLibraryInstance = (name, scope) => {
    const lib = libs[name];
    if (!lib) throw new DependencyError(name);

    // When using: import { formatMessage } from '@orchard/frontend-localization',
    // the formatMessage method knows which module it is requested by.
    if (SCOPED_MODULES.indexOf(name) >= 0) return lib(scope);
    return lib;
};

export class Module {
    constructor(name, deps, factory) {
        this.name = name;
        this._deps = deps;
        this._factory = factory;
        this.exports = {};
    }

    load() {
        if (this.component) return this.component;

        const requestedDependencies = this._deps.map(lib =>
            getLibraryInstance(lib, this._name)
        );
        // eslint-disable-next-line prefer-spread
        this.component = this._factory.apply(this, requestedDependencies);

        return this.component;
    }
}

export class ModuleRegistry {
    _modules = {};

    init(name, deps, factory) {
        if (this._modules[name]) throw new ModuleAlreadyDefinedError(name);
        const module = new Module(name, deps, factory);
        this._modules[name] = module;
    }

    get(name) {
        return this._modules[name];
    }

    resolve(name) {
        const module = this.get(name);
        if (!module) return Promise.reject(new ModuleNotDefinedError(name));

        try {
            const component = module.load();
            if (!component) return Promise.reject(new NoEntryPointError(name));
            return Promise.resolve(component);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    has(name) {
        return !!this.get(name);
    }

    clear(name) {
        delete this._modules[name];
    }
}
