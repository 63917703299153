import React from 'react';
import { CloseIcon, SearchIcon } from '@orchard/frontend-react-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import GlobalSearchComponent from 'src/components/global-search';
import { HEADER_CLASSNAME } from 'src/constants/header';
import { PERMISSION_CATALOG, PRIVILEGE_INDEX } from 'src/constants/permissions';
import IdentityContext from 'src/context';

export const CLASSNAME_GLOBAL_SEARCH = `${HEADER_CLASSNAME}-globalSearch`;
export const CLASSNAME_CLOSE_OVERLAY = `${HEADER_CLASSNAME}-closeOverlay`;
export const CLASSNAME_NAV_ITEM = `${HEADER_CLASSNAME}-navItem`;
export const CLASSNAME_CLOSE_BTN = 'closeSearchButton';
export const CLASSNAME_ICON = 'globalSearchIcon';

const HeaderGlobalSearch = ({ isSpaPage }) => {
    const [active, setActive] = React.useState(false);
    const { identity } = React.useContext(IdentityContext);

    if (
        !identity.hasPermission({
            permission: PERMISSION_CATALOG,
            privilege: PRIVILEGE_INDEX,
        })
    )
        return null;

    const hideSearch = () => setActive(false);
    const toggleSearch = () => setActive(!active);

    const renderSearch = () => (
        <React.Fragment>
            <div className={CLASSNAME_GLOBAL_SEARCH}>
                <GlobalSearchComponent
                    idPrefix="Header"
                    setActive={setActive}
                    autoFocus
                    isSpaPage={isSpaPage}
                />
                <div className={CLASSNAME_CLOSE_BTN} onClick={hideSearch}>
                    <CloseIcon />
                </div>
            </div>

            <div className={CLASSNAME_CLOSE_OVERLAY} onClick={hideSearch} />
        </React.Fragment>
    );

    return (
        <React.Fragment>
            <div
                id="headerGlobalSearchIcon"
                className={cx([
                    'nav-link',
                    CLASSNAME_NAV_ITEM,
                    CLASSNAME_ICON,
                    { active },
                ])}
                onClick={toggleSearch}
            >
                <SearchIcon />
            </div>
            {active && renderSearch()}
        </React.Fragment>
    );
};

HeaderGlobalSearch.propTypes = {
    isSpaPage: PropTypes.bool,
};

HeaderGlobalSearch.defaultProps = {
    isSpaPage: false,
};

export default HeaderGlobalSearch;
