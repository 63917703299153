export const PERMISSION_CATALOG = 'catalog';
export const PERMISSION_ADMIN = 'admin';
export const PERMISSION_MARKETING = 'marketing';
export const PERMISSION_ACCOUNT = 'account';
export const PERMISSION_ANALYTICS = 'analytics';
export const USER_SERVICE_TIER_GATED_DIY = 'diy-tier-1';

export const PRIVILEGE_INDEX = 'index';
export const PRIVILEGE_MANAGE_USERS = 'manageusers';

export const FEATURE_HEADER_ICON_ENDPOINT = 'header_icon_endpoint';
export const FEATURE_TAX_BANNER = 'tax_outreach_2021';
export const FEATURE_DEPRECATE_ANALYTICS = 'deprecate_analytics';
export const FEATURE_INSIGHTS_WS_BANNER = 'insights_ws_banner';
export const FEATURE_IS_GATED_DIY = 'frontend_distribution_gated_diy';
export const FEATURE_AUTH0_SPA = 'frontend_workstation_use_auth0_spa';
export const FEATURE_V2_COLOR_SCHEME = 'workstation_solfege_color_scheme_v2';
export const FEATURE_REACT_LEGAL_PAGES =
    'frontend_workstation_react_term_pages';
export const FEATURE_NOTIFICATIONS_BANNER = `orchard_suite_notifications_banner`;
export const FEATURE_PRODUCT_BUILDER_SURVEY_BANNER =
    'ccm_product_builder_survey_workstation';

export const VARIANT_ENABLED = 'enabled';
export const VARIANT_CONTROL = 'control';

export const PERMITTED_VENDOR_IDS_AFTER_ANALYTICS_DEPRECIATION = [
    70680, 70697, 70745, 70958, 73639, 73560, 70959, 72415, 76410,
];
