import React, { useState } from 'react';
import { useAppConfig } from '@theorchard/suite-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Banner from 'src/components/banner';
import { getEnvironmentBrandedLinkInsights } from 'src/components/header/header';
import PromoModal from 'src/components/promo-modal';
import { FEATURE_INSIGHTS_WS_BANNER } from 'src/constants';
import IdentityContext from 'src/context';
import { formatMessage, getGoAppName } from 'src/utils';

const DISMISSED = 'Dismissed';
const CLICKED = 'Clicked';

export const PERMITTED_ANALYTICS_PATH_NAMES = [
    '/analytics/engagement',
    '/analytics/audience',
    '/analytics/engagement',
    '/analytics/heatmap',
    '/analytics/comparison',
    '/analytics/reporting/physical/report/product-view',
    '/analytics/filmtransparency',
    '/',
];

export const ORCHARD_GO_FEATURE_CONTROL = 'OrchardGo New Banner';
export const ORCHARD_GO_SURVEY_FEATURE_CONTROL = 'OrchardGo Survey Banner';
export const ORCHARD_GO_BANNER = 'Orchard-go-banner';
export const INSIGHTS_BANNER = 'Insights-banner';
const ORCHARD_GO_CLASS_NAME = 'Orchard-go-ad';
const INSIGHTS_CLASS_NAME = 'Insights-ad';
export const ORCHARD_GO_SURVEY_BANNER = 'Orchard-go-survey-banner';

const getOrchardGoSurveyPrefilledUrl = ({ vendorId, userId }) =>
    // eslint-disable-next-line max-len
    `https://docs.google.com/forms/d/e/1FAIpQLSeB4CSAh7FPJSGAUMM-QbLussZQVWHWseUJksLaChrOhMw21Q/viewform?usp=pp_url&entry.1186434305=${vendorId}&entry.524728512=${userId}`;

export const OrchardGoAd = () => {
    const config = useAppConfig();
    const [shouldOpenModal, setShouldOpenModal] = useState(false);

    const handleOnClick = () => {
        setShouldOpenModal(true);
    };

    const handleModalClose = () => {
        setShouldOpenModal(false);
    };

    return (
        <div className={ORCHARD_GO_CLASS_NAME}>
            <img
                className={`${ORCHARD_GO_CLASS_NAME}-icon`}
                src={`https://cdn.theorchard.io/assets/${config.brand}/icons/go_app.svg`}
                alt="OrchardGO_Icon"
            />
            <div className={`${ORCHARD_GO_CLASS_NAME}-title`}>
                {formatMessage('banner.orchardGo.description')}
                <div
                    className={`${ORCHARD_GO_CLASS_NAME}-url`}
                    onClick={handleOnClick}
                >
                    {formatMessage('banner.orchardGo.anchorText', {
                        goAppName: getGoAppName(config.brand),
                    })}
                </div>
            </div>

            <PromoModal
                shouldOpenModal={shouldOpenModal}
                handleModalClose={handleModalClose}
            />
        </div>
    );
};

export const InsightsAd = ({ config }) => (
    <div className={INSIGHTS_CLASS_NAME}>
        <img
            className={`${INSIGHTS_CLASS_NAME}-icon`}
            src={`https://cdn.theorchard.io/assets/${config.brand}/icons/insights_app.svg`}
            alt="OrchardGO_Icon"
        />
        <div className={`${INSIGHTS_CLASS_NAME}-title`}>
            {formatMessage('banner.insights.description')}
            <a
                href={getEnvironmentBrandedLinkInsights(config, {
                    medium: 'web',
                    campaign: 'launch_banner',
                })}
                target="_blank"
                rel="noopener noreferrer"
            >
                {formatMessage('banner.insights.anchorText')}
            </a>
        </div>
    </div>
);

InsightsAd.propTypes = {
    config: PropTypes.object,
};

export const OrchardGoSurvey = ({ vendorId, userId, brand }) => (
    <div className={ORCHARD_GO_CLASS_NAME}>
        <img
            className={`${ORCHARD_GO_CLASS_NAME}-icon`}
            src={`https://cdn.theorchard.io/assets/${brand}/icons/go_app.svg`}
            alt="OrchardGO_Icon"
        />
        <div className={`${ORCHARD_GO_CLASS_NAME}-title`}>
            {formatMessage('banner.orchardGoSurvey.description', {
                goAppName: getGoAppName(brand),
            })}
            <a
                className={`${ORCHARD_GO_CLASS_NAME}-url`}
                href={getOrchardGoSurveyPrefilledUrl({ vendorId, userId })}
            >
                {formatMessage('banner.orchardGoSurvey.anchorText')}
            </a>
        </div>
    </div>
);
OrchardGoSurvey.propTypes = {
    userId: PropTypes.number,
    vendorId: PropTypes.number,
    brand: PropTypes.string,
};

export const HeaderBanner = ({ location }) => {
    const config = useAppConfig();
    const { identity } = React.useContext(IdentityContext);

    const { pathname } = location;
    const [bannerDismissed, setBannerDismissed] = useState(false);
    const isInsightsBannerEnabled = identity.hasFeatureFlag(
        FEATURE_INSIGHTS_WS_BANNER
    );

    if (!identity?.id) return null;

    const hasDismissedBanner = bannerCookieName =>
        document.cookie.includes(`${bannerCookieName}=false`);

    const shouldShowInsightsBanner =
        isInsightsBannerEnabled &&
        PERMITTED_ANALYTICS_PATH_NAMES.includes(pathname) &&
        !hasDismissedBanner(INSIGHTS_BANNER);

    const shouldShowGoBanner =
        identity.hasFeatureControl(ORCHARD_GO_FEATURE_CONTROL) &&
        pathname === '/' &&
        !hasDismissedBanner(ORCHARD_GO_BANNER);

    const shouldShowGoSurveyBanner =
        identity.hasFeatureControl(ORCHARD_GO_SURVEY_FEATURE_CONTROL) &&
        pathname === '/' &&
        !hasDismissedBanner(ORCHARD_GO_SURVEY_BANNER);

    const dismissBanner = (cookieName, eventType, permanent = true) => {
        if (permanent) document.cookie = `${cookieName}=false`;
        setBannerDismissed(true);
        window.analytics.track(eventType, {
            category: 'Workstation Banner',
            label: cookieName,
        });
    };

    if (shouldShowInsightsBanner)
        return (
            <Banner
                cookieName={INSIGHTS_BANNER}
                bannerDismissed={bannerDismissed}
                dismissBanner={() => dismissBanner(INSIGHTS_BANNER, DISMISSED)}
            >
                <InsightsAd config={config} />
            </Banner>
        );

    if (shouldShowGoSurveyBanner)
        return (
            <Banner
                cookieName={ORCHARD_GO_SURVEY_BANNER}
                bannerDismissed={bannerDismissed}
                dismissBanner={() =>
                    dismissBanner(ORCHARD_GO_SURVEY_BANNER, DISMISSED)
                }
            >
                <OrchardGoSurvey
                    userId={identity.id}
                    vendorId={identity.vendorId}
                    brand={config.brand}
                />
            </Banner>
        );

    if (shouldShowGoBanner)
        return (
            <Banner
                cookieName={ORCHARD_GO_BANNER}
                bannerDismissed={bannerDismissed}
                dismissBanner={() =>
                    dismissBanner(ORCHARD_GO_BANNER, DISMISSED)
                }
            >
                <OrchardGoAd
                    onClick={() =>
                        dismissBanner(ORCHARD_GO_BANNER, CLICKED, false)
                    }
                />
            </Banner>
        );

    return null;
};

HeaderBanner.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default withRouter(HeaderBanner);
