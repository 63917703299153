import React, { useEffect, useState } from 'react';
import { ChatGlyph } from '@orchard/frontend-react-components';
import { useAppConfig, Segment } from '@theorchard/suite-frontend';
import { useTheme, themes, rgbToHex } from '@theorchard/suite-theming';
import { BRAND_AWAL, EVENT_HELP_CLICKED, HEADER_TERM } from 'src/constants';
import IdentityContext from 'src/context';
import { formatMessage } from 'src/utils';
import { getAccountDetails } from '../utils';

export const NO_ZENDESK_KEY_MSG =
    'Failed to initialize the Zendesk help button. No "zendeskKey" has been defined in the app configuration';
const CLASSNAME = 'ZendeskButton';
const SCRIPT_ID = 'ze-snippet';

// TODO: remove this fallback once all themes have been migrated to V2
const orchardV1Fallback = {
    'midnight-1': 'rgb(0 112 224)',
    'midnight-2': 'rgb(6 99 195)',
    'midnight-3': 'rgb(10 87 166)',
    'midnight-4': 'rgb(11 74 139)',
};
const getThemeColor = (tc, colorV2, colorV1) => {
    const cValue = tc?.[colorV2] || tc?.[colorV1] || orchardV1Fallback[colorV1];
    return rgbToHex(cValue);
};

const ZendeskButton = () => {
    const { identity } = React.useContext(IdentityContext);
    const config = useAppConfig();
    const { theme } = useTheme();
    const themeColors = themes[theme]?.colors || {};

    const zendeskKey = config?.zendeskKey;

    const [isScriptLoaded, setIsScriptLoaded] = useState(false);
    const [isWidgetOpen, setIsWidgetOpen] = useState(false);

    const widgetColors = {
        button: getThemeColor(themeColors, 'midnight-650', 'midnight-2'),
        resultLists: getThemeColor(themeColors, 'midnight-450', 'midnight-1'),
        header: getThemeColor(themeColors, 'midnight-850', 'midnight-4'),
        articleLinks: getThemeColor(themeColors, 'midnight-850', 'midnight-4'),
    };

    useEffect(() => {
        if (!zendeskKey || document.getElementById(SCRIPT_ID) || isScriptLoaded)
            return;

        window.zESettings = {
            webWidget: {
                offset: { horizontal: '20px', vertical: '40px' },
                position: { horizontal: 'right', vertical: 'top' },
            },
        };

        const script = document.createElement('script');
        script.id = SCRIPT_ID;
        script.setAttribute('data-testid', 'ZendeskScript');
        script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
        script.async = true;
        script.onload = () => {
            window.zE?.('webWidget', 'hide');

            window.zE?.('webWidget:on', 'open', () => {
                Segment.trackEvent(EVENT_HELP_CLICKED);
                setIsWidgetOpen(true);
            });

            window.zE?.('webWidget:on', 'close', () => {
                window.zE?.('webWidget', 'hide');
                setIsWidgetOpen(false);
            });

            setIsScriptLoaded(true);
        };
        script.onerror = () => {
            console.warn('There was an error loading zendesk'); // eslint-disable-line no-console
        };

        document.body.appendChild(script);
    }, [zendeskKey, isScriptLoaded]);

    useEffect(() => {
        if (!isScriptLoaded) return;

        const account = getAccountDetails(identity);

        window.zE?.('webWidget', 'identify', {
            name: account.contactName,
            email: account.contactEmail,
            organization: account.defaultBrand || config.brand,
        });

        window.zE?.('webWidget', 'updateSettings', {
            webWidget: {
                contactForm: {
                    fields: [
                        { id: 'email', prefill: { '*': account.contactEmail } },
                    ],
                },
            },
        });

        if (identity.language)
            window.zE?.('webWidget', 'setLocale', identity.language);
    }, [isScriptLoaded, identity, config.brand]);

    if (!zendeskKey) {
        console.warn(NO_ZENDESK_KEY_MSG); // eslint-disable-line no-console
        return null;
    }

    return (
        <button
            type="button"
            id="zendeskButton"
            className={`${CLASSNAME} Header-navItem zE-${
                isWidgetOpen ? 'Open' : 'Closed'
            }`}
            data-testid={CLASSNAME}
            onClick={() => {
                window.zE?.('webWidget', 'show');
                window.zE?.('webWidget', 'toggle');

                if (config.brand === BRAND_AWAL) {
                    window.zE?.('webWidget', 'updateSettings', {
                        webWidget: {
                            answerBot: {
                                suppress: true,
                            },
                        },
                    });
                    window.zE?.('webWidget', 'helpCenter:setSuggestions', {
                        search: 'workstation',
                    });
                }

                window.zE?.('webWidget', 'updateSettings', {
                    webWidget: {
                        color: widgetColors,
                    },
                });
            }}
        >
            <ChatGlyph />
            <span>{formatMessage(`${HEADER_TERM}.help`)}</span>
        </button>
    );
};

export default ZendeskButton;
