import { initApplication } from '@theorchard/suite-frontend';
import { WorkstationApplication, WorkstationPlugin } from './app/index';
import { FEATURE_V2_COLOR_SCHEME } from './constants';
export * from './externals/lib';

initApplication({
    title: 'Workstation',
    mainNav: false,
    colorScheme: {
        V2: identity => Boolean(identity.features[FEATURE_V2_COLOR_SCHEME]),
    },
    plugins: [WorkstationPlugin],
    routes: [{ path: '*', page: WorkstationApplication }],
    idleTimeoutSeconds: 0,
    config: {
        auth0StoreTokenInClient: true,
    },
});
