import TokenManagerService, {
    TokenManager,
} from '@theorchard/grass-token-manager';
import { Sentry, SuiteAuthClient } from '@theorchard/suite-frontend';
import { isEmpty } from 'lodash';
import { ENV_PROD, GRASS_SESSION_START_RELATIVE_ENDPOINT } from 'src/constants';
import { getUser } from 'src/services';
import { WorkstationConfig, WorkstationIdentity } from 'src/types';
import api from 'src/utils/api';
import { decryptSessionIdCipher } from 'src/utils/auth';
import { WorkstationAuthError } from './authError';
import {
    createFeatures,
    clearGrassToken,
    storeGrassToken,
    restoreGrassToken,
    endGrassSession,
} from './utils';

const GRASS_TOKEN_KEY = 'impersonated';

export class ImpersonatedAuthClient implements SuiteAuthClient {
    config: WorkstationConfig;
    tokenManager: TokenManager;
    identity?: WorkstationIdentity;

    static isImpersonated(config: WorkstationConfig) {
        if (config.environment !== ENV_PROD && config.grassToken) {
            storeGrassToken(GRASS_TOKEN_KEY, config.grassToken);
            return true;
        }

        if (window.location.hash.startsWith('#sid=')) return true;

        return !!restoreGrassToken(GRASS_TOKEN_KEY);
    }

    constructor(config: WorkstationConfig) {
        this.config = config;

        this.tokenManager = TokenManagerService({
            tokenDuration: config.grassTokenDuration,
            onRefreshFailed: () => {
                Sentry.captureMessage(
                    'Failed to refresh impersonated grass session'
                );
                void this.logout();
            },
            onSetToken: token => {
                if (token) storeGrassToken(GRASS_TOKEN_KEY, token);
            },
        });
    }

    async refresh() {
        return await Promise.resolve(true);
    }

    private parseSessionIdParam() {
        const params = new URLSearchParams(
            window.location.hash.replace('#', '?')
        );

        const sid = params.get('sid');

        if (!sid) return undefined;

        if (!this.config.auth0ClientId)
            throw new Error('Missing "auth0ClientId"');

        const decrypted = decryptSessionIdCipher(
            sid,
            this.config.auth0ClientId
        );

        if (!decrypted)
            throw new Error('Failed to decrypt impersonation "sid" param');

        return decrypted;
    }

    private async startSession(sid: string) {
        const { grassSessionStartEndpoint } = this.config;

        const params = new URLSearchParams(
            window.location.hash.replace('#', '?')
        );

        const user = {
            userType: params.get('user_type') ?? undefined,
            email:
                params.get('username')?.trim()?.replace(' ', '+') ?? undefined,
            vendorContactId: params.get('vend_contact_id') ?? undefined,
        };

        const response = await api.post(
            grassSessionStartEndpoint || GRASS_SESSION_START_RELATIVE_ENDPOINT,
            {
                bearerToken: sid,
                data: {
                    username: user.email,
                    vend_contact_id: user.vendorContactId,
                    user_type: user.userType,
                },
            }
        );

        if (response.status === 401) {
            await this.logout();
            return false;
        }

        if (response.status === 403) {
            throw new WorkstationAuthError(
                'impersonatedSessionExpired',
                'Invalid or expired impersonation token',
                { cause: response.error }
            );
        }

        if (!response.ok)
            throw new Error(
                `Impersonated session failed to start with: ${response.status}`,
                { cause: response.error }
            );

        if (isEmpty(response.body) || !response.body?.grassToken)
            throw new Error('Invalid response', { cause: response.error });

        this.tokenManager.setToken({ token: response.body.grassToken });

        return true;
    }

    async loadUser() {
        try {
            const { user, featureFlags } = await getUser();

            this.identity = {
                id: user.id,
                impersonated: true,
                profileId: user.id,
                profileType: 'LabelProfile',
                ...user.identity,
                user,
                featureFlags,
                features: createFeatures(featureFlags),
            };

            return this.identity;
        } catch (error) {
            // If we are using a fixed grass token, then logging out would cause an endless redirect loop.
            // So instead we are re-throwing the error, showing it to the user.
            if (this.config.grassToken) throw error;

            void this.logout();
            return undefined;
        }
    }

    async authenticate() {
        const sid = this.parseSessionIdParam();

        if (sid) {
            const success = await this.startSession(sid);
            if (!success) return undefined;
        }

        return await this.loadUser();
    }

    async getHeaders() {
        const token = await this.getTokenSilently();

        return { session: token };
    }

    async checkSession() {
        const token = restoreGrassToken(GRASS_TOKEN_KEY);
        if (token) this.tokenManager.setToken({ token });
    }

    async logout() {
        try {
            this.tokenManager.reset();

            clearGrassToken(GRASS_TOKEN_KEY);

            await endGrassSession(this.config.grassSessionClearEndpoint);
        } finally {
            window.location.assign('/');
        }
    }

    async getTokenSilently() {
        const token = await this.tokenManager.getToken();

        if (!token) throw new Error('No grass token available');

        return token;
    }

    async isAuthenticated() {
        return !!this.tokenManager.token;
    }

    async getIdentity() {
        if (!this.identity) throw new Error('Identity not available');

        return this.identity;
    }

    async getFeatureFlags() {
        return this.identity?.features ?? {};
    }

    async refreshAuthenticatedUser() {
        return await Promise.resolve(this.identity);
    }
}
