import { useCallback } from 'react';
import {
    resolveEntityUrl,
    resolveListUrl,
} from 'src/components/global-search/ws-select-handler';

const useHandleSelect = ({
    history,
    setActive,
    isSpaPage,
    location = window.location,
}) =>
    useCallback(
        item => {
            const { type, term, data: itemData } = item;
            const url = itemData
                ? resolveEntityUrl(type, itemData)
                : resolveListUrl(type, term);

            if (!url) return;

            if (isSpaPage) {
                if (setActive) setActive(false);

                history.push(url);
            } else location.assign(url);
        },
        [history, setActive, isSpaPage, location]
    );

export default useHandleSelect;
