import { DEFAULT_LOCALE } from '@orchard/frontend-localization';
import { OnInitCallback } from '@theorchard/suite-frontend';
import ModuleLoader from 'src/components/module-loader';
import { INDEX_LINK, INDEX_PHP_LINK } from 'src/constants';
import { WorkstationConfig } from 'src/types';
import { IdentityManager, registerTranslations } from 'src/utils';
import * as api from 'src/utils/api';
import allLocales from '../../locale';

export const onInit: OnInitCallback = async ({ config }) => {
    const {
        apiUrl,
        cdnUrl,
        environment,
        deployTimestamp,
        userRestrictedFeatures,
        userFeatureFlags,
        locale = window.ORCHARD_LOCALE,
        locales = allLocales,
    } = config as WorkstationConfig;

    if (apiUrl) api.setApiUrl(apiUrl as string);

    if (window.location.href.includes(INDEX_PHP_LINK))
        window.history.replaceState(
            {},
            document.title,
            window.location.href.replace(INDEX_PHP_LINK, INDEX_LINK)
        );

    if (window.location.href.includes('/auth0Redirect'))
        window.history.replaceState({}, document.title, window.location.origin);

    registerTranslations(locales, locale || DEFAULT_LOCALE);

    IdentityManager.instance.setOverrides({
        userFeatureFlags,
        userRestrictedFeatures,
    });

    ModuleLoader.configure({ cdnUrl, deployTimestamp });

    window.ORCHARD_ENV = environment;
};
