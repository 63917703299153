import { reduce } from 'lodash';

const ensureKey = id => id.toString();

export default class ObjectCache {
    constructor(dict = {}) {
        this.cache = this._normalizeKeys(dict);
    }

    _normalizeKeys = entries =>
        reduce(
            entries,
            (result, value, key) => ({
                ...result,
                [ensureKey(key)]: value,
            }),
            {}
        );

    get(id) {
        return this.cache[ensureKey(id)];
    }

    notInCache(ids) {
        return ids.filter(id => !this.cache[ensureKey(id)]);
    }

    update(entries) {
        this.cache = {
            ...this.cache,
            ...this._normalizeKeys(entries),
        };

        return this.cache;
    }
}
