import React from 'react';
import { LoaderWrapper } from '@orchard/frontend-react-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { CLASSNAME as CX_RESULTS } from './constants';
import {
    CLASSNAME as CLASSNAME_OPTION,
    CLASSNAME_IMAGE,
    CLASSNAME_BODY,
    CLASSNAME_TITLE,
} from './search-result-option';

export const NUMBER_OF_ITEMS = 3;
export const CLASSNAME = 'SearchResultsLoadingIndicator';
export const CLASSNAME_HEADER = `${CX_RESULTS}-heading`;
export const CLASSNAME_COVER = `${CLASSNAME}-cover`;
export const CLASSNAME_LINE = `${CLASSNAME}-line`;
export const CLASSNAME_LINE_SM = `${CLASSNAME}-line-sm`;

export const SearchLoaderItem = () => (
    <div className={CLASSNAME_OPTION}>
        <div className={cx(CLASSNAME_COVER, CLASSNAME_IMAGE)} />
        <div className={CLASSNAME_BODY}>
            <div className={cx(CLASSNAME_LINE, CLASSNAME_TITLE)} />
            <div className={CLASSNAME_LINE} />
        </div>
        <div className={CLASSNAME_LINE_SM} />
    </div>
);

export const SearchLoaderGroup = ({ title }) => (
    <LoaderWrapper
        header={<div className={CLASSNAME_HEADER}>{title}</div>}
        numberOfItems={NUMBER_OF_ITEMS}
        content={SearchLoaderItem}
        vertical
    />
);
SearchLoaderGroup.propTypes = {
    title: PropTypes.string,
};

const SearchLoadingIndicator = ({ categories, formatMessage }) => (
    <div className={CLASSNAME} data-testid="gs-loading-indicator">
        {categories.map(category => (
            <SearchLoaderGroup
                key={category}
                title={formatMessage(`${category}Plural`)}
            />
        ))}
    </div>
);
SearchLoadingIndicator.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.string),
    formatMessage: PropTypes.func.isRequired,
};
SearchLoadingIndicator.defaultProps = {
    categories: ['result'],
};

export default SearchLoadingIndicator;
