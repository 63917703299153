import CryptoJS from 'crypto-js';
import { WorkstationConfig } from 'src/types';
import Identity from './Identity';

export const decryptSessionIdCipher = (
    encryptedString: string,
    key: string
) => {
    const json = JSON.parse(
        CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(encryptedString))
    );

    const salt = CryptoJS.enc.Hex.parse(json.salt);
    const iv = CryptoJS.enc.Hex.parse(json.iv);

    const encrypted = json.ciphertext;

    let iterations = parseInt(json.iterations, 10);
    if (iterations <= 0) iterations = 999;

    const hashKey = CryptoJS.PBKDF2(key, salt, {
        hasher: CryptoJS.algo.SHA512,
        keySize: 8,
        iterations,
    });
    const decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {
        mode: CryptoJS.mode.CBC,
        iv,
    });

    const decryptedSessionId = decrypted.toString(CryptoJS.enc.Utf8);

    return decryptedSessionId;
};

const encryptSessionIdCipher = (value: string, key: string) => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const salt = CryptoJS.lib.WordArray.random(256);
    const iterations = 999;

    const hashKey = CryptoJS.PBKDF2(key, salt, {
        hasher: CryptoJS.algo.SHA512,
        keySize: 8,
        iterations,
    });

    const encrypted = CryptoJS.AES.encrypt(value, hashKey, {
        mode: CryptoJS.mode.CBC,
        iv,
    });

    const encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    const output = {
        ciphertext: encryptedString,
        iv: CryptoJS.enc.Hex.stringify(iv),
        salt: CryptoJS.enc.Hex.stringify(salt),
        iterations,
    };

    return CryptoJS.enc.Base64.stringify(
        CryptoJS.enc.Utf8.parse(JSON.stringify(output))
    );
};

export const getSwitchKeySessId = (
    config: WorkstationConfig,
    identity: Identity
) => {
    const switchKey =
        identity && identity.id && identity.vendorId
            ? `${identity.id}${identity.vendorId}`
            : null;
    const auth0ClientId = config?.auth0ClientId || null;

    if (!switchKey || !auth0ClientId) return null;

    return encryptSessionIdCipher(switchKey, auth0ClientId);
};
