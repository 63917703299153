import React, { useState } from 'react';
import { Alert } from '@orchard/frontend-react-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { formatMessage } from 'src/utils';

export const PERMITTED_PATH_NAMES_APPLE_SOURCE_REMOVAL = [
    '/analytics/audience',
];

const AppleInfoAlert = ({ location }) => {
    const [bannerDismissed, setBannerDismissed] = useState(false);
    const { pathname } = location;

    if (
        !PERMITTED_PATH_NAMES_APPLE_SOURCE_REMOVAL.includes(pathname) ||
        Date.now() > Date.UTC(2021, 4, 1) ||
        bannerDismissed
    )
        return null;

    return (
        <div className="container-max-1170">
            <Alert
                variant={Alert.VARIANT_INFO}
                dismissible
                onDismiss={() => setBannerDismissed(true)}
            >
                <div>{formatMessage('appleAlert')}</div>
            </Alert>
        </div>
    );
};

AppleInfoAlert.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default withRouter(AppleInfoAlert);
