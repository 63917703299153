import React from 'react';
import PropTypes from 'prop-types';
import { HEADER_CLASSNAME } from 'src/constants/header';
import {
    PERMISSION_ACCOUNT,
    PRIVILEGE_MANAGE_USERS,
} from 'src/constants/permissions';
import IdentityContext from 'src/context';
import { getAccountDetails } from '../utils';
import HeaderAccountDropdownTitle from './header-account-dropdown-title';
import HeaderDropdownLoader from './header-dropdown-loader';
import LinkedAccountsDropdown from './linked-accounts-dropdown';

const CLASSNAME = `${HEADER_CLASSNAME}-accountMenu`;

const HeaderAccountMenu = ({ isEmbedOnPhp }) => {
    const { identity } = React.useContext(IdentityContext);
    if (identity.isAnonymous()) return <HeaderDropdownLoader />;

    const account = getAccountDetails(identity);

    const showAdminLink = identity.hasPermission({
        permission: PERMISSION_ACCOUNT,
        privilege: PRIVILEGE_MANAGE_USERS,
    });

    return (
        <LinkedAccountsDropdown
            className={CLASSNAME}
            title={<HeaderAccountDropdownTitle {...account} />}
            showAdminLink={showAdminLink}
            isEmbedOnPhp={isEmbedOnPhp}
            {...account}
        />
    );
};
HeaderAccountMenu.propTypes = {
    isEmbedOnPhp: PropTypes.bool,
};

export default HeaderAccountMenu;
