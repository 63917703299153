import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Banner from 'src/components/banner';
import { FEATURE_PRODUCT_BUILDER_SURVEY_BANNER } from 'src/constants';
import IdentityContext from 'src/context';
import { formatMessage } from 'src/utils';

export const PRODUCT_BUILDER_SURVEY_BANNER = 'Product-builder-survey-banner';
const PRODUCT_BUILDER_SURVEY_CLASS_NAME = 'Product-builder-survey';

const permittedProductSurveyPath = pathname =>
    /\/project\/.*/.test(pathname) ||
    ['/catalog', '/projects'].includes(pathname);

export const ProductBuilderSurvey = () => (
    <div className={`${PRODUCT_BUILDER_SURVEY_CLASS_NAME}`}>
        <img
            className={`${PRODUCT_BUILDER_SURVEY_CLASS_NAME}-icon`}
            src={`https://cdn.theorchard.io/assets/workstation/images/icons/survey.png`}
            alt="survey icon"
        />
        <div className={`${PRODUCT_BUILDER_SURVEY_CLASS_NAME}-title`}>
            {formatMessage('banner.productBuilderSurvey.description')}
            <a
                href="https://t.maze.co/181855328"
                target="_blank"
                rel="noopener noreferrer"
            >
                {formatMessage('banner.productBuilderSurvey.anchorText')}
            </a>
        </div>
    </div>
);

export const PageBanner = ({ location }) => {
    const { identity } = React.useContext(IdentityContext);

    const { pathname } = location;
    const [bannerDismissed, setBannerDismissed] = useState(false);

    const hasDismissedBanner = bannerCookieName =>
        document.cookie.includes(`${bannerCookieName}=false`);

    const showProductBuilderSurveyBanner =
        identity.hasFeatureFlag(FEATURE_PRODUCT_BUILDER_SURVEY_BANNER) &&
        permittedProductSurveyPath(pathname) &&
        !hasDismissedBanner(PRODUCT_BUILDER_SURVEY_BANNER);

    const dismissBanner = (cookieName, eventType, permanent = true) => {
        if (permanent) document.cookie = `${cookieName}=false`;
        setBannerDismissed(true);
    };

    if (showProductBuilderSurveyBanner)
        return (
            <Banner
                cookieName={PRODUCT_BUILDER_SURVEY_BANNER}
                bannerDismissed={bannerDismissed}
                dismissBanner={() =>
                    dismissBanner(PRODUCT_BUILDER_SURVEY_BANNER, 'Dismissed')
                }
                className={`${PRODUCT_BUILDER_SURVEY_CLASS_NAME}-banner`}
            >
                <ProductBuilderSurvey />
            </Banner>
        );

    return null;
};

PageBanner.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default withRouter(PageBanner);
