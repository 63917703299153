import React from 'react';
import { Nav, Navbar } from '@orchard/frontend-react-components';
import PropTypes from 'prop-types';
import SitemapItems from '../sitemap-items';

const SubHeader = ({ items }) => (
    <Navbar className="SubHeader">
        <Nav>
            <SitemapItems className="SubHeader" items={items} />
        </Nav>
    </Navbar>
);
SubHeader.propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
};

export default SubHeader;
