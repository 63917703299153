import {
    HELPCENTER_URL,
    HELPCENTER_URL_QA,
    ORCHARD_GO_URI,
} from 'src/constants';
import { isProd } from './environment';

export const getHelpCenterUrl = (_, lang) => {
    // _ = identity, removed HELPCENTER_AUTO_LOGIN FF check as not ever used
    // based on environment set helpcenter url
    const HELP_URL = isProd() ? HELPCENTER_URL : HELPCENTER_URL_QA;

    return `${HELP_URL}/?lang=${lang}`;
};

export const getOrchardGoHelpCenterUrl = lang => {
    const HELP_URL = isProd()
        ? `${HELPCENTER_URL}${ORCHARD_GO_URI}`
        : `${HELPCENTER_URL_QA}${ORCHARD_GO_URI}`;

    return `${HELP_URL}/?lang=${lang}`;
};
