import { useEffect, useState } from 'react';

const useRequest = (request, ...args) => {
    const [response, setResponse] = useState({
        loading: true,
    });

    const update = state =>
        setResponse({
            loading: false,
            ...state,
        });

    useEffect(() => {
        if (!response.loading) setResponse({ loading: true });

        request(...args)
            .then(data => update({ data }))
            .catch(error => update({ error }));
    }, [request, ...args]); // eslint-disable-line react-hooks/exhaustive-deps

    return response;
};

export default useRequest;
