import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { formatMessage } from 'src/utils';

const isActive = (route, location, urlMatch) => {
    if (route && route.isExact) return true;
    if (urlMatch)
        return (Array.isArray(urlMatch) ? urlMatch : [urlMatch]).some(match =>
            location.pathname.match(match.replace('*', '.*'))
        );
    return false;
};

export const CLASSNAME = 'nav-link';

const ReactifiedLink = ({
    className,
    activeClassName,
    term,
    url,
    urlMatch,
}) => (
    <NavLink
        to={url}
        exact
        className={cx(CLASSNAME, className)}
        activeClassName={activeClassName}
        isActive={(route, location) => isActive(route, location, urlMatch)}
    >
        {formatMessage(term)}
    </NavLink>
);
ReactifiedLink.propTypes = {
    className: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    activeClassName: PropTypes.string,
    url: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    urlMatch: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};

export default ReactifiedLink;
