import { AuthError, Identity } from '@theorchard/suite-frontend';

export class WorkstationAuthError extends Error implements AuthError {
    code: string;
    user?: Partial<Identity>;
    retry?: () => void;

    constructor(
        code: string,
        message: string,
        {
            user,
            cause,
            retry,
        }: {
            user?: Partial<Identity>;
            cause?: unknown;
            retry?: () => void;
        } = {}
    ) {
        super(message, { cause });
        this.name = 'WorkstationAuthError';
        this.code = code;
        this.user = user;
        this.retry = retry;
    }
}
