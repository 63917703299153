import React from 'react';
import { useAppConfig } from '@theorchard/suite-config';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
    SUPPORT_EMAIL,
    BRAND_ORCHARD,
    FEATURE_REACT_LEGAL_PAGES,
} from 'src/constants';
import { PRIVACY_LINK, TERMS_LINK } from 'src/constants/header';
import IdentityContext from 'src/context';
import { formatMessage, getCurrentLocale } from 'src/utils';
import { getHelpCenterUrl } from 'src/utils/helpcenter';

export const CLASSNAME = 'Footer';
export const CLASSNAME_COPYRIGHT = `${CLASSNAME}-copyright`;
export const HELP_LINK_ID = `${CLASSNAME}_help_link`;
const CLASSNAME_TEXTLINE = `${CLASSNAME}-textLine`;
const CLASSNAME_TEXTPART = `${CLASSNAME}-textLinePart`;

const getSupportEmail = identity => {
    const { vendor: { supportContactEmail, assignedToEmail } = {} } = identity;

    return supportContactEmail || assignedToEmail || SUPPORT_EMAIL;
};

export const FooterLink = ({ text, term, href, id }) => {
    // check if current link is a helpcenter link
    // then set target to _self
    const targetValue = term !== 'help' ? '_blank' : '_self';

    return (
        <a
            id={id || `${CLASSNAME}_${term}_link`}
            className={cx(CLASSNAME_TEXTPART, { divider: !!href })}
            href={href}
            // eslint-disable-next-line react/jsx-no-target-blank
            target={targetValue}
        >
            {text || formatMessage(`footer.${term}`)}
        </a>
    );
};
FooterLink.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    href: PropTypes.string,
    term: PropTypes.string.isRequired,
};

const Footer = () => {
    const { identity } = React.useContext(IdentityContext);
    const config = useAppConfig();

    const supportEmail = !identity.isAnonymous() && getSupportEmail(identity);
    const helpUrl = getHelpCenterUrl(identity, getCurrentLocale());

    let links = [
        { term: 'help', href: helpUrl, id: HELP_LINK_ID },
        { term: 'privacyPolicy', href: PRIVACY_LINK, id: 'privacyPolicy' },
        {
            term: 'termsAndConditions',
            href: TERMS_LINK,
            id: 'termsAndConditions',
        },
    ];

    if (config.brand !== BRAND_ORCHARD || identity.isAwalUser())
        links = links.filter(l => l.id !== HELP_LINK_ID);

    if (identity.subaccount) {
        links.unshift({ term: 'contactDistributor' });
    } else if (
        supportEmail &&
        config.brand === BRAND_ORCHARD &&
        !identity.isAwalUser()
    ) {
        links.unshift({
            term: 'supportEmail',
            text: supportEmail,
            href: `mailto:${supportEmail}`,
        });
        links.unshift({ term: 'supportContact' });
    }

    return (
        <div
            className={cx({
                [CLASSNAME]: true,
                'd-print-none': true,
            })}
        >
            <p className={CLASSNAME_TEXTLINE}>
                {links.map(link => {
                    if (
                        identity.hasFeatureFlag(FEATURE_REACT_LEGAL_PAGES) &&
                        [PRIVACY_LINK, TERMS_LINK].includes(link.href)
                    )
                        return (
                            <Link
                                id={link.id}
                                className="Footer-textLinePart divider"
                                key={link.term}
                                to={link.href}
                                onClick={() => window.scrollTo(0, 0)}
                            >
                                {formatMessage(`footer.${link.term}`)}
                            </Link>
                        );

                    return <FooterLink key={link.term} {...link} />;
                })}
            </p>
            <p className={cx(CLASSNAME_TEXTLINE, CLASSNAME_COPYRIGHT)}>
                {formatMessage('footer.copyright', {
                    year: new Date().getFullYear(),
                })}
            </p>
        </div>
    );
};

export default Footer;
