import {
    TYPE_PRODUCT,
    TYPE_PROJECT,
    PRODUCT_TYPEID_FILM,
    PRODUCT_TYPE_MUSIC_VIDEO,
    PROJECT_URL,
    PRODUCT_URL,
    PRODUCT_FILM_URL,
    PRODUCT_VIDEO_URL,
    PRODUCTS_URL,
    PROJECTS_URL,
} from 'src/constants/catalog';

const isFilmProduct = ({ productTypeId }) =>
    productTypeId && parseInt(productTypeId, 10) === PRODUCT_TYPEID_FILM;

const isVideoProduct = ({ productConfiguration: type }) =>
    type && type.toLowerCase() === PRODUCT_TYPE_MUSIC_VIDEO;

export const resolveEntityUrl = (type, entity) => {
    const { projectId, releaseId } = entity;
    switch (type) {
        case TYPE_PROJECT:
            return PROJECT_URL(projectId);
        case TYPE_PRODUCT: {
            if (isFilmProduct(entity)) return PRODUCT_FILM_URL(releaseId);
            if (isVideoProduct(entity))
                return PRODUCT_VIDEO_URL(projectId, releaseId);
            return PRODUCT_URL(projectId, releaseId);
        }
        default:
            return null;
    }
};

export const resolveListUrl = (type, term = '') => {
    switch (type) {
        case TYPE_PROJECT:
            return PROJECTS_URL(term);
        case TYPE_PRODUCT:
            return PRODUCTS_URL(term);
        default:
            return null;
    }
};
