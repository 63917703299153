/* globals process */
import React, { lazy, Suspense } from 'react';
import { Sentry, Segment } from '@theorchard/suite-frontend';
import PropTypes from 'prop-types';
import { setPageTitle } from 'src/utils/set-page-title';
import { DEFAULT_CDN_URL, getFullBrandName } from '../../constants';
import ErrorComponent from '../error';
import createAmdLoader from './amd-loader';
import LoadingIndicator from './loading-indicator';
import ManifestLoader from './manifest';

export class UnknownModuleError extends Error {
    constructor(name) {
        super(`404: Unknown module "${name}"`);
    }
}

const manifest = new ManifestLoader(
    process.env.CDN_URL || DEFAULT_CDN_URL,
    process.env.DEPLOY_TIMESTAMP
);
const amdLoader = createAmdLoader(manifest);

export class ModuleLoaderComponent extends React.Component {
    static configure({ cdnUrl, deployTimestamp }) {
        if (cdnUrl) manifest.cdnUrl = cdnUrl;
        if (deployTimestamp) manifest.deployTimestamp = deployTimestamp;
    }

    static propTypes = {
        module: PropTypes.string,
        pageTitle: PropTypes.string,
        brand: PropTypes.string,
        className: PropTypes.string,
    };

    static contextTypes = {
        identity: PropTypes.object,
    };

    static getDerivedStateFromProps(props, { failed }) {
        const { module } = props;

        if (module !== failed) return { failed: null };
        return null;
    }

    /* eslint-disable-next-line react/sort-comp */
    static manifest = manifest;

    constructor(props, context) {
        super(props, context);

        this.state = { failed: null };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const { failed } = this.state;
        const { identity } = this.context;
        const { module } = this.props;

        return (
            nextProps.module !== module ||
            nextState.failed !== failed ||
            nextContext.identity !== identity
        );
    }

    componentDidCatch(error, info) {
        const { module } = this.props;

        Sentry.withScope(scope => {
            scope.setExtras(info);
            Sentry.captureException(error);
        });

        this.setState({ failed: module });
    }

    render() {
        const { identity } = this.context;
        const { failed } = this.state;
        const { module, pageTitle, className, brand } = this.props;

        if (failed) return <ErrorComponent />;

        if (identity.isAnonymous() || !module) return null;

        const OnDemandModule = lazy(() => amdLoader(module));

        if (pageTitle) setPageTitle(pageTitle, getFullBrandName(brand));

        Segment.trackPage();

        const moduleClassName = `module-${module} ${className || ''}`;

        return (
            <Suspense fallback={<LoadingIndicator />}>
                <div className={moduleClassName}>
                    <OnDemandModule />
                </div>
            </Suspense>
        );
    }
}
