import React, { Component } from 'react';
import {
    SearchIcon,
    SelectInput,
    SearchResultsOutline,
} from '@orchard/frontend-react-components';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import {
    CLASSNAME,
    IDNAME,
    TERM_ID,
    TERM_NORESULT,
    TERM_ERROR,
    TERM_PLACEHOLDER,
} from './constants';
import SearchLoadingIndicator from './search-loading-indicator';
import SearchResultOption from './search-result-option';
import SearchResults from './search-results';

let QUERY_SEQ = 0;

class GlobalSearch extends Component {
    static propTypes = {
        autoFocus: PropTypes.bool,
        idPrefix: PropTypes.string,
        footerType: PropTypes.string,
        formatMessage: PropTypes.func,
        categories: PropTypes.arrayOf(PropTypes.string),
        loading: PropTypes.bool,
        options: PropTypes.arrayOf(PropTypes.object),
        error: PropTypes.bool,
        queryId: PropTypes.number,
        onSearch: PropTypes.func,
        onSelect: PropTypes.func,
        debounceTime: PropTypes.number,
        idSequence: PropTypes.func,
        openOnFocus: PropTypes.bool,
    };

    static defaultProps = {
        autoFocus: false,
        footerType: 'link',
        formatMessage: msg => msg,
        idSequence: () => ++QUERY_SEQ,
        options: [],
        queryId: 0,
        debounceTime: 300,
        openOnFocus: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            queryId: 0,
            queryTerm: '',
        };

        const { debounceTime } = props;
        if (debounceTime)
            this.handleInputChange = debounce(
                this.handleInputChange,
                debounceTime,
                {
                    trailing: true,
                }
            );
    }

    shouldComponentUpdate(nextProps) {
        const { queryId: currentQueryId } = this.state;
        const { queryId } = nextProps;

        return queryId === currentQueryId;
    }

    componentWillUnmount() {
        this._disposed = true;
    }

    formatMessage = (term, args) => {
        const { formatMessage } = this.props;
        return formatMessage(`${TERM_ID}.${term}`, args);
    };

    handleInputChange = inputValue => {
        if (this._disposed) return;

        const term = inputValue.trim();
        const { queryTerm } = this.state;
        if (term === queryTerm) return;

        const { onSearch, idSequence } = this.props;
        const queryId = idSequence();

        this.setState(
            { queryId, queryTerm: term },
            () => onSearch && onSearch({ term, queryId })
        );
    };

    handleChange = selectedValue => {
        if (this._disposed) return;

        const { onSelect, options } = this.props;

        if (onSelect) {
            const [option] = options.filter(
                ({ value }) => value === selectedValue
            );

            if (option) onSelect(option);
        }
    };

    handleFooterClick = selectedValue => {
        if (this._disposed) return;

        const { onSelect } = this.props;

        if (onSelect) onSelect(selectedValue);
    };

    noResultsText() {
        const { loading, error, categories } = this.props;
        const { queryTerm } = this.state;

        if (error)
            return (
                <div className="error-text">
                    {this.formatMessage(TERM_ERROR)}
                </div>
            );

        if (loading)
            return (
                <SearchLoadingIndicator
                    formatMessage={this.formatMessage}
                    categories={categories}
                />
            );

        if (queryTerm)
            return (
                <div className="noresults-container">
                    <SearchResultsOutline />
                    <div className="noresults-text">
                        {this.formatMessage(TERM_NORESULT, {
                            query: queryTerm,
                        })}
                    </div>
                </div>
            );

        return null;
    }

    render() {
        const {
            autoFocus,
            footerType,
            idPrefix,
            options,
            loading,
            openOnFocus,
        } = this.props;

        return (
            <div
                className={CLASSNAME}
                id={idPrefix ? `${idPrefix}-${IDNAME}` : undefined}
            >
                <span>
                    <SelectInput
                        autofocus={autoFocus}
                        labelKey="title"
                        valueKey="value"
                        options={loading ? [] : options}
                        isLoading={loading}
                        filterOptions={false}
                        placeholder={this.formatMessage(TERM_PLACEHOLDER)}
                        noResultsText={this.noResultsText()}
                        onInputChange={this.handleInputChange}
                        onChange={this.handleChange}
                        optionComponent={SearchResultOption}
                        openOnFocus={openOnFocus}
                        menuRenderer={props => (
                            <SearchResults
                                {...props}
                                footerType={footerType}
                                onFooterClick={this.handleFooterClick}
                                formatMessage={this.formatMessage}
                            />
                        )}
                    />
                    <span>
                        <SearchIcon className={`${CLASSNAME}-search`} />
                    </span>
                </span>
            </div>
        );
    }
}

export default GlobalSearch;
