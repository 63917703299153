import React from 'react';
import {
    Nav,
    Navbar,
    ExternalLinkIcon,
} from '@orchard/frontend-react-components';
import { useAppConfig } from '@theorchard/suite-config';
import PropTypes from 'prop-types';
import {
    HEADER_CLASSNAME,
    HEADER_TERM,
    ENV_PROD,
    BRAND_AWAL,
    BRAND_ORCHARD,
} from 'src/constants';
import IdentityContext from 'src/context';
import Sitemap from 'src/sitemap.json';
import { formatMessage } from 'src/utils';
import SitemapItems from '../sitemap-items';
import AppSwitcher from './app-switcher';
import HeaderAccountMenu from './header-account-menu';
import HeaderBanner from './header-banner';
import HeaderGlobalSearch from './header-global-search';
import HeaderLogo from './header-logo';
import HeaderTaxMissingBanner from './header-tax-missing-banner';
import HelpLink from './help-link';

const HEADER_CLASSNAME_EXTERNAL_LINK = `${HEADER_CLASSNAME}-external-link ${HEADER_CLASSNAME}-navItem`;
const HEADER_CLASSNAME_EXTERNAL_LINK_BETA = `${HEADER_CLASSNAME}-external-link-beta`;
const HEADER_CLASSNAME_EXTERNAL_LINK_ICON = `${HEADER_CLASSNAME}-external-link-icon`;
const HEADER_CLASSNAME_EXTERNAL_LINK_TEXT = `${HEADER_CLASSNAME}-external-link-text`;

const HEADER_TERM_INSIGHTS = `${HEADER_TERM}.insights`;
const HEADER_TERM_COLLABORATORS = `${HEADER_TERM}.collaborators`;

export const getEnvironmentBrandedDomain = config => {
    let domain = 'theorchard.com';

    if (config.brand === BRAND_ORCHARD && config.environment !== ENV_PROD)
        domain = 'qaorch.com';
    else if (config.brand === BRAND_AWAL)
        domain = config.environment === ENV_PROD ? 'awal.com' : 'qaawal.com';

    return domain;
};

export const getEnvironmentBrandedLinkInsights = (config, utmParams = {}) => {
    const campaign = utmParams?.campaign || 'insights-beta-link';
    const source = utmParams?.source || 'workstation';
    const medium = utmParams?.medium || 'link';

    const insightsDomain = getEnvironmentBrandedDomain(config);
    return `https://insights.${insightsDomain}?utm_campaign=${campaign}&utm_source=${source}&utm_medium=${medium}`;
};

export const getEnvironmentBrandedLinkCollaborators = config => {
    const collaboratorsDomain = getEnvironmentBrandedDomain(config);
    return `https://collaborators.${collaboratorsDomain}/`;
};

export const ExternalAppLink = ({
    behindFeatureFlag,
    analyticsCategory,
    url,
    text,
    betaLabel,
}) => {
    const { identity } = React.useContext(IdentityContext);

    if (
        !identity ||
        identity.isAnonymous() ||
        (behindFeatureFlag && !identity.hasFeatureFlag(behindFeatureFlag))
    )
        return '';

    const trackLinkClicked = () => {
        if (window.analytics && window.analytics.track)
            window.analytics.track('clicked', { category: analyticsCategory });
    };

    return (
        <a
            href={url}
            className={`nav-link ${HEADER_CLASSNAME_EXTERNAL_LINK}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={trackLinkClicked}
        >
            <ExternalLinkIcon className={HEADER_CLASSNAME_EXTERNAL_LINK_ICON} />
            <span className={HEADER_CLASSNAME_EXTERNAL_LINK_TEXT}>{text}</span>
            {betaLabel && (
                <span className={HEADER_CLASSNAME_EXTERNAL_LINK_BETA}>
                    {betaLabel}
                </span>
            )}
        </a>
    );
};
ExternalAppLink.propTypes = {
    behindFeatureFlag: PropTypes.string,
    analyticsCategory: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    betaLabel: PropTypes.string,
};

export const InsightsLink = () => {
    const config = useAppConfig();
    return (
        <ExternalAppLink
            behindFeatureFlag="workstation_insights_link"
            analyticsCategory="Workstation Header Insights Link"
            url={getEnvironmentBrandedLinkInsights(config)}
            text={formatMessage(HEADER_TERM_INSIGHTS)}
        />
    );
};

export const CollaboratorsLink = () => {
    const config = useAppConfig();
    const { identity } = React.useContext(IdentityContext);
    const isSubaccount = identity.accountType === 'subaccount';

    if (!identity.hasPermission([{ permission: 'accounting' }]) || isSubaccount)
        return null;

    return (
        <ExternalAppLink
            analyticsCategory="Workstation Header Collaborators Link"
            url={getEnvironmentBrandedLinkCollaborators(config)}
            text={formatMessage(HEADER_TERM_COLLABORATORS)}
            betaLabel="NEW"
        />
    );
};

const Header = ({ isEmbedOnPhp }) => {
    return (
        <React.Fragment>
            <HeaderBanner />
            <HeaderTaxMissingBanner />

            <div className={HEADER_CLASSNAME}>
                <Navbar variant="dark">
                    <Navbar.Brand>
                        <HeaderLogo isEmbedOnPhp={isEmbedOnPhp} />
                    </Navbar.Brand>

                    <Navbar.Brand className="navbar-nav">
                        <HeaderGlobalSearch isSpaPage />
                    </Navbar.Brand>

                    <Nav className="mr-auto">
                        <SitemapItems
                            className={HEADER_CLASSNAME}
                            items={Sitemap.items}
                            isEmbedOnPhp={isEmbedOnPhp}
                        />
                        <>
                            <InsightsLink />
                            <CollaboratorsLink />
                        </>
                    </Nav>

                    <Nav>
                        <HeaderAccountMenu isEmbedOnPhp={isEmbedOnPhp} />
                        <AppSwitcher />
                        <HelpLink />
                    </Nav>
                </Navbar>
            </div>
        </React.Fragment>
    );
};
Header.propTypes = {
    isEmbedOnPhp: PropTypes.bool,
};

export default Header;
