import { ModuleRegistry } from './module-registry';

const requests = {};
const registry = new ModuleRegistry();

window.define = (name, deps, callback) => registry.init(name, deps, callback);

const createAmdLoader = manifest => name => {
    if (requests[name]) return requests[name];
    if (registry.has(name)) return registry.resolve(name);

    const clearRequest = result => {
        delete requests[name];
        return result;
    };

    requests[name] = manifest
        .get(name)
        .then(({ scripts, stylesheets }) => {
            const linkElements = stylesheets.map(linkUrl => {
                const link = document.createElement('link');
                link.charset = 'utf-8';
                link.type = 'text/css';
                link.rel = 'stylesheet';
                link.href = linkUrl;
                link.crossOrigin = null;
                return link;
            });

            document.head.append(...linkElements);

            const scriptElements = scripts.map(scriptUrl => {
                const script = document.createElement('script');
                script.charset = 'utf-8';
                script.type = 'text/javascript';
                script.src = scriptUrl;
                return script;
            });

            const result = Promise.all(
                scriptElements.map(
                    script =>
                        new Promise((resolve, reject) => {
                            script.addEventListener('load', resolve);
                            script.addEventListener('error', error =>
                                reject(error)
                            );
                        })
                )
            ).then(() => registry.resolve(name));

            document.head.append(...scriptElements);

            return result;
        })
        .then(clearRequest, error => Promise.reject(clearRequest(error)));

    return requests[name];
};

export default createAmdLoader;
