import LocalizationManager from '@orchard/frontend-localization';
import { WORKSTATION_UTILS, DEFAULT_LOCALE } from 'src/constants';
import scopedModule from 'src/utils/scoped-module';
import * as lib from './lib';

export const workstationUtils = scopedModule(
    (moduleName, { defaultLocale = DEFAULT_LOCALE } = {}) => {
        const mngr = new LocalizationManager(defaultLocale);

        return {
            configureLocalization: config => mngr.configure(config),
            getCurrentLocale: () => mngr.getCurrentLocale(),
            formatMessage: (key, args) => mngr.formatMessage(key, args),
            setCurrentLocale: locale => mngr.setCurrentLocale(locale),
            registerTranslations: (locales, currentLocale) =>
                mngr.registerTranslations(locales, currentLocale),
            ...lib,
        };
    }
);

export default {
    [WORKSTATION_UTILS]: workstationUtils,
    '@orchard/frontend-workstation': lib,
    '@theorchard/frontend-workstation': lib,
};
