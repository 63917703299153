import { FEATURE_DEPRECATE_ANALYTICS } from 'src/constants';

const isSubaccount = identity => {
    const { accountType } = identity;

    return accountType === 'subaccount';
};

const deprecatedAnalyticsTabs = ['/analytics/comparison'];

export const hasAccessToRoute = (identity, route) => {
    const {
        featureFlag,
        featureControl,
        permission,
        privilege = null,
        subaccountRestriction,
    } = route;
    const { permissions = permission ? [{ permission, privilege }] : null } =
        route;
    const analyticsDeprecated = identity.hasFeatureFlag(
        FEATURE_DEPRECATE_ANALYTICS
    );
    const sourceOfStreamsDisabled =
        !identity.hasFeatureControl('Source of Stream');
    const isOverviewSubheader =
        route.url === '/analytics/overview' &&
        route.key &&
        route.key.includes('subheader');

    const hasPermission = permissions
        ? identity.hasPermission(permissions)
        : true;
    const hasFeatureControl = featureControl
        ? identity.hasFeatureControl(featureControl)
        : true;
    const hasFeatureFlag = featureFlag
        ? identity.hasFeatureFlag(featureFlag)
        : true;

    if (subaccountRestriction)
        return (
            hasFeatureFlag &&
            hasPermission &&
            hasFeatureControl &&
            !isSubaccount(identity)
        );

    if (analyticsDeprecated && deprecatedAnalyticsTabs.includes(route.url))
        return false;

    if (analyticsDeprecated && sourceOfStreamsDisabled && isOverviewSubheader)
        return false;

    return hasFeatureFlag && hasPermission && hasFeatureControl;
};

export const collapseRoute = (identity, route) => {
    const { variants = [] } = route;

    const [variant = {}] = variants.filter(item =>
        hasAccessToRoute(identity, item)
    );

    return { ...route, ...variant };
};

export const collapseToVisible = (identity, items) =>
    items
        .map(item => collapseRoute(identity, item))
        .filter(item => item.url || item.match)
        .filter(item => hasAccessToRoute(identity, item));
