import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_PRODUCT, TYPE_PROJECT } from 'src/constants/catalog';
import { CLASSNAME } from '../constants';

export const TYPE_BTN_ID = {
    [TYPE_PRODUCT]: 'globalSearchProductsButton',
    [TYPE_PROJECT]: 'globalSearchProjectsButton',
};

const OptionGroupFooter = ({
    groupCount,
    totalCount,
    footerType,
    formatMessage,
    type,
    idPrefix,
    onClick,
}) => {
    if (footerType === 'link') {
        const message = formatMessage(`${type}Results`, { count: totalCount });
        return (
            <div
                className={`${CLASSNAME}-footer`}
                onClick={onClick}
                id={
                    idPrefix && TYPE_BTN_ID[type]
                        ? `${idPrefix}-${TYPE_BTN_ID[type]}`
                        : undefined
                }
            >
                {message}
                <span className="ml-1">&rarr;</span>
            </div>
        );
    }

    if (footerType === 'limited' && groupCount < totalCount)
        return (
            <div className={`${CLASSNAME}-footer-static`}>
                {formatMessage('tooManyResults')}
            </div>
        );

    return <div className={`${CLASSNAME}-footer`} />;
};
OptionGroupFooter.propTypes = {
    groupCount: PropTypes.number,
    totalCount: PropTypes.number,
    footerType: PropTypes.string,
    type: PropTypes.string,
    formatMessage: PropTypes.func,
    onClick: PropTypes.func,
    idPrefix: PropTypes.string,
};

export default OptionGroupFooter;
