import { isObject } from 'lodash';

export const ensureArray = arrayOrSingle =>
    Array.isArray(arrayOrSingle) ? arrayOrSingle : [arrayOrSingle];

export const toLookup = (array, initial = {}) =>
    Array.isArray(array)
        ? array.reduce(
              (map, key) => ({
                  ...map,
                  [key]: true,
              }),
              initial
          )
        : (isObject(array) && array) || {};
