import { FeatureFlagDictionary } from '@orchard/frontend-identity';
import { FeatureFlagDictionary as Features } from '@theorchard/suite-frontend';

export const createFeatures = (featureFlags: FeatureFlagDictionary): Features =>
    Object.entries(featureFlags).reduce(
        (result, [key, value]) => ({
            ...result,
            [key]: value === 'enabled',
        }),
        {}
    );
