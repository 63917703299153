import React from 'react';
import { Nav } from '@orchard/frontend-react-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { formatMessage } from 'src/utils';

export const LinkPropTypes = {
    className: PropTypes.string.isRequired,
    term: PropTypes.string.isRequired,
    urlMatch: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    activeClassName: PropTypes.string,
    url: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};

const LegacyLink = ({ className, activeClassName, term, url, urlMatch }) => {
    const matchingUrl = urlMatch || url;
    const urlMatchReplaceArr =
        typeof matchingUrl === 'string' ? [matchingUrl] : matchingUrl;

    const isActive = urlMatchReplaceArr.some(urlM =>
        window.location.pathname.match(urlM)
    );
    const computedClassName = cx(className, { [activeClassName]: isActive });

    return (
        <Nav.Link className={computedClassName} href={url}>
            {formatMessage(term)}
        </Nav.Link>
    );
};
LegacyLink.propTypes = LinkPropTypes;

export default LegacyLink;
