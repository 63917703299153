import React from 'react';
import { useAppConfig } from '@theorchard/suite-config';
import PropTypes from 'prop-types';
import {
    FEATURE_DEPRECATE_ANALYTICS,
    LAST_VENDOR_ID_BEFORE_ANALYTICS_DEPRECIATION,
    BRAND_AWAL,
    PERMITTED_VENDOR_IDS_AFTER_ANALYTICS_DEPRECIATION,
} from 'src/constants';
import IdentityContext from 'src/context';
import { hasAccessToRoute, collapseRoute } from 'src/utils/sitemap';
import LegacyLink from './legacy-link';
import ReactifiedLink from './reactified-link';

export const CLASSNAME_ITEM = 'navItem';

const shouldShowAnalyticsTab = identity => {
    const userIsNewerThanInsightsLaunch =
        identity.vendorId > LAST_VENDOR_ID_BEFORE_ANALYTICS_DEPRECIATION;
    const userHasAccessAfterInsightsLaunch =
        PERMITTED_VENDOR_IDS_AFTER_ANALYTICS_DEPRECIATION.includes(
            identity.vendorId
        );
    const isAnalyticsDeprecatedFeatureFlagEnabled = identity.hasFeatureFlag(
        FEATURE_DEPRECATE_ANALYTICS
    );
    const isPhysicalReportingFeatureControlEnabled = identity.hasFeatureControl(
        'Workstation Physical Reporting'
    );
    const isWorkstationAnalyticsFeatureControlEnabled =
        identity.hasFeatureControl('Workstation Analytics Overview');
    const isSourceOfStreamsFeatureControlEnabled =
        identity.hasFeatureControl('Source of Stream');
    // we have to show analytics tab for some users which were created after insights launch according to this ticket:
    // https://theorchard.atlassian.net/browse/IN-10149.
    const newUserAccessDenied =
        !userHasAccessAfterInsightsLaunch && userIsNewerThanInsightsLaunch;
    const vendorAccessDenied =
        newUserAccessDenied && !isPhysicalReportingFeatureControlEnabled;

    // see conditions table in https://theorchard.atlassian.net/browse/IN-9785
    if (vendorAccessDenied) return false;

    if (!isAnalyticsDeprecatedFeatureFlagEnabled) return true;

    if (
        !(
            isPhysicalReportingFeatureControlEnabled ||
            isWorkstationAnalyticsFeatureControlEnabled
        )
    )
        return false;
    if (
        isWorkstationAnalyticsFeatureControlEnabled &&
        !isPhysicalReportingFeatureControlEnabled &&
        !isSourceOfStreamsFeatureControlEnabled
    )
        return false;

    return true;
};

const SitemapItems = ({ items, className, isEmbedOnPhp }) => {
    const { identity } = React.useContext(IdentityContext);
    const config = useAppConfig();

    const isAnalyticsDeprecatedFeatureFlagEnabled = identity.hasFeatureFlag(
        FEATURE_DEPRECATE_ANALYTICS
    );
    const itemProps = {
        className: `${className}-${CLASSNAME_ITEM}`,
        activeClassName: `${className}-${CLASSNAME_ITEM}-active`,
    };

    const renderLink = page => {
        const { module, key: term, match: urlMatch } = page;
        let { url } = page;
        const isSubHeader = term.includes('subheader');
        const shouldChangeUrl =
            url === '/analytics/overview' || url === '/analytics/engagement';

        if (
            isAnalyticsDeprecatedFeatureFlagEnabled &&
            shouldChangeUrl &&
            !isSubHeader
        )
            url = '/analytics/splash';

        const linkProps = { ...itemProps, url, urlMatch, term };
        let ItemLink = module ? ReactifiedLink : LegacyLink;

        if (
            url.includes('/analytics') &&
            isAnalyticsDeprecatedFeatureFlagEnabled
        )
            ItemLink = ReactifiedLink;

        if (isEmbedOnPhp) ItemLink = LegacyLink;

        return <ItemLink key={term} {...linkProps} />;
    };

    const hideAnalyticsTab = !shouldShowAnalyticsTab(identity);

    return items
        .filter(item => !(hideAnalyticsTab && item.url?.includes('/analytics')))
        .filter(
            item => !(config.brand === BRAND_AWAL && item.url === '/marketing2')
        )
        .map(item => collapseRoute(identity, item))
        .filter(item => item.url && hasAccessToRoute(identity, item))
        .map(page => renderLink(page));
};

SitemapItems.propTypes = {
    isEmbedOnPhp: PropTypes.bool,
    className: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object),
};

SitemapItems.defaultProps = {
    items: [],
};

export default SitemapItems;
