import React from 'react';
import PropTypes from 'prop-types';
import IdentityContext from '../../context';
import { IdentityManager } from '../../utils';

export default class IdentityProviderComponent extends React.PureComponent {
    static propTypes = {
        user: PropTypes.object,
        featureFlags: PropTypes.object,
        children: PropTypes.element,
    };

    static childContextTypes = {
        identity: PropTypes.object,
    };

    getChildContext() {
        const { user, featureFlags } = this.props;

        if (
            !user ||
            !IdentityManager.instance.identity ||
            IdentityManager.instance.identity.id !== user.id
        ) {
            IdentityManager.instance.setFeatureFlags(featureFlags);
            IdentityManager.instance.setIdentity(user);
        }

        const { identity } = IdentityManager.instance;
        return { identity };
    }

    render() {
        const { children } = this.props;
        const context = this.getChildContext();

        return (
            <IdentityContext.Provider value={context}>
                {children}
            </IdentityContext.Provider>
        );
    }
}
