import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { TYPE_PRODUCT, TYPE_PROJECT } from 'src/constants/catalog';
import IdentityContext from 'src/context';
import queries from 'src/queries';
import { formatMessage } from 'src/utils';
import hooks from 'src/utils/hooks';
import GlobalSearch from './global-search';
import useHandleSelect from './use-handle-select';

const WorkstationGlobalSearch = ({
    autoFocus,
    idPrefix,
    idSequence,
    history,
    setActive,
    isSpaPage,
}) => {
    const { identity } = useContext(IdentityContext);

    const [doSearch, { loading, data, error, query: { queryId } = {} }] =
        hooks.useLazyRequest(queries.createGlobalSearchQuery({ identity }));

    const handleSelect = useHandleSelect({
        history,
        identity,
        setActive,
        isSpaPage,
    });

    return (
        <GlobalSearch
            autoFocus={autoFocus}
            idPrefix={idPrefix}
            onSelect={handleSelect}
            onSearch={doSearch}
            loading={loading}
            options={data}
            queryId={queryId}
            error={!!error}
            categories={[TYPE_PROJECT, TYPE_PRODUCT]}
            formatMessage={formatMessage}
            idSequence={idSequence}
        />
    );
};

WorkstationGlobalSearch.propTypes = {
    autoFocus: PropTypes.bool,
    idPrefix: PropTypes.string,
    idSequence: PropTypes.func,
    history: PropTypes.object.isRequired,
    setActive: PropTypes.func,
    isSpaPage: PropTypes.bool,
};

WorkstationGlobalSearch.defaultProps = {
    isSpaPage: false,
};

export default withRouter(WorkstationGlobalSearch);
