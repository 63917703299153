import React from 'react';
import {
    VideoIcon,
    PhysicalAudioIcon,
    DigitalAudioIcon,
} from '@orchard/frontend-react-components';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {
    PRODUCT_TYPE_FILM,
    PRODUCT_TYPE_MUSIC_VIDEO,
    PRODUCT_TYPE_PHYSICAL_AUDIO,
    PRODUCT_TYPE_DIGITAL_AUDIO,
} from 'src/constants/catalog';

export const CLASSNAME = 'GlobalSearchResults-option';
export const CLASSNAME_IMAGE = `${CLASSNAME}-image`;
export const CLASSNAME_IMAGE_IMG = `${CLASSNAME}-image-img`;
export const CLASSNAME_PLACEHOLDER = `${CLASSNAME}-image-placeholder`;
export const CLASSNAME_TYPE = `${CLASSNAME}-type`;
export const CLASSNAME_BODY = `${CLASSNAME}-body`;
export const CLASSNAME_CODE = `${CLASSNAME}-code`;
export const CLASSNAME_TITLE = `${CLASSNAME}-title`;
export const CLASSNAME_SUBTITLE = `${CLASSNAME}-subtitle`;

export default class SearchResultOption extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        option: PropTypes.shape({
            code: PropTypes.string,
            title: PropTypes.string,
            subtitle: PropTypes.string,
            imageUrl: PropTypes.string,
            subtype: PropTypes.string,
        }).isRequired,
        onSelect: PropTypes.func,
    };

    handleOnClick = () => {
        const { onSelect, option } = this.props;
        if (onSelect) onSelect(option);
    };

    renderOptionIcon = subtype => {
        switch (subtype.toLowerCase()) {
            case PRODUCT_TYPE_FILM:
            case PRODUCT_TYPE_MUSIC_VIDEO:
                return <VideoIcon />;
            case PRODUCT_TYPE_PHYSICAL_AUDIO:
                return <PhysicalAudioIcon />;
            case PRODUCT_TYPE_DIGITAL_AUDIO:
                return <DigitalAudioIcon />;
            default:
                return null;
        }
    };

    render() {
        const { className, option } = this.props;
        const { code, title, subtitle, imageUrl, subtype } = option;

        return (
            <div
                className={cx(CLASSNAME, className)}
                onClick={this.handleOnClick}
            >
                <div className={CLASSNAME_IMAGE}>
                    {imageUrl ? (
                        <img
                            className={CLASSNAME_IMAGE_IMG}
                            src={imageUrl}
                            alt=""
                        />
                    ) : (
                        <div className={CLASSNAME_PLACEHOLDER} />
                    )}
                </div>
                <div className={CLASSNAME_TYPE}>
                    {subtype && this.renderOptionIcon(subtype)}
                </div>
                <div className={CLASSNAME_BODY}>
                    <div className={CLASSNAME_TITLE}>{title}</div>
                    <div className={CLASSNAME_SUBTITLE}>{subtitle}</div>
                </div>
                <div className={CLASSNAME_CODE}>{code}</div>
            </div>
        );
    }
}
