import React from 'react';
import PropTypes from 'prop-types';
import { CLASSNAME } from '../constants';

const OptionGroupHeader = ({ message }) => (
    <div className={`${CLASSNAME}-heading`}>{message}</div>
);
OptionGroupHeader.propTypes = {
    message: PropTypes.string,
};

export default OptionGroupHeader;
