/**
 * @file Provide methods to perform get requests to ows-grass.
 * Within workstation all url paths beginning with /grass are proxied to ows-grass.
 */

import { GRAPHQL_ENDPOINT } from 'src/constants/apollo';
import { toCamel } from 'src/utils/object-keys';
import superagent, { Response, ResponseError } from 'superagent';

class ApiError extends Error {
    constructor(error: ResponseError) {
        super(
            error.response?.error
                ? error.response.error.message
                : error.message ?? 'Unknown error'
        );

        Object.assign(this, error);
        this.name = 'API Error';
    }
}

let gqlClient: object | null = null;
let apiUrl = '/grass';

/**
 * Formats the supplied response making all keys in the body camel case.
 */
const formatToCamelCase = (res: Response) => ({
    ...res,
    body: res.body && toCamel(res.body),
});

export const setApiUrl = (url: string) => {
    apiUrl = url;
};

export const getApiUrl = () => apiUrl;

export const getGrassGraphqlGatewayUrl = () =>
    `${getApiUrl()}/${GRAPHQL_ENDPOINT}`;

export const setGqlClient = (client: object) => {
    gqlClient = client;
};
export const getGqlClient = () => gqlClient;

/**
 * Send a GET request to ows-grass.
 */
export const get = async (
    path: string,
    options: {
        params?: object;
        headers?: object;
        formatResponse?: boolean;
    } = {}
) => {
    const { tokenManager } = window;
    if (!tokenManager) throw new Error('"window.tokenManager" is undefined');

    const url = `${getApiUrl()}/${path}`;
    const session = await tokenManager.getToken();

    if (!session) throw new Error('No available grass token');

    const headers = {
        'Content-Type': 'application/json',
        session,
        ...options.headers,
    };

    const formatResponse = options.formatResponse !== false;

    return await superagent
        .get(url)
        .query(options.params ?? {})
        .set(headers)
        .then(response =>
            formatResponse ? formatToCamelCase(response) : response
        )
        .catch((error: ResponseError) => {
            throw new ApiError(error);
        });
};

/**
 * Send a POST request
 */
export const post = async (
    url: string,
    {
        data,
        bearerToken,
    }: {
        data?: object;
        bearerToken: string;
    }
) =>
    await superagent
        .post(url)
        .send(data)
        .set({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearerToken}`,
        })
        .withCredentials()
        .then(response => formatToCamelCase(response))
        .catch((error: ResponseError) => {
            if (error.response) return error.response;

            throw new ApiError(error);
        });

/**
 * Send a POST request to an arbitrary api endpoint (no session nor token required)
 */
export const postPublic = async (
    url: string,
    data?: object,
    extraHeaders?: object
) => {
    const headers = {
        'Content-Type': 'application/json',
        ...extraHeaders,
    };

    return await superagent
        .post(url)
        .send(data)
        .set(headers)
        .withCredentials()
        .then(res => res.body)
        .catch(error => {
            throw new ApiError(error);
        });
};

export default {
    postPublic,
    post,
    get,
};
