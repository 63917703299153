import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-unescaped-entities, max-len */
const PrivacyPage = ({ brand }) => (
    <div id="PrivacyPage" className={`legalPage ${brand}`}>
        <p>
            <strong>Privacy Policy</strong>
        </p>
        <p>Effective Date 01/01/2020</p>

        <p>
            This Privacy Policy describes our policies on the collection, use
            and disclosure of your information in connection with your use of
            our websites, online and offline services, and emails (“Site” or
            “Sites”). Sony Music Entertainment (“Sony Music”) is a wholly owned
            subsidiary of Sony Corporation of America. Information collected on
            the Sites may be used in the manner described below by Sony Music
            Entertainment, our labels, agents, subsidiaries, trusted partners
            (such as artists and artist management) and affiliates acting on our
            behalf (collectively, “Sony Music”). When used herein, the terms
            “we”, “us” and “our” refer to Sony Music.&nbsp; When this Privacy
            Policy uses the term “personal information,” we mean information
            that identifies or is reasonably capable of identifying or being
            associated with a particular individual, such as full name, street
            address, telephone number or email address.
        </p>

        <p>
            We encourage you to review the entire Privacy Policy. Please click
            on any of the links below to review that particular section.
        </p>

        <hr className="wp-block-separator" />

        <p>
            <a href="#collection" className="sme-privacy-exclude">
                Collection and Use of Personal Information
            </a>
        </p>

        <p>
            <a href="#social" className="sme-privacy-exclude">
                Social Networks/Online Communities
            </a>
        </p>

        <p>
            <a href="#transactions" className="sme-privacy-exclude">
                Transactions
            </a>
        </p>

        <p>
            <a href="#information" className="sme-privacy-exclude">
                Information Sharing
            </a>
        </p>

        <p>
            <a href="#choice" className="sme-privacy-exclude">
                Choice/Opt-Out
            </a>
        </p>

        <p>
            <a href="#tracking" className="sme-privacy-exclude">
                Tracking Technologies
            </a>
        </p>

        <p>
            <a href="#security" className="sme-privacy-exclude">
                Security
            </a>
        </p>

        <p>
            <a href="#updating" className="sme-privacy-exclude">
                Updating Your Personal Information
            </a>
        </p>

        <p>
            <a href="#consent" className="sme-privacy-exclude">
                Consent to Processing
            </a>
        </p>

        <p>
            <a href="#additional" className="sme-privacy-exclude">
                Additional Information
            </a>
        </p>

        <p>
            <a href="#changes" className="sme-privacy-exclude">
                Changes to This Policy
            </a>
        </p>

        <p>
            <a
                href="#your-california-privacy-rights"
                className="sme-privacy-exclude"
            >
                Additional Information for California Residents
            </a>
        </p>

        <p>
            <a href="#questions" className="sme-privacy-exclude">
                Questions About This Privacy Policy
            </a>
        </p>

        <hr className="wp-block-separator" />

        <p id="collection">
            <strong>Collection and Use of Personal Information</strong>
        </p>

        <p>
            We may collect the following categories of personal information from
            you:
        </p>

        <ul>
            <li>
                Contact information, including name, email address, street or
                mailing address and telephone or mobile phone number
            </li>
            <li>
                Billing information, including billing address, order details
                and other commercial information&nbsp;
            </li>
            <li>
                Demographic information, including age, date of birth, gender,
                interests, country and zip code
            </li>
            <li>Unique identifiers, including user name and password</li>
            <li>
                Preference and other information, including product wish lists,
                order history and marketing &amp; music preferences &nbsp;
            </li>
            <li>
                Geolocation information, including your actual or approximate
                location (for example, from the IP address of your device or
                computer)
            </li>
            <li>Photos and videos that you submit on our Sites</li>
            <li>Internet and other electronic network activity</li>
        </ul>

        <p>
            We or one of our trusted partners may use this information for the
            following business purposes:
        </p>

        <ul>
            <li>
                Send you requested updates and newsletters about an artist, type
                of music or related special offers
            </li>
            <li>Send you requested product or service information</li>
            <li>
                Send a one-time email or e-card to your friends upon your
                request when you provide their email address
            </li>
            <li>Fulfill your order</li>
            <li>Send you an order confirmation</li>
            <li>
                Respond to customer service requests, questions and concerns
            </li>
            <li>
                Administer your account, including managing your preference
                center
            </li>
            <li>Process information submitted with a job application</li>
            <li>Send you marketing communications</li>
            <li>
                Administer promotions and sweepstakes you enter and notify you
                of the results
            </li>
            <li>Improve our marketing efforts</li>
            <li>
                Maintain and improve our Sites, content, products and services
            </li>
            <li>Conduct research and analysis</li>
            <li>
                Display or promote content based upon your location and/or
                interests
            </li>
            <li>Provide location services such as “tagging” and “check-in”</li>
            <li>
                Maintain the security and effective functioning of our Sites and
                your accounts
            </li>
            <li>
                Address our compliance and legal obligations and exercise our
                legal rights
            </li>
        </ul>

        <p>
            We use cookies, web beacons and similar technologies to
            automatically collect internet and other electronic network
            activity, including information about your computer as you browse
            our Site(s). &nbsp;We and our business partners may automatically
            collect information as you browse our Site(s) such as connection
            information (including the domain name and Internet Service
            Provider), browser type and version, mobile device ID, cell phone
            carrier, operating system and device type, average time spent on our
            Site(s), pages viewed, traffic to and from the websites, information
            accessed, the Internet Protocol (IP) address you use to connect to
            the Internet and related geographical information about your
            computer or mobile device, and advertising data and other standard
            weblog information. Such information is sometimes referred to as web
            analytics and/or clickstream data.
        </p>

        <p>
            When you provide us with personal information about your contacts we
            will only use this information for the specific purpose for which it
            is provided. &nbsp;
        </p>

        <p>
            If you believe that one of your contacts has provided us with your
            personal information and you would like to request that it be
            removed from our database, please&nbsp;
            <a href="https://www.sonymusic.com/feedback/">click here</a>.
        </p>

        <p>
            <em>Other Sources of Information</em>
        </p>

        <p>
            We may receive information about you from other sources and may
            combine this data with information we already have about you,
            including:
        </p>

        <ul>
            <li>
                <strong>Artists</strong>: such as marketing data from a new or
                partnering artist that is integrated with information we already
                have about you to create a more tailored fan experience;
            </li>
            <li>
                <strong>Business Partners</strong>: such as contest providers
                and other promotional partners, sub-contractors in technical,
                payment and delivery services, advertising networks, analytics
                providers and others who enable sign-on functionality and social
                network integration;
            </li>
            <li>
                <strong>Publicly-available databases</strong>;
            </li>
            <li>
                <strong>Other Applications</strong>: such as music/video
                streaming sites and social media platforms; and
            </li>
            <li>
                <strong>Digital Service Providers</strong>: if you choose to use
                features on our Sites (such as pre-save campaigns) that connect
                to your online profiles or social media accounts, we may collect
                any information that you permit through your settings on those
                connected accounts.
            </li>
        </ul>

        <p>
            This information helps us to update, expand and analyze our records,
            identify new customers and provide content and services that may be
            of interest to you.
        </p>

        <p>
            <em>Monitoring and Fraud Prevention</em>
        </p>

        <p>
            To help protect you and others, we monitor use of the Sites and we
            use the information&nbsp;we collect, including personal information,
            to identify fraudulent activities and transactions; prevent abuse of
            and investigate and/or seek prosecution for any potential threats to
            or misuse of the Sites; ensure compliance with the applicable terms
            of service and this privacy policy; investigate violations of or
            enforce these agreements; and otherwise to protect the rights and
            property of Sony Music, its partners and customers. &nbsp;Monitoring
            may result in the collection, recording, and analysis of online
            activity or communications through the Sites, and you consent to
            this monitoring by using the Sites. &nbsp;If you do not consent to
            these conditions, you must discontinue your use of the Sites.
        </p>

        <p>
            <em>Retention</em>
        </p>

        <p>
            We will retain your information for as long as your account is
            active and as needed to provide you services. &nbsp;We will use and
            retain your information as necessary to comply with our legal
            obligations, resolve disputes and enforce our agreements.
        </p>

        <p id="social">
            <strong>Social Networks/Online Communities</strong>
        </p>

        <p>
            We enable you to create a profile for accessing blogs or online
            communities for sharing information such as messages, photos and
            videos. &nbsp;Please note that we cannot control the actions of
            others with whom you may choose to share your pages and information.
            &nbsp;We may also enable you to post content from some of our Sites
            to your profile on other social networks. &nbsp;You can access these
            services from our Sites using Facebook Connect, Twitter, or other
            Open ID providers. &nbsp;These services will authenticate your
            identity and allow you to post and share certain personal or other
            information with that site. &nbsp;Please be aware that the privacy
            policy of that site will apply to any content you provide them in
            these instances. &nbsp;We do not allow these services to provide any
            personal information or content back to our Sites or your profile
            with us unless you agree. &nbsp;To request removal of your personal
            information from our blog or community forum,&nbsp;
            <a href="https://www.sonymusic.com/feedback/">contact us here</a>.
            &nbsp;If we are unable to remove your personal information, we will
            respond to your request with the applicable reasons.
        </p>

        <p>
            Widgets – Our Site(s) may include interactive mini-programs that
            provide specific services from another company (e.g., displaying
            digital news feeds, playing music, “liking” or sharing content,
            etc.), often referred to as widgets. &nbsp;You may have the
            opportunity to submit personal information, such as your email
            address, for collection through a widget. &nbsp;A widget may also
            use browser or Flash cookies and/or collect clickstream and usage
            data. Information collected by a widget is governed by the privacy
            policy of the company that created it.
        </p>

        <p id="transactions">
            <strong>Transactions</strong>
        </p>

        <p>
            You may purchase artists’ music or merchandise from some of our
            Site(s) or our Artists’ Site(s), in which case you will be directed
            to an externally hosted web page or site to complete your
            transaction. &nbsp;These sites will be clearly identified and link
            to their own privacy policies so you know which company is
            collecting your personal information. &nbsp;You should refer to
            those policies for any privacy-related concerns regarding your
            purchase.
        </p>

        <p id="information">
            <strong>Information Sharing</strong>
        </p>

        <p>
            We will only share your personal information with others as
            described in this Privacy Policy. &nbsp;We may provide your personal
            information to companies that help us with our business activities
            (e.g. shipping or processing an order, assisting us in Site
            operations, providing customer service, delivering email
            newsletters, etc.) or that assist us in improving our products,
            content, services or advertising. &nbsp;These companies are
            authorized to use your personal information only as necessary to
            provide these services.
        </p>

        <p>We may also share your personal information:</p>

        <ul>
            <li>
                With Sony Music affiliates and representatives of our current
                recording artists
            </li>
            <li>
                With affiliated Sony Group companies (i.e. any company in which
                Sony Corporation of America owns, directly or indirectly, 50% or
                more of its outstanding shares and any company with the name
                “SONY” as part of its trade name)
            </li>
            <li>
                With reputable partners in whose products or services we think
                you may have an interest, with your prior consent to do so
            </li>
            <li>
                With representatives of former recording artists, who will be
                advised in advance that your consent is required for continued
                use
            </li>
            <li>
                As required by law, such as to comply with a subpoena or similar
                legal process
            </li>
            <li>
                When we believe in good faith that disclosure is necessary to
                protect our rights, protect your safety or the safety of others,
                investigate fraud or respond to a government request
            </li>
            <li>
                In connection with a corporate change including a merger,
                acquisition or sale of assets
            </li>
            <li>To any other party with your prior consent to do so</li>
        </ul>

        <p id="choice">
            <strong>Choice/Opt-Out</strong>
        </p>

        <p>
            <em>Email and Newsletter Preferences</em>
        </p>

        <p>
            You may choose to stop receiving newsletters, updates or marketing
            emails by following the unsubscribe instructions included in such
            emails. &nbsp;Some Sites also allow you to change your email
            preferences within your online profile or by contacting us&nbsp;
            <a href="https://www.sonymusic.com/feedback/">here</a>.
        </p>

        <p>
            <em>Interest-based Advertising</em>
        </p>

        <p>
            We partner with other companies to manage our advertisements.
            &nbsp;We or our partners may use cookies or similar technologies in
            order to provide you advertising based upon your browsing activities
            and interests on our Sites and other sites, and across your
            different devices. &nbsp;If you wish to opt out of interest-based
            advertising please check your browser settings or if located in the
            European Union click&nbsp;
            <a href="http://www.youronlinechoices.eu/">here</a> for additional
            information. &nbsp;
        </p>

        <p>
            <em>Mobile Marketing</em>
        </p>

        <p>
            If you choose to join a mobile marketing list, please be aware that
            there are usually costs associated with receiving SMS (text) or MMS
            (multimedia) messages depending on your wireless carrier and plan.
            &nbsp;You should check with your mobile carrier to determine what
            charges apply before signing up to receive our updates via your
            mobile phone. &nbsp;If you would like to stop receiving messages
            from us on your mobile phone, please reply to the message with
            “STOP.” &nbsp;You may also contact your wireless carrier for
            additional information on receiving or stopping mobile messages.
        </p>

        <p>
            We may also send you push notifications from time-to-time in order
            to update you about any events or promotions that we may be running.
            &nbsp;If you no longer wish to receive these types of
            communications, you may turn them off at the device or application
            level, as applicable. &nbsp;To ensure you receive proper
            notifications, we will need to collect certain information about
            your device such as operating system and user identification
            information.
        </p>

        <p>
            <em>Geolocation</em>
        </p>

        <p>
            If you have previously enabled your computer or mobile device to
            send us location information, you may be able to opt-out from
            further allowing us to access such information by changing the
            settings on your computer or mobile device or by uninstalling the
            application.
        </p>

        <p id="tracking">
            <strong>Tracking Technologies</strong>
        </p>

        <p>
            We and our business partners use cookies and other technologies such
            as web beacons to keep track of your visits, preferences and profile
            information. &nbsp;This information is used to make our Sites easier
            to use, improve our advertising and to provide you with a more
            personal and convenient experience. &nbsp;For example, we may use
            this information to store some of your preferences or to provide you
            with tailored advertisements related to your specific interests on
            our Sites and other sites, [and across your different devices].
            &nbsp;Additionally, should you choose to enroll, we and our partners
            may use this information to allow you to participate in one of our
            fan rewards programs. &nbsp;To learn more about the use of cookies
            in online advertising, including how to manage or delete them&nbsp;
            <a href="http://www.networkadvertising.org/managing/learn_more.asp">
                click here
            </a>
            .
        </p>

        <p>
            We and some of our partners use Flash cookies to store some of your
            preferences or personal information. &nbsp;For more information on
            Flash cookies and how to manage privacy and storage settings&nbsp;
            <a href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html">
                click here
            </a>
            .
        </p>

        <p>
            In some of our email messages, we use “click-through URLs” or pixel
            tags to determine whether the email has been opened and to measure
            the effectiveness of our customer communications. &nbsp;This data
            may also be used to gauge your interest in particular topics, so we
            can deliver a more relevant, personal and convenient
            user-experience.
        </p>

        <p id="security">
            <strong>Security</strong>
        </p>

        <p>
            The security of your personal information is important to us.
            &nbsp;When you enter sensitive information on our order forms, we
            encrypt the transmission of that information using industry accepted
            security practices. &nbsp;If you choose to use another company’s
            payment option to pay for your order, its privacy statement and
            security practices will apply to your information. &nbsp;We
            encourage you to read that privacy statement before providing your
            information.
        </p>

        <p>
            We follow generally accepted industry standards to protect the
            personal information submitted to us, both during transmission and
            after receipt. &nbsp;However, no method of transmission over the
            Internet or method of electronic storage is 100% secure and we
            cannot guarantee its absolute security.&nbsp; If you have any
            questions about the security of your personal information, you can
            contact us by&nbsp;
            <a href="#contact-info">clicking here</a>.
        </p>

        <p id="updating">
            <strong>Accessing/Updating Your Personal Information</strong>
        </p>

        <p>
            Upon request we will provide you with information about whether we
            hold any of your personal information.
        </p>

        <p>
            Our Sites may offer different methods for updating your personal
            information. &nbsp;To review and update your personal information on
            artist marketing Sites, please log in to your profile/account on
            each Site where you have registered.&nbsp; If the Site does not have
            a profile/account page, you may review and update your personal
            information by&nbsp;
            <a href="https://www.sonymusic.com/feedback/">clicking here</a>.
        </p>

        <p>
            If you wish to cancel your account or request that we no longer use
            your information to provide you services, contact us by&nbsp;
            <a href="https://www.sonymusic.com/feedback/">clicking here</a>
            .&nbsp;
        </p>

        <p>We will respond to your requests within a reasonable timeframe.</p>

        <p id="consent">
            <strong>Consent to Processing</strong>
        </p>

        <p>
            Our Sites are operated in the United States. &nbsp;If you are
            located in the European Union, Canada or elsewhere outside of the
            United States, please be aware that information we collect will be
            transferred to and processed in the United States. By using our
            Sites, or providing us with any information, you fully understand
            and consent to this transfer, processing and storage of your
            information in the United States, a jurisdiction in which the
            privacy laws may not be as comprehensive as those in the country
            where you reside and/or are a citizen.
        </p>

        <p id="additional">
            <strong>Additional Information</strong>
        </p>

        <p>
            Links to Other Site(s) – Our Site(s) includes links to other site(s)
            whose privacy practices may differ from those of Sony Music.
            &nbsp;If you submit personal information to any of those sites, your
            information is governed by their privacy policies. &nbsp;We
            encourage you to carefully read the privacy policy of any site you
            visit.
        </p>

        <p>
            Children – We do not knowingly collect or attempt to collect any
            personal information from children under the age of 13. &nbsp;If you
            feel that we have unknowingly collected information from someone
            under the age of 13, please&nbsp;
            <a href="https://www.sonymusic.com/feedback/">contact us</a>{' '}
            immediately and this information will be removed.
        </p>

        <p>For more information on how to protect your children online:</p>

        <p>
            <strong>Visit&nbsp;</strong>
            <a href="http://www.onguardonline.gov/">
                <strong>www.OnGuardOnline.gov</strong>
            </a>
            <strong>&nbsp;for social networking safety tips for&nbsp;</strong>
            <a href="http://www.onguardonline.gov/topics/social-networking-sites.aspx">
                <strong>parents</strong>
            </a>
            <strong>&nbsp;and&nbsp;</strong>
            <a href="http://www.onguardonline.gov/topics/safety-tips-tweens-teens.aspx">
                <strong>youth</strong>
            </a>
            <strong>.</strong>
        </p>

        <p id="changes">
            <strong>Changes To This Policy</strong>
        </p>

        <p>
            By using our Site(s), you agree to the terms of this Privacy Policy.
            &nbsp;We may amend this Privacy Policy at any time and, as such, we
            encourage you to periodically review this page for the latest
            information on our privacy practices. &nbsp;If we make a material
            change to this Privacy Policy, we will send an email to users who
            have provided an email address or post a notice on our Site(s) prior
            to the change becoming effective. &nbsp;
        </p>

        <p id="your-california-privacy-rights">
            <strong>Additional Information for California Residents</strong>
        </p>

        <p>
            In addition to the information provided above regarding the
            categories, sources, uses, and sharing of personal information we
            collect, we are providing the following additional information to
            California residents consistent with applicable law. &nbsp;
        </p>

        <p>
            We collect the information described above (including identifiers,
            commercial information, internet or other electronic network
            activity, and geolocation data) from fans and consumers, artists,
            business partners, other applications (e.g., social networks),
            advertising networks and digital service providers.&nbsp; We may use
            this information for any of the business purposes described
            above.&nbsp; We may also share this information with the following
            categories of third parties: Sony Music and affiliated companies,
            artists, advertising networks, digital service providers and other
            business partners for such business purposes.&nbsp;
        </p>

        <p>
            In addition to the sources and categories of third parties mentioned
            above, we also may share this information with law enforcement and
            professional service companies for the limited purposes of
            addressing our compliance and legal obligations and exercising our
            legal rights.
        </p>

        <p>
            <em>California Consumer Rights</em>
        </p>

        <p>
            Subject to certain exceptions, California residents have the
            following rights: (i)&nbsp;
            <em>Access and portability</em>. &nbsp;Upon a verifiable request,
            California residents can request access to specific pieces of
            personal information that we collect, use, disclose or sell and
            information about our data practices; (ii)&nbsp;
            <em>Deletion</em>. &nbsp;Request deletion of your personal
            information; and (iii)&nbsp;
            <em>
                opt out of sharing with third parties for certain commercial
                purposes.
            </em>
            &nbsp;To the extent permitted by applicable law, we may be required
            to retain some of your personal information, and certain personal
            information is strictly necessary in order for us to fulfil the
            purposes described in this Privacy Policy.
        </p>

        <p>
            <em>Do Not Sell My Personal Information</em>
        </p>

        <p>
            We do not sell your personal information to third parties for
            money.&nbsp; However, we share personal information with other
            companies and service providers in compliance with the law in order
            to deliver more meaningful ads and other communications to
            consumers, and for other similar reasons provided in this
            policy.&nbsp;
        </p>

        <p>
            California residents have the right to opt-out of this information
            sharing. &nbsp;If you wish to exercise this right, please{' '}
            <a href="https://www.sonymusic.com/ccpa-contact-form/">
                click&nbsp;here
            </a>
            &nbsp;or call the following toll-free number&nbsp;
            <em>[</em>
            1-833-380-0676
            <em>]</em>. &nbsp;To the extent that you elect to designate an
            authorized agent to make a request on your behalf, they must select
            “An Authorized Representative” on the form
            <em>.&nbsp;</em>
            &nbsp;We will not share the personal information of California
            minors who are under the age of 16 with third parties for commercial
            purposes without affirmative consent, which can be provided at the
            point of data collection.
        </p>

        <p>
            <em>Exercising other California Consumer Rights</em>
        </p>

        <p>
            If you are a California resident and wish to exercise any of these
            rights, please: (a) contact us&nbsp;
            <a href="https://www.sonymusic.com/feedback/">here</a>
            &nbsp;with the specific nature of your request, referencing “Your
            California Privacy Rights”; or call the following toll-free
            number&nbsp;
            <em>[</em>
            1-833-380-0676
            <em>]</em>. &nbsp;When submitting your request, you must affirm that
            you are a California resident or an authorized representative for a
            California resident. &nbsp;Authorized agents must be registered with
            the California Secretary of State, and the consumer must designate
            the authorized agent by providing the authorized agent with a valid
            power of attorney or written permission to act for the
            consumer.&nbsp; All requests should be properly labelled and contain
            all the necessary information. &nbsp;You may be asked to provide
            additional proof of identification so that we can verify your
            identity and validate the request. &nbsp;Please note that you are
            limited by law in the number of requests you may submit per year.
            &nbsp;We will not discriminate against you by offering you different
            pricing or products, or by providing you with a different level or
            quality of products, based solely upon you exercising your rights
            under the CCPA.&nbsp;
        </p>

        <p>
            <em>California “Shine the Light”</em>
        </p>

        <p>
            If you are a California resident, California Civil Code Section
            1798.83 provides you the right to receive (a) information
            identifying any third party(ies) to whom we may have disclosed,
            within the preceding calendar year, your personal information for
            that third party’s direct marketing purposes; and (b) a description
            of the categories of personal information disclosed.&nbsp; To obtain
            such information free of charge, please write to us at the address
            below, or submit your request here. We will respond to these
            requests within thirty (30) days. &nbsp;Requests that come to us by
            other means may result in a delayed response.
        </p>

        <p id="questions">
            <strong>Questions About This Privacy Policy</strong>
        </p>

        <p>
            If you have questions or concerns regarding this Privacy Policy, you
            should first contact Sony Music. You can contact us about this
            Privacy Policy by writing us:
        </p>

        <p id="contact-info">
            ATTN: Sony Music Privacy Office
            <br />
            Sony Music Entertainment, Madison Avenue, 22
            <sup>nd</sup> Floor
            <br />
            New York NY 10010
        </p>

        <p>
            Or you can&nbsp;
            <a href="https://www.sonymusic.com/feedback/">click here</a> to
            submit feedback online.
        </p>
    </div>
);
PrivacyPage.propTypes = {
    brand: PropTypes.string.isRequired,
};

export default PrivacyPage;
