import React from 'react';
import { LoaderWrapper } from '@orchard/frontend-react-components';

const CLASSNAME = 'HeaderVendorIconLoader';
export const CLASSNAME_WRAPPER = `${CLASSNAME}-wrapper`;
export const CLASSNAME_ITEM = `${CLASSNAME}-item`;
export const CLASSNAME_IMAGE = `${CLASSNAME}-image`;

export const HeaderVendorIconLoaderItem = () => (
    <div className={CLASSNAME_ITEM}>
        <div className={CLASSNAME_IMAGE} />
    </div>
);

const HeaderVendorIconLoader = () => (
    <LoaderWrapper
        content={HeaderVendorIconLoaderItem}
        className={`${CLASSNAME}-wrapper`}
    />
);

export default HeaderVendorIconLoader;
