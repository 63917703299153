import React, { useEffect, useRef } from 'react';
import { DEFAULT_LOCALE } from '@orchard/frontend-localization';
import { SuiteWrapper } from '@theorchard/suite-frontend';
import { ApolloProvider } from 'react-apollo';
import { BrowserRouter } from 'react-router-dom';
import createClient from 'src/apollo';
import IdentityProvider from 'src/components/identity-provider';
import { WorkstationIdentity } from 'src/types';
import { LocalizationManager } from 'src/utils';
import Datadog from 'src/utils/datadog';

export const Wrapper: SuiteWrapper = ({ data, children }) => {
    const { config } = data;
    const { cdnUrl, brand } = config;

    const apolloClient = useRef<ReturnType<typeof createClient>>();
    const identity = data.identity as WorkstationIdentity;

    const locale =
        identity?.user?.identity?.localization ||
        identity?.locale ||
        DEFAULT_LOCALE;

    // Persist datadog configuration so it can be used in php pages.
    Datadog.persistConfig(config);

    useEffect(() => {
        if (locale !== DEFAULT_LOCALE) {
            window.ORCHARD_LOCALE = locale;
            LocalizationManager.setCurrentLocale(locale);
        }
    }, [locale]);

    useEffect(() => {
        const favIcon = document.querySelector(
            'link[rel="shortcut icon"]'
        ) as HTMLAnchorElement;

        if (favIcon?.href && cdnUrl)
            favIcon.href = `${cdnUrl}/assets/${brand}/icons/workstation_app.svg`;
    }, [cdnUrl, brand]);

    if (!identity) return <>{children}</>;

    const { user, featureFlags } = identity;

    if (!apolloClient.current)
        apolloClient.current = createClient(user, config);

    return (
        <IdentityProvider user={user} featureFlags={featureFlags}>
            <ApolloProvider client={apolloClient.current}>
                <BrowserRouter>
                    <>{children}</>
                </BrowserRouter>
            </ApolloProvider>
        </IdentityProvider>
    );
};
