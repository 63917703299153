import React from 'react';
import { useAppConfig, useAuthClient } from '@theorchard/suite-frontend';
import AppleInfoAlert from 'src/components/appleInfoAlert/appleInfoAlert';
import FooterComponent from 'src/components/footer';
import HeaderComponent from 'src/components/header';
import PageBanner from 'src/components/page-banner';
import RoutesComponent from 'src/components/routes';

export const WorkstationApplication = () => {
    const config = useAppConfig();
    const authClient = useAuthClient();

    return (
        <div className="App-container">
            <HeaderComponent />
            <PageBanner />
            <div className="page-content">
                <AppleInfoAlert />
                <RoutesComponent
                    config={config}
                    onLogout={async () => {
                        await authClient.logout();
                    }}
                />
            </div>
            <FooterComponent />
        </div>
    );
};
