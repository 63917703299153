import React from 'react';
import { LoadingIndicator } from '@orchard/frontend-react-components';
import { flatMap } from 'lodash';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
    FEATURE_DEPRECATE_ANALYTICS,
    FEATURE_REACT_LEGAL_PAGES,
    BRAND_AWAL,
    INDEX_LINK,
    CATALOG_LINK,
    LOGOFF_LINK,
    PRIVACY_LINK,
    TERMS_LINK,
} from 'src/constants';
import SiteMap from 'src/sitemap.json';
import { formatMessage, ensureArray, redirectOffReact } from '../../utils';
import { isDev } from '../../utils/environment';
import { collapseToVisible } from '../../utils/sitemap';
import ErrorComponent from '../error';
import PrivacyPage from '../legal/privacy-page';
import TermsPage from '../legal/terms-page';
import ModuleLoader from '../module-loader';
import SplashScreen from '../splashScreen';
import SubHeader from '../subheader';

export const offReactRoutes = [
    '/analytics/overview',
    '/accounting',
    '/accounting/statementshistory',
];
export const legalRoutes = [PRIVACY_LINK, TERMS_LINK];

class Routes extends React.Component {
    static propTypes = {
        config: PropTypes.object,
        siteMap: PropTypes.arrayOf(PropTypes.object),
        onLogout: PropTypes.func.isRequired,
    };

    static contextTypes = {
        identity: PropTypes.object,
    };

    static defaultProps = {
        siteMap: SiteMap.items,
    };

    _createRoutes = (items, render) =>
        flatMap(items, route => {
            const { match, url, key } = route;

            return ensureArray(match || url).map(path => (
                <Route
                    path={path}
                    key={key || path}
                    exact
                    render={props => render(route, props)}
                />
            ));
        });

    redirectToPhp = offReactPath => {
        redirectOffReact(offReactPath);
    };

    logOffUser = () => {
        const { config, onLogout } = this.props;

        onLogout(config);

        return (
            <div className="text-center">
                <p>{formatMessage('loggingOffWorkstation')}</p>
                <LoadingIndicator top={24} />
            </div>
        );
    };

    renderNotFound = () => (
        <ErrorComponent error={formatMessage('errors.pageNotFound')} />
    );

    renderModule = (module, props) => {
        const { config } = this.props;

        const moduleLoaderProps = {
            ...props,
            brand: config.brand,
        };

        return module ? (
            <ModuleLoader {...moduleLoaderProps} module={module} />
        ) : (
            <ErrorComponent error={formatMessage('errors.nonReactifiedPage')} />
        );
    };

    renderModuleRoute = props => {
        const { match: { params: { module } = {} } = {} } = props;
        return module
            ? this.renderModule(module, props)
            : this.renderNotFound();
    };

    renderSection = route => {
        const { identity } = this.context;
        const { items, className } = route;

        const visibleItems = collapseToVisible(identity, items);
        const routes = this._createRoutes(
            visibleItems,
            ({ module, ...rest }, props) =>
                this.renderModule(module, { ...props, ...rest })
        );

        const subHeaderVisible = visibleItems.some(item => !!item.url);

        return (
            <div className={className}>
                {subHeaderVisible && <SubHeader items={visibleItems} />}
                <Switch>
                    {routes}
                    <Route render={this.renderNotFound} />
                </Switch>
            </div>
        );
    };

    renderRedirectToCatalog = () => <Redirect to={CATALOG_LINK} />;

    renderLegalPage = path => {
        const { config } = this.props;

        switch (path) {
            case TERMS_LINK:
                return <TermsPage brand={config.brand} />;
            case PRIVACY_LINK:
                return <PrivacyPage brand={config.brand} />;
            default:
                return this.renderNotFound();
        }
    };

    render() {
        const { identity } = this.context;
        const { siteMap: rootItems, config } = this.props;

        if (identity.isAnonymous()) return null;

        const visibleItems = collapseToVisible(identity, rootItems);

        let routes = this._createRoutes(visibleItems, (route, props) => {
            const { items, module } = route;

            return items && items.length
                ? this.renderSection(route, props)
                : this.renderModule(module, { ...route, ...props });
        });

        if (isDev())
            routes = routes.concat([
                <Route
                    key="dev_route"
                    path="/module/:module"
                    render={this.renderModuleRoute}
                />,
            ]);

        return (
            <Switch>
                {identity.hasFeatureFlag(FEATURE_DEPRECATE_ANALYTICS) && (
                    <Route
                        render={() => <SplashScreen identity={identity} />}
                        path="/analytics/splash"
                    />
                )}

                {identity.hasFeatureFlag(FEATURE_REACT_LEGAL_PAGES) &&
                    legalRoutes.map(legalRoutePath => (
                        <Route
                            exact
                            key={legalRoutePath}
                            path={legalRoutePath}
                            component={() =>
                                this.renderLegalPage(legalRoutePath)
                            }
                        />
                    ))}

                {offReactRoutes.map(offReactPath => (
                    <Route
                        // This forces getting OFF react on SPA environment, otherwise the router will think its a 404
                        exact
                        key={offReactPath}
                        path={offReactPath}
                        component={() => this.redirectToPhp(offReactPath)}
                    />
                ))}

                {config?.brand === BRAND_AWAL && (
                    <Route
                        exact
                        path={INDEX_LINK}
                        component={this.renderRedirectToCatalog}
                    />
                )}

                <Route exact path={LOGOFF_LINK} component={this.logOffUser} />

                {routes}

                <Route render={this.renderNotFound} />
            </Switch>
        );
    }
}

export default Routes;
