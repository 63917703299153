import { Sentry } from '@theorchard/suite-frontend';
import { GRASS_SESSION_CLEAR_RELATIVE_ENDPOINT } from 'src/constants';
import api from 'src/utils/api';

const getStorageKey = (key: string) => `grassToken:${key}`;

export const restoreGrassToken = (key: string) =>
    window.localStorage.getItem(getStorageKey(key));

export const storeGrassToken = (key: string, token: string) =>
    window.localStorage.setItem(getStorageKey(key), token);

export const clearGrassToken = (key: string) =>
    window.localStorage.removeItem(getStorageKey(key));

export const endGrassSession = async (endpoint?: string) => {
    const endSessionEndpoint =
        endpoint || GRASS_SESSION_CLEAR_RELATIVE_ENDPOINT;

    try {
        await api.postPublic(endSessionEndpoint);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.warn(`Failed to call end session: "${endSessionEndpoint}"`);

        Sentry.captureException(error);
    }
};
