/* globals process */
export const ORCHARD_ENV = 'ORCHARD_ENV';
export const ORCHARD_TOKEN_MANAGER = 'tokenManager';
export const ORCHARD_LOCALE = 'ORCHARD_LOCALE';

export const ENV_TEST = 'test';
export const ENV_DEV = 'dev';
export const ENV_QA = 'qa';
export const ENV_PROD = 'prod';

// NOTE
// process.env.[KEY] is replaced by webpack during build.
// Meaning: we can not use object destructuring on the process.env object!
export const getCurrentEnv = () => process.env.ENV;

export const getEnvConfig = config => {
    const env = config.environment || getCurrentEnv();
    const isAwal = ['overdrive', 'awal'].includes(config.brand);

    const extraDummyKeys = {
        // because suite-auth has a strict validator
        appName: config.appName || 'frontend-workstation',
        cdnUrl:
            config.cdnUrl ||
            `https://${env === 'prod' ? '' : 'qa-'}cdn.theorchard.io`,
        auth0Audience:
            config.auth0Audience || 'https://workstation.qaorch.com/api',
        auth0ClientId: config.auth0ClientId || 'auth0ClientId',
        auth0Domain:
            config.auth0Domain || env === 'prod'
                ? 'login.distroauth.com'
                : 'qalogin.theorchard.com',
        datadogApplicationId:
            config.datadogApplicationId || 'datadogApplicationId',
        datadogClientToken: config.datadogClientToken || 'datadogClientToken',
    };

    return {
        ...config,
        brand: isAwal ? 'awal' : 'orchard',
        environment: env,
        graphqlUrl:
            config.graphqlUrl ||
            `https://${
                env === ENV_PROD ? '' : 'qa-'
            }ows-grass.theorchard.io/graphql-gateway/graphql`,
        zendeskKey:
            config.zendeskKey || isAwal
                ? '6312381f-4a7f-4ef3-bb5a-c825df8c46ef'
                : 'ab325f48-8a68-41ff-99e2-b50928a025b5',
        ...extraDummyKeys,
    };
};

export const getFullBrandName = brand => {
    switch (brand) {
        case 'orchard':
            return 'The Orchard';
        case 'awal':
            return 'AWAL';
        default:
            return '';
    }
};
