import React from 'react';
import { Nav } from '@orchard/frontend-react-components';
import { useAppConfig } from '@theorchard/suite-config';
import { BRAND_AWAL } from 'src/constants';
import { HEADER_CLASSNAME, HEADER_TERM } from 'src/constants/header';
import IdentityContext from 'src/context';
import { formatMessage, getCurrentLocale } from 'src/utils';
import { getHelpCenterUrl } from 'src/utils/helpcenter';
import ZendeskButton from '../zendesk-button';

const HEADER_CLASSNAME_HELP = `${HEADER_CLASSNAME}-help ${HEADER_CLASSNAME}-navItem`;
const HEADER_TERM_HELP = `${HEADER_TERM}.help`;

const HelpLink = () => {
    const { identity } = React.useContext(IdentityContext);
    const config = useAppConfig();
    const lang = getCurrentLocale();

    if (identity?.vendor?.companyBrand?.name === BRAND_AWAL)
        return <ZendeskButton />;
    else if (!identity?.vendor?.companyBrand && config.brand === BRAND_AWAL)
        return <ZendeskButton />;

    return (
        <Nav.Link
            href={getHelpCenterUrl(identity, lang)}
            className={HEADER_CLASSNAME_HELP}
        >
            {formatMessage(HEADER_TERM_HELP)}
        </Nav.Link>
    );
};

export default HelpLink;
