import { flatten, map, every, find } from 'lodash';

const authenticationHelper = (vendContactRoles, vendorRestrictedFeatures) => {
    const hasVendContactRoles = rolesToCheck => {
        if (!rolesToCheck) return true;

        if (!vendContactRoles || vendContactRoles.length === 0) return false;

        const vendorPermissions = flatten(
            map(
                vendContactRoles,
                vendContactRole => vendContactRole.role.vendorRolePermissions
            )
        );

        return every(rolesToCheck, role => {
            const hasRole = find(
                vendorPermissions,
                vendorRole =>
                    vendorRole.permission.resource.resource ===
                        role.permission &&
                    vendorRole.permission.privilege === role.privilege
            );

            return hasRole !== undefined;
        });
    };

    const hasVendorRestrictedFeatures = featuresToCheck => {
        if (!featuresToCheck) return true;

        if (!vendorRestrictedFeatures) return false;

        return every(
            featuresToCheck,
            feature => !vendorRestrictedFeatures.includes(feature)
        );
    };

    const hasAuthentication = options => {
        const { rolesToCheck, featuresToCheck } = options;

        if (
            (!rolesToCheck || !Array.isArray(rolesToCheck)) &&
            (!featuresToCheck || !Array.isArray(featuresToCheck))
        )
            throw new Error(
                'Invalid arguments. Either rolesToCheck or featuresToCheck must have values.'
            );

        return (
            hasVendContactRoles(rolesToCheck) &&
            hasVendorRestrictedFeatures(featuresToCheck)
        );
    };

    return hasAuthentication;
};

export default authenticationHelper;
