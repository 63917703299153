import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable react/no-unescaped-entities, max-len */

const TermsPage = ({ brand }) => {
    const renderAwalTerms = () => (
        <div id="awalTerms">
            <p>AWAL DISTRIBUTION AGREEMENT</p>

            <p>
                Capitalized terms (as well as “you” and “your”) are used as
                defined herein. Key definitions:
            </p>

            <p>
                <strong>
                    “Agreement” means this AWAL Distribution Agreement.
                </strong>
                <br />
                “AWAL” means AWAL Digital Limited (Company Number 4430703) with
                a registered office at 2 Canal Reach, London N1C 4DB.
                <br />
                “Digital Distribution” means distribution (or other
                authorization of use) of the Recordings by any current or future
                means or media as selected by AWAL in good faith, other than the
                distribution of physical products.
                <br />
                “Outlets” is defined in Section 1.01.
                <br />
                “Product Uploader” means the software, features and tools
                provided or made available by AWAL via which digital files can
                be added to the AWAL Portal.
                <br />
                “Properties” means all Recordings and all material to be
                delivered and exploited under this Agreement (including anything
                delivered by you (or on your behalf) under Section 1.02.
                <br />
                “Recordings” means all audio and/or audio‐visual recordings of
                commercial quality (as determined in AWAL’s sole discretion)
                delivered by You (in your sole discretion) to AWAL during the
                Term, including via the Product Uploader.
                <br />
                “Talent” means all recording artists, musicians, vocalists,
                producers, (re-)mixers, writers, musical performing artists,
                engineers, sample rights owners, songwriters, photographers,
                crew and any and all others who performed or participated in the
                making of any Properties.
                <br />
                “Term” commences on the date AWAL sends to you an email agreeing
                to be bound by these Terms & Conditions (“Effective Date”) and
                continues until terminated by either party on 30 days’ written
                notice in accordance with these Terms & Conditions.
                <br />
                “Territory” means the world.
                <br />
                “you,” “your” and “Client” each mean the individual or legal
                entity which registered your application via the AWAL website
                and, to the extent you are a legal entity, "you," “your” and
                “Client” also mean the recording artist or other individual(s)
                who own(s) the entity.
            </p>

            <p>
                <strong>1. Exclusive Distribution Rights.</strong>
                <br />
                1.01 AWAL as Exclusive Distributor. You hereby appoint AWAL as
                the exclusive distributor of the Recordings during the Term and
                throughout the Territory, via Digital Distribution. This
                appointment includes the exclusive rights to sell, copy,
                distribute, perform, sublicense, monetize and otherwise exploit
                the Recordings (or any part thereof) via any and all forms of
                Digital Distribution, including direct to retailers, digital
                service providers, direct to consumers, and to others of any
                description that exploit sound or audiovisual recordings by any
                and all means and media (whether now known or existing in the
                future) as selected by AWAL in its discretion (“Outlets”),
                including as “art tracks” (such as via YouTube), and to
                sublicense such rights to Outlets accordingly. The appointment
                also includes the right to obtain for and assign to the
                Recordings ISRC and EAN/UPC‐A codes as needed. However, you
                reserve the right to request a takedown of any applicable
                Recording on thirty (30) days’ Notice hereunder, for convenience
                or for any other reason in your sole discretion, after which
                AWAL’s exclusivity with respect to such Recording will terminate
                unless and until you elect to redeliver it to AWAL hereunder.
                <br />
                1.02 Artwork; Name & Likeness. You hereby grant AWAL and the
                Outlets the right, solely in order to exploit and promote the
                Properties, to use: (i) artists’ names, trademarks, logos,
                likenesses, photographs and biographical material, and (ii)
                album and production artwork, video stills, thumbnail images,
                and the names, likenesses and biographical material of Talent;
                but solely to the extent delivered or otherwise approved by you.
            </p>
            <p>
                <strong>2. AWAL’s Distribution Activities.</strong>
                <br />
                2.01 General. As between you and AWAL, you retain copyright
                ownership of all Recordings and expressly reserve all rights in
                and to the Properties not expressly granted to AWAL. AWAL does
                not guarantee placement of Recordings with any Outlet, and is
                not obliged to distribute Recordings delivered by you (including
                if a Recording is technically unsatisfactory or inappropriate in
                any way), but AWAL will promptly notify you as to the extent of
                any decision not to distribute and all applicable Digital
                Distribution rights in the relevant Recording will revert to you
                on AWAL’s notification. You acknowledge that the sale and
                exploitation of Properties is a highly speculative business,
                that AWAL makes no guarantee as to any degree of sales or
                exploitations, and that AWAL retains absolute discretion over
                its sales and exploitation policies.
                <br />
                2.02 Affiliates and Contractors. AWAL may elect to operate in
                part via its corporate affiliates and third-party contractors,
                and may sublicense its rights hereunder to the extent needed for
                such operations. However, AWAL remains directly liable for all
                of its payment and other obligations to you.
                <br />
                2.03 AWAL Portal Access. In connection with your Recordings,
                AWAL will provide you access to release and track-level sales
                and streaming reporting via AWAL’s workstation portal. Subject
                to applicable data protection and privacy laws and regulation,
                AWAL may use information and data arising from or generated,
                obtained, or acquired by AWAL in connection with the
                exploitation of Properties, including demographic, statistical,
                usage and anonymised data relating to such activity, including
                for purposes of calculating AWAL’s market share.
            </p>
            <p>
                <strong>3. Your Obligations.</strong>
                <br />
                3.01 Delivery. You are solely responsible, at your cost, for the
                creation and delivery to AWAL of all Properties. AWAL will not
                remix, re-master, edit or alter any Recording (except as
                required for technical or timing reasons), without your prior
                written approval. You are responsible for audio and video
                encoding costs prior to delivery. If AWAL incurs any further
                encoding costs, AWAL may in its discretion deduct such amounts
                from any payments due to you, or request reimbursement of any
                such amounts and you will promptly pay AWAL such amounts.
                <br />
                3.02 Third-Party Consents, Permissions, Obligations. In
                connection with exploitation of the Properties under this
                Agreement, you are solely responsible for all costs and expenses
                (other than storage costs in connection with the Recordings),
                and any obligations or liabilities to third parties related to
                the exploitations authorised in this Agreement. For example, you
                are responsible for (a) taxes that you owe; (b) clearing the
                rights of and paying amounts due to Talent, and any other
                persons who contributed to the Properties; (c) amounts due to
                any party for samples, or for footage in the Properties; (d)
                music publishing licenses, including synchronization, lyric use
                and mechanical licenses to the extent not covered by the
                Outlets; (e) any advertising, marketing or promotion you choose
                to do; and (f) sound recording and artwork clearances. To the
                extent you fail to cover any of the foregoing, AWAL has the
                right but not the obligation to cover them for you, and to
                deduct such amounts from any payments due to you, or request
                reimbursement of any such amounts and you will promptly pay AWAL
                such amounts.
                <br />
                3.03 Notification of Changes to Rights, Claims. Without limiting
                your warranties or your indemnification obligations, if you no
                longer have the necessary rights to an individual Recording or
                other Property, or if the Property is the subject of any
                third-party Claim (as defined below), you must promptly inform
                AWAL as set forth in Section 6 below.
                <br />
                3.04 AWAL Content Guidelines. All Recordings and other
                Properties must abide by the terms of the AWAL Content
                Guidelines (as updated from time to time), and must comply in
                all respects with all applicable “style guides” (or content
                guidelines or community guidelines, etc.) of Outlets, including
                the iTunes Style Guide and the Spotify Content Infringement
                Guidelines. If AWAL believes in good faith that you have
                violated this Section 3.04, AWAL may: (i) withhold payments to
                you until such violation is resolved and remedied to AWAL’s
                reasonable satisfaction; (ii) issue take-downs of any offending
                Recordings and other Properties; and/or (iii) terminate this
                Agreement upon notice to you, with immediate effect.
                <br />
                3.05 Fraudulent Practices. You will not, and will not authorise
                or encourage any third party to, directly or indirectly
                manipulate streams or queries, or generate impressions of or
                clicks on any ad(s), through any automated, deceptive,
                fraudulent or other invalid means, including by way of repeated
                manual clicks, the use of “bots,” the hijacking of accounts or
                otherwise. You acknowledge that doing so may result in the
                removal of some or all of your Properties from Outlets, as well
                as the withholding of royalties by such Outlets. In addition, in
                no event will you represent yourself as AWAL’s, The Orchard’s or
                Sony Music’s representative, or use any of their trademarks
                without express permission, or represent yourself as affiliated
                or connected with them in any way that is not strictly accurate,
                whether in connection with this Agreement or otherwise. Both you
                and AWAL may accurately state that your Recordings are
                “distributed by AWAL.” If AWAL believes in good faith that you
                have violated this Section 3.05, AWAL may: (i) withhold payments
                to you until such suspected fraud is resolved and remedied to
                AWAL’s reasonable satisfaction; (ii) issue take-downs of any
                offending Recordings and other Properties; and/or (iii)
                terminate this Agreement upon notice to you, with immediate
                effect.
            </p>
            <p>
                <strong>
                    4. Distribution Share; Accountings, Audits, Claim Limits,
                    Tax and Reimbursements.
                </strong>
                <br />
                4.01 Distribution Share. AWAL will pay you eighty-five percent
                (85%) of all gross receipts actually received by (or credited
                against an advance to) AWAL with respect to Digital Distribution
                of the Recordings to end users, subject to Section 4.03 below
                (your “Distribution Share”).
                <br />
                4.02 Accountings; Audits; Limitations on Claims. AWAL will
                account to you by way of a permission-limited section of your
                AWAL Portal, which generally includes historical data back to
                the Effective Date. Accounting and payments will be made
                available to you on a monthly basis, forty-five (45) days after
                the end of the month in which the applicable amounts were
                received by AWAL. Monthly balances under fifty pounds (£50) will
                be rolled into the following month and paid in the accounting
                following when your balance reaches fifty pounds (£50). If any
                Outlet issues any corrections to prior statements, or after six
                (6) months has failed to pay AWAL amounts that were previously
                paid or credited to you, or other errors are discovered, AWAL
                may adjust its accountings and payments to you and (if
                applicable) charge and/or otherwise make appropriate
                reconciliations with regards to your account accordingly. You
                have the right to audit AWAL’s books and records to verify the
                accuracy of payments. Audits are permitted once per year during
                AWAL’s normal business hours, on at least thirty (30) days’
                prior Notice, at the place where AWAL maintains such records.
                Audits are at your expense, and may only be conducted by an
                independent chartered or certified public accounting firm
                retained on a non-contingency fee basis, and only once with
                respect to any particular payment. Any lawsuit by you regarding
                any statement or payment must be commenced within one (1) year
                after the date the applicable statement covers. After such year,
                you have no right to sue AWAL in connection with any such
                statement. You irrevocably waive any longer statute of
                limitations that would otherwise apply by law.
                <br />
                4.03 Required Withholdings; Deductions; VAT; Reimbursements.
                Notwithstanding anything to the contrary in this Agreement, AWAL
                is entitled to deduct from all payments due to you (a) any sums
                (such as withholding taxes) which AWAL or its licensees or
                related entities are obliged to pay or withhold in any country
                by reason of any law or regulation, and (b) any costs or
                expenses charged to AWAL by YouTube or other applicable Outlets
                arising specifically and directly from the sale or placement of
                advertisement(s). All payments to you or charges to be made by
                AWAL under this Agreement are expressed as exclusive of VAT,
                which if applicable are payable by you in addition to the sums
                otherwise set out in this Agreement. To the extent AWAL is
                permitted under this Agreement to charge or claim back any
                amounts to you, and with respect to any costs or expenses
                approved by you (such as for additional services that you
                request), AWAL may require payment in advance, or simply recoup
                such charges against your account. If, however, you receive a
                bill from AWAL for any such charges (if for example your account
                has a negative balance after the Term), you must pay it
                promptly.
            </p>
            <p>
                <strong>5. LIMITATION OF LIABILITY.</strong>
                <br />
                EXCEPT AS SPECIFICALLY PROVIDED OTHERWISE, AND WITHOUT LIMITING
                SECTION 8, NEITHER PARTY WILL BE LIABLE TO THE OTHER PARTY FOR
                SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, PUNITIVE OR
                INCIDENTAL DAMAGES WHATSOEVER (INCLUDING LOST PROFITS OR
                GOODWILL, BUSINESS INTERRUPTION, REPUTATIONAL DAMAGE AND THE
                LIKE) ARISING OUT OF OR IN CONNECTION WITH THE PERFORMANCE,
                NON-PERFORMANCE OR BREACH OF THIS AGREEMENT. WITH RESPECT TO
                TAKE-DOWNS, PROVIDED THAT AWAL CONTINUES TO WORK WITH YOU IN
                GOOD FAITH TO SEND TAKE-DOWN NOTICES TO OUTLETS WHENEVER
                APPROPRIATE, AWAL WILL NOT BE LIABLE FOR ANY FAILURE TO EFFECT
                SUCH TAKE-DOWNS.
            </p>
            <p>
                <strong>6. Notices.</strong>
                <br />
                All formal notices with respect to this Agreement (each referred
                to hereunder as a “Notice”) will be given in writing by
                registered first class post, or by messenger or courier, or by
                nationally recognized overnight express mail service, by you to
                AWAL’s address set forth herein and to info@awal.com, and by
                AWAL to the address (or, optionally, the email address)
                associated with your account. All other instructions, approvals
                and consents may be (as directed by AWAL) sent via email or via
                the AWAL Portal.
            </p>
            <p>
                <strong>7. Representations and Warranties.</strong>
                <br />
                You warrant, represent, covenant, and agree the following: You
                have the full right and authority to enter into, perform all of
                your obligations under, and grant the rights granted in
                accordance with this Agreement; you have the right to grant to
                AWAL all rights you purport to grant hereunder or are required
                to be granted in accordance with these Terms & Conditions;
                AWAL’s exercise of its rights hereunder and use of Recordings
                and other Properties as per these Terms & Conditions will not
                violate any law or regulation of any country nor violate or
                infringe upon the rights of any third party and you have a valid
                and enforceable agreement with, and have obtained all necessary
                licenses, clearances and rights from, all third parties
                (including Talent) whose work, performances or services are
                embodied in any Recordings or other Properties to allow you to
                grant the rights granted in accordance hereof; you will be
                solely responsible for all payments, fees or royalties that may
                be due to any such party arising from AWAL’s exercise of the
                rights granted to it hereunder; none of the Recordings or other
                Properties contain material which is criminally obscene or
                defamatory, that may expose AWAL to civil or criminal
                proceedings, or that otherwise violate community standards in
                particular countries; all metadata, label copy, credits and
                similar information supplied by you is complete and accurate in
                all respects; you will not (and, where relevant, you will
                procure that artists will not) assert any “moral rights” arising
                in connection with these Terms and Conditions against AWAL, its
                licensees or agents authorised by AWAL (whether under a specific
                or blanket licence); that you are at least 18 (eighteen) years
                of age as of the date of your application via the AWAL website;
                and if you are sales tax registered, you will inform AWAL via
                the AWAL Portal and if you are UK VAT registered you will enter
                into a Self-Billing Agreement with AWAL in a form reasonably
                required by AWAL. You will comply with all applicable laws
                (including the General Data Protection Regulation (GDPR EU
                2016/679) and the California Consumer Privacy Act (CCPA)), rules
                (such as BPI and IFPI guidelines), and regulations, including
                the application of parental advisory or other warnings or
                designations. AWAL will not be required to make any payments of
                any nature to any third party for or in connection with the
                exercise or exploitation of rights by AWAL under this Agreement.
            </p>
            <p>
                <strong>8. Indemnification.</strong>
                <br />
                You will defend and indemnify AWAL (including its parents,
                subsidiaries, and affiliates, and each of their respective
                stockholders, predecessors, successors, assigns, agents,
                directors, officers, attorneys, employees and representatives)
                against any expenses or losses (including reasonable attorneys’
                fees and litigation expenses) resulting from any third-party
                claim arising from a breach, or a claim which if true would
                constitute a breach (any such claim, whether or not asserted in
                a formal legal action, a “Claim”), of any of your
                representations, warranties or obligations. You will (unless
                expressly directed otherwise by AWAL in writing on a
                case-by-case basis) be responsible for defending against any
                such Claim and for engaging counsel at your cost without any
                liability for the same being placed on AWAL, and you will at
                your cost use best efforts to cause AWAL to be dismissed from
                any proceedings. You may not settle or resolve any Claim in a
                manner that imposes any liability or obligation on AWAL, or
                affects AWAL’s rights, without AWAL’s prior written approval.
                For purposes of clarity, AWAL may cease distribution and take
                down any Properties that become subject to a Claim, and withhold
                payments to you in an amount reasonably related to the Claim and
                potential associated expenses and damages.
            </p>
            <p>
                <strong>9. Confidentiality.</strong>
                <br />
                Without AWAL’s prior written consent, you may not disclose any
                of the terms and conditions of this Agreement, any information
                you learn regarding AWAL’s business or proprietary systems, or
                any data provided to you. This does not include information
                which (a) is generally available to the public through no
                wrongful or negligent act or omission on your part; (b) you have
                received from a third party free to make such disclosure without
                breach of any legal obligation; or (c) is required to be
                disclosed pursuant to any statute, regulation, order, subpoena
                or document discovery request (as to which you will give AWAL
                prompt Notice in enough time to enable AWAL to seek a court
                order limiting or otherwise governing such disclosure). Because
                your obligations under this Section 9 are integral to AWAL’s
                business dealings, you agree that AWAL is entitled to injunctive
                relief to enforce them.
            </p>
            <p>
                <strong>10. Interpretation and Enforcement.</strong>
                <br />
                This Agreement contains the entire understanding between you and
                AWAL as to the matters herein, and supersedes all prior
                agreements, promises, assurances, warranties, representations
                and understandings between you and AWAL, whether written or
                oral, as to such matters. Other than as set out in clause 16
                below, this Agreement can only be amended by an instrument
                signed by all parties. A waiver of any term or condition of this
                Agreement in any instance is not a prospective waiver of such
                term or condition, nor of any subsequent breach. All remedies,
                rights and obligations in this Agreement are cumulative, and do
                not limit any other remedy, right or obligation. As used in this
                Agreement, “including” signifies one or more illustrative
                examples, not an exclusive list. Unless otherwise specified,
                “or” is used in its inclusive sense. The provisions of this
                Agreement are severable and distinct from one another; if any
                provision is or becomes invalid, illegal or unenforceable, the
                validity, legality or enforceability of the others will not be
                affected. If either party is in breach of this Agreement, the
                other party will permit a reasonable time to cure such breach.
                This Agreement does not give rise to any rights under a
                Contracts (Rights of Third Parties) Act 1999 to enforce any term
                of this Agreement. The Agreement is governed by the laws of
                England and Wales. In the event of any dispute arising out of or
                in relation to this agreement, including any question regarding
                its existence, validity or termination, you must seek settlement
                of that dispute by mediation (administered by the London Court
                of International Arbitration (‘LCIA’)) before having recourse to
                litigation. The mediation will be conducted in accordance with
                the LCIA's Mediation Rules then in force, which Rules are deemed
                to be incorporated by reference into this provision. Thereafter,
                the parties grant the courts of England, exclusive jurisdiction
                to hear any disputes arising out of or relating to this
                Agreement. Nothing in this provision prevents either party from
                applying to such courts for interim relief. Each party waives
                the right to request a trial by jury.
            </p>
            <p>
                <strong>11. Relationship of the Parties.</strong>
                <br />
                You and AWAL are independent contractors to each other. Nothing
                in this Agreement authorises any party as agent of the other,
                nor creates a partnership, fiduciary or employment relationship.
                Without limiting AWAL’s payment obligations to you, you have no
                right, title or interest in or to revenues received by AWAL,
                AWAL is not obligated to segregate any revenues from other
                funds, and AWAL is not a trustee, pledgeholder or fiduciary of
                amounts owed to you. AWAL acts as principal in all transactions
                with its customers.
            </p>
            <p>
                <strong>12. Assignment.</strong>
                <br />
                AWAL may assign its rights and obligations under this Agreement
                in whole to a party who agrees to be obligated hereunder. You
                may not assign this Agreement or any rights or obligations, in
                whole or in part, without AWAL’s prior written consent. Any
                purported assignment in violation of this paragraph is null and
                void ab initio.
            </p>
            <p>
                <strong>13. Force Majeure.</strong>
                <br />
                If the fulfillment of any of AWAL’s obligations is materially
                hampered due to an event not reasonably within its control
                (e.g., flood, water damage, power failure, epidemic, pandemic,
                catastrophe, terrorism, strike, war, failure of technical
                facilities, etc.), AWAL will not be in breach as a result and
                may, on Notice to you, suspend the Term for the duration of such
                event. AWAL must continue to fulfill its payment obligations, to
                the extent not affected by such event.
            </p>
            <p>
                <strong>14. Post-Term Provisions.</strong>
                <br />
                Sections 5, 6, 7, 8, 9, 10 and 14 of this Agreement survive the
                end of the Term. After the Term, if AWAL receives any income
                from Outlets on Properties, AWAL’s sole liability to you will be
                the obligation to pay you in full as though the Agreement were
                still in place, and to send renewed take-down notices whenever
                reasonably requested and appropriate.
            </p>
            <p>
                <strong>15. Further Assurances.</strong>
                <br />
                You will do such acts and execute such documents as AWAL may
                reasonably require to ensure AWAL the full benefit of this
                Agreement. In particular, you will promptly provide copies of
                all documents necessary to evidence your right and title in and
                to any particular Properties.
            </p>
            <p>
                <strong>16. Updates to Agreement.</strong>
                <br />
                AWAL reserves the right to amend or update these Terms &
                Conditions or any part thereof upon notice to you, such notice
                to be given via the AWAL portal. However, AWAL will not amend or
                update the definitions of Recordings, Term, Territory or
                Distribution Share without your prior approval, such approval to
                be given via the AWAL portal or by email.
            </p>
        </div>
    );

    const renderOrchardTerms = () => (
        <div id="orchardTerms">
            <p>
                This site (together with any information, materials and services
                made available therein) (the "Web Site"), and any related mobile
                software application(s) made available by us in connection with
                this site (together with any information, materials and services
                made available therein) (the "App"; the Web Site and App
                collectively, the "Site"), is provided by Orchard Enterprises
                NY, Inc. ("The Orchard") for your use subject to the terms of
                these terms and conditions of use and all modifications thereto
                ("Terms and Conditions").
            </p>
            <p>
                BY ACCESSING OR USING THE SITE, OR BY CLICKING A BOX INDICATING
                THAT YOU ACCEPT OR AGREE TO THESE TERMS AND CONDITIONS, YOU
                CONSENT TO BE BOUND BY THESE TERMS AND CONDITIONS. If you do not
                agree to these Terms and Conditions, do not access or use this
                Web Site. We may change these Terms and Conditions from time to
                time by notifying you of such changes by any reasonable means,
                including by posting the revised Terms and Conditions through
                the Site. Any such changes will not apply to any dispute between
                you and us arising prior to the date on which we posted the
                revised Terms and Conditions incorporating such changes, or
                otherwise notified you of such changes. Your access to or use of
                the Site following any changes to these Terms and Conditions
                will constitute your acceptance of such changes. The{' '}
                <em>Last Updated</em> legend above indicates when these Terms
                and Conditions were last changed.
                <strong> </strong>
                We may, at any time and without liability, modify or discontinue
                all or part of the Site (including access to the Site via any
                third-party links); charge, modify or waive any fees required to
                use the Site; or offer opportunities to some or all Site users.
            </p>
            <p>
                If you are an individual accessing or using the Site on behalf
                of, or for the benefit of, any corporation, partnership or other
                entity with which you are associated (an "Organization"), then
                you are agreeing to these Terms and Conditions on behalf of
                yourself and such Organization, and you represent and warrant
                that you have the legal authority to bind such Organization to
                these Terms and Conditions. References to "you" and "your" in
                these Terms and Conditions will refer to both the individual
                using the Site and to any such Organization.
            </p>
            <p>
                Sites that are linked to or from the Site may have different
                Terms and Conditions; please review the terms and conditions on
                those sites.
            </p>

            <h4 className="terms-conditions-topic">1. Limitations on Use</h4>
            <p>
                The Site is provided to you solely for purposes related to the
                digital licensing agreement between you and The Orchard (herein,
                the "Licensing Agreement"). If you fail to abide by these Terms
                &amp; Conditions, or the terms and conditions of the Licensing
                Agreement, The Orchard reserves the right to suspend or
                terminate your access to the Web Site and/or the App. Your
                access to the Site shall also terminate upon expiration or
                termination of the Licensing Agreement. Without limitation of
                the foregoing, you agree to not use the Site to upload, post,
                publish, e-mail, reproduce, distribute or otherwise transmit any
                material that violates the rights of any person or entity or
                violates any applicable governmental law, rule or regulation.
                You shall defend and indemnify The Orchard, and its parents,
                subsidiaries, affiliates, directors, officers, employees,
                shareholders, successors and assigns (collectively, the "Related
                Entities") pursuant to the applicable provisions of the
                Licensing Agreement if any third party claim results from your
                violation of the foregoing. Sections 1 (last two sentences), 2
                (last sentence), 3 (other than the first sentence) and 4 through
                12 shall survive any termination of these Terms and Conditions.
            </p>
            <h4 className="terms-conditions-topic">
                {' '}
                2. Accounts, Security, Passwords
            </h4>
            <p>
                In order to utilize this Site, you were provided with an account
                with a username and password. You are responsible for
                maintaining the strict confidentiality of your account password,
                and you shall be responsible for any access to or use of the
                Site by you or any person or entity using a password provided to
                you, whether or not such access or use has been authorized by or
                on behalf of you, and whether or not such person or entity is
                your employee or agent. You agree to immediately notify The
                Orchard of any unauthorized use of your password or account or
                any other breach of security. It is your sole responsibility to
                control the dissemination and use of your password, control
                access to and use of your account. The Orchard will not be
                liable for any loss or damage arising from your failure to
                comply with this provision.
            </p>
            <h4 className="terms-conditions-topic">3. Third Party Web Sites</h4>
            <p>
                You may be able to link to third parties' web sites from the
                Site, for example through links to various third party vendors
                referenced under "Marketing Tools." Linked sites are not
                controlled by The Orchard and these Terms and Conditions are not
                intended to govern any such third party web sites. The Orchard
                does not monitor, make any representations with respect to or
                assume any liability with respect to any third party web sites,
                including, but not limited to, any information or materials made
                available on, or any products or services that are advertised or
                available for purchase through, such third party web sites. The
                Orchard disclaims any responsibility or liability relating to
                any linked sites and does not assume any responsibility for
                their contents, products or services.
            </p>
            <h4 className="terms-conditions-topic">
                4. Intellectual Property Rights{' '}
            </h4>
            <p>
                All materials contained on the Site (other than materials
                furnished by you pursuant to the Licensing Agreement) are the
                exclusive property of The Orchard or its licensors, and The
                Orchard and its licensors reserve all applicable copyright,
                patents, trademarks, trade secrets and other intellectual
                property rights thereto.
            </p>
            <p>
                Subject to your compliance with these Terms and Conditions, and
                solely for so long as you are permitted by The Orchard to access
                and use the Web Site, you may view one (1) copy of any portion
                of the Web Site to which we provide you access hereunder.
            </p>
            <p>
                The App is licensed (not sold) to end users. Subject to your
                compliance with these Terms and Conditions, and solely for so
                long as you are permitted by The Orchard to use the App, we
                hereby permit you, on a limited, non-exclusive, revocable,
                non-transferable, non-sublicensable basis, to install and use
                the App on a mobile device that you own or control, solely for
                your use hereunder. If you fail to comply with any of the terms
                or conditions of these Terms and Conditions, you must
                immediately cease using the App and remove (that is, uninstall
                and delete) the App from your mobile device.
            </p>
            <h4 className="terms-conditions-topic">5. Privacy Policy </h4>
            <p>
                Please review our{' '}
                <u>
                    <a href="/legal/privacy">privacy policy</a>
                </u>
                . This policy provides information of which you should be aware
                when visiting or using The Orchard's Site, including our policy
                for the collection, use and dissemination of personal
                information.
            </p>
            <h4 className="terms-conditions-topic">6. Confidentiality </h4>
            <p>
                The Site is subject to the confidentiality provisions of the
                Licensing Agreement. If and to the extent the Licensing
                Agreement does not include such provisions, you agree that you
                shall hold in confidence and not communicate, transmit, publish,
                disseminate or otherwise disclose any of the Site's features,
                offerings and/or services. Such restrictions shall not apply to
                information which (i) at or after the time of disclosure becomes
                generally available to the public through no wrongful or
                negligent act or omission on your part; (ii) you receive from a
                third party free to make such disclosure without breach of any
                legal obligation; or (iii) is required to be disclosed pursuant
                to any statute, regulation, order, subpoena or document
                discovery request (as to which you shall give The Orchard prompt
                notice). The fulfillment of your obligations under this
                paragraph is integral to the success of The Orchard's business
                dealings and The Orchard shall be entitled to injunctive relief
                to enforce the provisions hereof without limitation of its other
                rights.
            </p>
            <h4 className="terms-conditions-topic">
                7. Disclaimer of Warranties{' '}
            </h4>
            <p>
                YOUR USE OF THE SITE IS AT YOUR SOLE RISK; ALL INFORMATION,
                FEATURES, OFFERINGS, MATERIALS AND SERVICES OFFERED BY OR
                THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE"
                BASIS, WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED
                <strong>, </strong>
                INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, NON-INFRINGEMENT AND TITLE. NO SUCH
                INFORMATION, FEATURES, OFFERINGS, MATERIALS OR SERVICE SHALL
                CREATE ANY WARRANTY OF ANY KIND. ANY PRODUCTS AND SERVICES
                ORDERED OR PROVIDED VIA THE SITE ARE PROVIDED BY THE ORCHARD
                SUBJECT TO THE FOREGOING, EXCEPT TO THE EXTENT, IF AT ALL,
                OTHERWISE SET FORTH IN A LICENSE AGREEMENT SEPARATELY ENTERED
                INTO IN WRITING BETWEEN YOU AND THE ORCHARD. APPLICABLE LAW MAY
                NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE
                EXCLUSION MAY NOT APPLY TO YOU.
            </p>
            <h4 className="terms-conditions-topic">
                8. Limitation of Liability{' '}
            </h4>
            <p>
                IN NO EVENT WILL THE ORCHARD, ITS RELATED ENTITES, OR ANY OF
                THEIR REPRESENTATIVES, DISTRIBUTORS, SUPPLIERS, LICENSORS,
                AGENTS OR OTHERS INVOLVED IN CREATING, SPONSORING, PROMOTING OR
                OTHERWISE MAKING AVAILABLE THE SITE AND ITS CONTENT
                (COLLECTIVELY, THE COVERED PARTIES), BE LIABLE TO ANY PERSON OR
                ENTITY WHATSOEVER FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
                EXEMPLARY, PUNITIVE, COMPENSATORY, CONSEQUENTIAL OR ANY OTHER
                DAMAGES WHATSOEVER (i) ARISING IN ANY WAY OUT OF THE SITE, OR
                ITS AVAILABILITY, USE, RELIANCE ON OR INABILITY BY YOU TO USE
                THE SITE, (ii) ARISING FROM UNAUTHORIZED ACCESS TO OR TAMPERING
                WITH YOU PERSONAL INFORMATION OR TRANSMISSIONS; OR (iii) FOR ANY
                CLAIM ATTRIBUTABLE TO ANY ALLEGED ACT OR OMISSION OF THE ORCHARD
                IN CONNECTION WITH THE SITE, OR ANY FAILURE OF THE SITE OR ITS
                FEATURES, OFFERINGS AND/OR SERVICES, OR ERRORS, OMISSIONS OR
                OTHER INACCURACIES IN, OR DESTRUCTIVE PROPERTIES OF, THE SITE.
                THIS LIMITATION OF LIABILITY SHALL APPLY, EVEN IF THE COVERED
                PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES,
                AND REGARDLESS OF THE FORM OF ACTION, WHETHER BASED IN CONTRACT,
                TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE. THE MAXIMUM
                AGGREGATE LIABILITY OF THE ORCHARD FOR ALL DAMAGES, LOSSES OR
                CAUSES OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING
                NEGLIGENCE) OR OTHERWISE, SHALL BE FIFTY DOLLARS (U.S. $50.00).
            </p>
            <p>
                SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE
                LIMITATION OF LIABILITY, IN SUCH STATES OR JURISDICTIONS, THE
                COVERED PARTIES' LIABILITY SHALL BE LIMITED TO THE EXTENT
                PERMITTED BY LAW.
            </p>
            <h4 className="terms-conditions-topic">9. Monitoring </h4>
            <p>
                We may (but have no obligation to) monitor, store, evaluate,
                analyze, alter or remove materials submitted by you before or
                after they appear on the Site, or analyze your access to or use
                of the Site. We may disclose information regarding your access
                to and use of the Site, and the circumstances surrounding such
                access and use, to anyone for any reason or purpose.
            </p>
            <h4 className="terms-conditions-topic"> 10. Code of Conduct </h4>
            <p>In connection with the Site, you must not:</p>
            <p>
                Post, transmit or otherwise make available through or in
                connection with the Site any virus, worm, Trojan horse, Easter
                egg, time bomb, spyware or other computer code, file or program
                that is or is potentially harmful or invasive or intended to
                damage or hijack the operation of, or to monitor the use of, any
                hardware, software or equipment.
            </p>
            <p>
                Use the Site for any purpose that is fraudulent or otherwise
                tortious or unlawful.
            </p>
            <p>
                Interfere with or disrupt the operation of the Site or the
                servers or networks used to make the Site available, including
                by hacking or defacing any portion of the Web Site; or violate
                any requirement, procedure or policy of such servers or
                networks.
            </p>
            <p>
                Reproduce, modify, adapt, translate, create derivative works of,
                sell, rent, lease, loan, timeshare, distribute or otherwise
                exploit any portion of (or any use of) the Site except as
                expressly authorized herein, without The Orchard's express prior
                written consent.
            </p>
            <p>
                Reverse engineer, decompile or disassemble any portion of the
                Site, except where such restriction is expressly prohibited by
                applicable law.
            </p>
            <p>
                Remove any copyright, trademark or other proprietary rights
                notice from the Site.
            </p>
            <p>
                Frame or mirror any portion of the Web Site, or otherwise
                incorporate any portion of the Web Site or App into any product
                or service, without The Orchard's express prior written consent.
            </p>
            <p>Systematically download and store Site content.</p>
            <p>
                Use any robot, spider, site search/retrieval application or
                other manual or automatic device to retrieve, index, "scrape,"
                "data mine" or otherwise gather Site content, or reproduce or
                circumvent the navigational structure or presentation of the Web
                Site, without The Orchard's express prior written consent.
            </p>
            <p>
                You are responsible for obtaining, maintaining and paying for
                all hardware and all telecommunications and other services
                needed to use the Site.
            </p>
            <h4 className="terms-conditions-topic">
                {' '}
                11. Other Important Provisions{' '}
            </h4>
            <p>
                The Site is controlled or operated (or both) from the United
                States, and is not intended to subject The Orchard to any
                non-U.S. jurisdiction or law. The Site may not be appropriate or
                available for use in some non-U.S. jurisdictions. Any use of the
                Site is at your own risk, and you must comply with all
                applicable laws, rules and regulations in doing so. We may limit
                the Site's availability at any time, in whole or in part, to any
                person, geographic area or jurisdiction that we choose.
            </p>
            <p>
                If any provision of the Terms and Conditions is held invalid or
                unenforceable, that portion shall be construed in a manner
                consistent with applicable law to reflect, as nearly as
                possible, the original intentions as reflected in the provision,
                and the other provisions of the Terms and Conditions remain in
                full force and effect. Orchard may assign its rights and
                obligations under these Terms and Conditions, in whole, or in
                part, in its sole discretion without notice to you. You may not
                assign your rights or obligations under these Terms and
                Conditions.
            </p>
            <p>
                Your warranties, representations and indemnities, as well as the
                choice of law and venue/jurisdiction provisions, of the
                Licensing Agreement are incorporated herein by reference as if
                fully stated herein. The Orchard's rights under these Terms and
                Conditions and its rights under the Licensing Agreement are
                intended to be cumulative and not limiting.
            </p>
            <p>
                If you have any questions about this policy or our site in
                general, please contact your Orchard representative.
            </p>

            <h4 className="terms-conditions-topic">
                12. Export Controls and Sanctions
            </h4>
            <p>
                You are responsible for complying with United States export
                controls and sanctions and for any violation of such controls
                and sanctions, including any United States embargoes or other
                federal rules and regulations restricting exports. You
                represent, warrant and covenant that you are not (a) located in,
                or a resident or a national of, any country subject to a U.S.
                government embargo, sanctions, or other restriction, or that has
                been designated by the U.S. government as a “terrorist
                supporting” country unless otherwise permitted pursuant to such
                rules and regulations; or (b) on any of the U.S. government
                lists of specially designated nationals or other restricted end
                users.
            </p>

            <h4 className="terms-conditions-topic">13. Apple-Specific Terms</h4>
            <p>
                In addition to your agreement with the foregoing terms and
                conditions, and notwithstanding anything to the contrary herein,
                the following provisions apply with respect to your use of any
                version of the App compatible with the iOS operating system of
                Apple Inc. ("Apple"). Apple is not a party to these Terms and
                Conditions and does not own and is not responsible for the App.
                Apple is not providing any warranty for the App except, if
                applicable, to refund the purchase price for it. Apple is not
                responsible for maintenance or other support services for the
                App and shall not be responsible for any other claims, losses,
                liabilities, damages, costs or expenses with respect to the App,
                including any third-party product liability claims, claims that
                the App fails to conform to any applicable legal or regulatory
                requirement, claims arising under consumer protection or similar
                legislation, and claims with respect to intellectual property
                infringement. Any inquiries or complaints relating to the use of
                the App, including those pertaining to intellectual property
                rights, must be directed to The Orchard. The license you have
                been granted herein is limited to a non-transferable license to
                use the App on an Apple-branded product that runs Apple's iOS
                operating system and is owned or controlled by you, or as
                otherwise permitted by the Usage Rules set forth in Apple's App
                Store Terms of Service. In addition, you must comply with the
                terms of any third-party agreement applicable to you when using
                the App, such as your wireless data service agreement. Apple and
                Apple's subsidiaries are third-party beneficiaries of these
                Terms and Conditions and, upon your acceptance of these Terms
                and Conditions, will have the right (and will be deemed to have
                accepted the right) to enforce the Terms and Conditions against
                you as a third-party beneficiary thereof; notwithstanding the
                foregoing, The Orchard's right to enter into, rescind or
                terminate any variation, waiver or settlement under these Terms
                and Conditions is not subject to the consent of any third party.
            </p>
        </div>
    );

    return (
        <div id="TermsPage" className={`legalPage ${brand}`}>
            <p>
                <strong>Terms and Conditions</strong>
            </p>
            <p>Effective Date 03/25/2019</p>

            <p>PLEASE READ THE FOLLOWING TERMS AND CONDITIONS CAREFULLY</p>

            {brand === 'awal' ? renderAwalTerms() : renderOrchardTerms()}
        </div>
    );
};
TermsPage.propTypes = {
    brand: PropTypes.string,
};

export default TermsPage;
