import { get } from 'lodash';

export const getAccountDetails = ({
    contact: {
        contactFirstName = '',
        contactLastName = '',
        contactEmail = '',
    } = {},
    subaccount,
    identity,
    vendor: {
        company = '',
        vendorId = 0,
        companyBrand = {
            name: '',
        },
    } = {},
    linkedAccounts = [],
}) => ({
    contactName: [contactFirstName, contactLastName]
        .filter(val => (val || '').trim())
        .join(' '),
    contactEmail: contactEmail || identity?.contactEmail,
    accountName: get(subaccount, 'subaccountName', company),
    accountId: get(subaccount, 'subaccountId', vendorId),
    isSubaccount: Boolean(subaccount && subaccount.subaccountId),
    defaultBrand: identity?.defaultBrand || companyBrand?.name,
    vendorName: company,
    linkedAccounts,
});
