import {
    Datadog,
    Identity,
    SuiteAppConfig,
    SuiteLogger,
} from '@theorchard/suite-frontend';

const DATADOG_STORAGE_KEY = '@suite:datadog';

type DatadogConfig = Pick<
    SuiteAppConfig,
    | 'datadogApplicationId'
    | 'datadogClientToken'
    | 'datadogService'
    | 'datadogSessionReplayRecording'
    | 'datadogTracingOrigin'
    | 'appName'
    | 'environment'
>;

const getPersistedConfig = (): DatadogConfig | undefined => {
    try {
        const json = localStorage.getItem(DATADOG_STORAGE_KEY);

        if (json) {
            const config = JSON.parse(json);

            if (
                config?.datadogApplicationId &&
                config?.datadogClientToken &&
                config?.appName
            )
                return config;

            return undefined;
        }
    } catch {
        return undefined;
    }
};

const persistConfig = (config: DatadogConfig) => {
    const {
        datadogApplicationId,
        datadogClientToken,
        datadogService,
        datadogSessionReplayRecording,
        datadogTracingOrigin,
        appName,
        environment,
    } = config;

    localStorage.setItem(
        DATADOG_STORAGE_KEY,
        JSON.stringify({
            datadogApplicationId,
            datadogClientToken,
            datadogService,
            datadogSessionReplayRecording,
            datadogTracingOrigin,
            appName,
            environment,
        })
    );
};

const init = (config?: DatadogConfig) => {
    const datadogConfig = config ?? getPersistedConfig();

    if (datadogConfig) Datadog.init(datadogConfig);
    else SuiteLogger.warn('Datadog', 'Failed to load persisted configuration');
};

const setUser = (user: Identity) => {
    Datadog.setUser(user);
};

export default { init, setUser, persistConfig };
