import TokenManagerService, {
    TokenManager,
} from '@theorchard/grass-token-manager';
import {
    DefaultAuthClient,
    Identity,
    Sentry,
} from '@theorchard/suite-frontend';
import { isEmpty } from 'lodash';
import { GRASS_SESSION_START_RELATIVE_ENDPOINT } from 'src/constants';
import { getUser } from 'src/services';
import { WorkstationConfig, WorkstationIdentity } from 'src/types';
import api from 'src/utils/api';
import { createFeatures, endGrassSession } from './utils';

export class WorkstationAuthClient extends DefaultAuthClient {
    config: WorkstationConfig;
    tokenManager: TokenManager;
    identity?: WorkstationIdentity;

    constructor(config: WorkstationConfig) {
        super(config);

        this.config = config;

        this.tokenManager = TokenManagerService({
            tokenDuration: config.grassTokenDuration,
            onRefreshFailed: () => {
                Sentry.captureMessage('Failed to refresh grass session');
                void this.logout();
            },
        });
    }

    private async startSession() {
        const { grassSessionStartEndpoint } = this.config;

        const bearerToken = await this.getTokenSilently();

        const response = await api.post(
            grassSessionStartEndpoint || GRASS_SESSION_START_RELATIVE_ENDPOINT,
            { bearerToken }
        );

        if (response.status === 401) {
            await this.logout();
            return false;
        }

        if (!response.ok)
            throw new Error(
                `Session failed to start with: ${response.status}`,
                { cause: response.error }
            );

        if (isEmpty(response.body))
            throw new Error('Invalid response', { cause: response.text });

        if (!response.body?.grassToken)
            throw new Error('No grass token in response');

        this.tokenManager.setToken({ token: response.body.grassToken });

        return true;
    }

    private handleRedirectToPhp = () => {
        const callbackParams = new URLSearchParams(window.location.search);
        const phpRedirect = callbackParams.get('phpRedirect');

        if (!phpRedirect) return false;

        window.location.assign(phpRedirect);
        return true;
    };

    override async authenticate() {
        const user = await super.authenticate();

        if (!user) return undefined;

        if (!(await this.startSession())) return undefined;

        if (this.handleRedirectToPhp()) return undefined;

        return await this.getIdentity(user);
    }

    async getIdentity(authUser: Identity) {
        const { user, featureFlags } = await getUser();

        this.identity = {
            ...authUser,
            ...user.identity,
            id: authUser.id,
            identityId: authUser.id,
            user,
            featureFlags,
            features: createFeatures(featureFlags),
        };

        return this.identity;
    }

    override async getFeatureFlags() {
        return this.identity?.features ?? {};
    }

    override async getIdentityResources() {
        return [];
    }

    override async logout(): Promise<void> {
        await endGrassSession(this.config.grassSessionClearEndpoint);

        await super.logout();
    }
}
