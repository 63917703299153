import { get as getIn } from 'lodash';
import { TYPE_PROJECT, TYPE_PRODUCT } from 'src/constants/catalog';
import { OWS_SEARCH_CATALOG_URL, OWS_SEARCH_TOKEN } from 'src/constants/urls';
import api from 'src/utils/api';

const VENDOR_PRODUCT = 'product';
const VENDOR_PROJECT = 'project';
export const RESULTS = 'results';

const SUBACCOUNT_PROJECT = 'projectSubaccount';
const SUBACCOUNT_PRODUCT = 'productSubaccount';

const VENDOR_CONTEXT = ['WORKSTATION_PROJECT', 'WORKSTATION_PRODUCT'];
const SUBACCOUNT_CONTEXT = [
    'WORKSTATION_PROJECT_SUBACCOUNT',
    'WORKSTATION_PRODUCT_SUBACCOUNT',
];

const emptyResult = { [RESULTS]: {} };
const emptyResponse = {
    [TYPE_PRODUCT]: emptyResult,
    [TYPE_PROJECT]: emptyResult,
};

const buildRequest = query => {
    const { term, vendorId, subaccountId, pageSize, currentPage } = query;
    const encodedTerm = encodeURIComponent(term);
    const contexts = subaccountId ? SUBACCOUNT_CONTEXT : VENDOR_CONTEXT;

    const params = [];
    const context = `["${contexts.join('","')}"]`;

    params.push(`context=${context}`);
    params.push(`token=${OWS_SEARCH_TOKEN}`);

    if (term) params.push(`term=${encodedTerm}`);
    if (pageSize) params.push(`page_size=${pageSize}`);
    if (currentPage) params.push(`page=${currentPage}`);
    if (vendorId || subaccountId) {
        const filter = subaccountId
            ? `subaccount_id=${subaccountId}`
            : `vendor_id=${vendorId}`;
        params.push(`filter.${filter}`);
    }

    return `${OWS_SEARCH_CATALOG_URL}?${params.join('&')}`;
};

export const catalogSearch = async query => {
    const { term, subaccountId } = query;
    if (!term || !term.trim()) return emptyResponse;

    const request = buildRequest(query);
    const { body } = await api.get(request);

    return {
        [TYPE_PROJECT]: getIn(
            body,
            subaccountId ? SUBACCOUNT_PROJECT : VENDOR_PROJECT,
            {}
        ),
        [TYPE_PRODUCT]: getIn(
            body,
            subaccountId ? SUBACCOUNT_PRODUCT : VENDOR_PRODUCT,
            {}
        ),
    };
};

export default {
    catalogSearch,
};
