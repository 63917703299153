import React, { useState, useEffect, useContext } from 'react';
import {
    OverlayTrigger,
    Button,
    Popover,
    GridGlyph,
} from '@orchard/frontend-react-components';
import { Segment } from '@theorchard/suite-frontend';
import { AppIcon } from '@theorchard/suite-icons';
import PropTypes from 'prop-types';
import IdentityContext from 'src/context';
import { services } from 'src/services/user';

const CLASSNAME = 'AppSwitcher';

const openOnNewTab = url => window.open(url, '_blank');

const AppSwitcher = () => {
    const { identity } = useContext(IdentityContext);
    const userIdentityId = identity?.identity?.id;
    const [availableApps, setAvailableApps] = useState(null);

    useEffect(() => {
        if (userIdentityId)
            services.getUserApplications(userIdentityId).then(apps => {
                setAvailableApps(apps);
            });
    }, [userIdentityId]);

    const renderAppButton = app => (
        <div
            key={app.id}
            role="button"
            tabIndex={0}
            className="GridItem"
            data-testid="appGridItem"
            onClick={() => {
                Segment.trackEvent(`Click - AppSwitcher - ${app.name}`);
                openOnNewTab(app.url);
            }}
            onKeyPress={() => openOnNewTab(app.url)}
        >
            <div className="AppButton">
                <AppIcon app={app.id} />
            </div>
            <span className="AppName">{app.name}</span>
        </div>
    );

    const renderPopupOverlay = () => (
        <Popover id="AppSwitcherPopover">
            <Popover.Content className="AppGrid">
                {availableApps.map(renderAppButton)}
            </Popover.Content>
        </Popover>
    );

    if (!availableApps) return null;

    return (
        <div className={CLASSNAME}>
            <OverlayTrigger
                placement="bottom"
                trigger="click"
                rootClose
                overlay={renderPopupOverlay()}
            >
                <Button
                    block
                    variant="link"
                    className="text-white px-2"
                    data-testid="openPopoverButton"
                >
                    <GridGlyph />
                </Button>
            </OverlayTrigger>
        </div>
    );
};

AppSwitcher.contextTypes = {
    identity: PropTypes.object,
};

export default AppSwitcher;
