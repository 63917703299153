export const TYPE_PRODUCT = 'product';
export const TYPE_PROJECT = 'project';

export const PRODUCT_TYPEID_MUSIC = 1;
export const PRODUCT_TYPEID_TV = 2;
export const PRODUCT_TYPEID_FILM = 3;

export const PRODUCT_TYPE_PHYSICAL_AUDIO = 'physical audio';
export const PRODUCT_TYPE_DIGITAL_AUDIO = 'digital audio';
export const PRODUCT_TYPE_MUSIC_VIDEO = 'music video';
export const PRODUCT_TYPE_FILM = 'film';

export const PROJECTS_URL = term => `/projects/search/${term}`;
export const PROJECT_URL = projectId => `/project/${projectId}`;

export const PRODUCTS_URL = term => `/catalog?term=${term}`;
export const PRODUCT_URL = (projectId, releaseId) =>
    `/project/${projectId}/product/${releaseId}`;
export const PRODUCT_FILM_URL = releaseId =>
    `/releasebuilder/view?release_id=${releaseId}`;
export const PRODUCT_VIDEO_URL = (projectId, releaseId) =>
    `${PRODUCT_URL(projectId, releaseId)}/video`;
