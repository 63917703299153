import React from 'react';

const IdentityContext = React.createContext({
    /** @type {Identity} */
    identity: null,

    /**
     * @deprecated
     * Legacy authorization check
     */
    hasAuthentication: () => false,
});

export default IdentityContext;
