import React from 'react';
import { WarningGlyph } from '@orchard/frontend-react-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Banner from 'src/components/banner';
import { FEATURE_TAX_BANNER } from 'src/constants/permissions';
import IdentityContext from 'src/context';

export const PERMITTED_PATH_NAMES_MISSING_BANNER = [
    '/',
    '/marketing2',
    '/alw/accounting',
    '/accounting',
    '/analytics/engagement',
    '/analytics/comparison',
    '/analytics/index',
    '/analytics/overview',
    '/analytics/audience',
    '/analytics/reporting/physical/report/product-view',
    '/analytics/filmtransparency',
    '/catalog',
    '/projects',
    '/accounting/statementshistory',
    '/accounting/collaborators',
    '/conflicts/new',
    '/artist/index',
];

const MISSING_TAX_INFO_BANNER = 'MissingTaxInfo-banner';

const MISSING_TAX_MAIL_URL = 'taxforms@theorchard.com';

export const MissingTaxInfoBanner = () => (
    <div className={MISSING_TAX_INFO_BANNER}>
        <WarningGlyph />
        <div className={`${MISSING_TAX_INFO_BANNER}-title`}>
            <span className={`${MISSING_TAX_INFO_BANNER}-title-type`}>
                ACTION REQUIRED:&nbsp;
            </span>
            We do not have a valid IRS tax form on file for your label. A valid
            tax form is required in order to make payment to your label.
            <br />
            Please submit an IRS tax form W-9 if you are a U.S. company or
            individual. Please submit an IRS tax form W-8 if you are a non-U.S.
            company or individual.
            <br />
            Email your IRS tax form and label ID to
            <a
                className={`${MISSING_TAX_INFO_BANNER}-url`}
                href={`mailto:${MISSING_TAX_MAIL_URL}`}
            >
                {MISSING_TAX_MAIL_URL}
            </a>
        </div>
    </div>
);

export const HeaderTaxMissingBanner = ({ location }) => {
    const { identity } = React.useContext(IdentityContext);
    const { pathname } = location;

    if (!PERMITTED_PATH_NAMES_MISSING_BANNER.includes(pathname)) return null;

    const hasMissingTaxInfo = identity.hasFeatureFlag(FEATURE_TAX_BANNER);

    const renderMissingTaxInfoBanner = () => {
        if (hasMissingTaxInfo)
            return (
                <Banner
                    canClose={false}
                    cookieName={MISSING_TAX_INFO_BANNER}
                    className={MISSING_TAX_INFO_BANNER}
                    bannerDismissed={false}
                    dismissBanner={() => {}}
                >
                    <MissingTaxInfoBanner />
                </Banner>
            );

        return null;
    };

    return (
        <React.Fragment>
            <div>{renderMissingTaxInfoBanner()}</div>
        </React.Fragment>
    );
};

HeaderTaxMissingBanner.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default withRouter(HeaderTaxMissingBanner);
