export const HEADER_CLASSNAME = 'Header';
export const HEADER_TERM = 'header';

export const INDEX_LINK = '/';
export const INDEX_PHP_LINK = '/index2';
export const CATALOG_LINK = '/catalog';
export const ANALYTICS_LINK = '/analytics';
export const ACCOUNTING_LINK = '/accounting';
export const ADMIN_LINK = '/account/admin';
export const ASSETS_LINK = '/account/assets';
export const PROFILE_LINK = '/account/myprofile';
export const DEFAULTS_LINK = '/account/labeldefaults';
export const LOGOUT_LINK = '/logout';
export const LOGOFF_LINK = '/logoff';

export const PRIVACY_LINK = '/legal/privacy';
export const TERMS_LINK = '/legal/terms';

export const SWITCH_USER_LINK = (id, switchSessId) =>
    switchSessId
        ? `/login/switch?user=${id}&key=${switchSessId}`
        : `/login/switch?user=${id}`;
