import React from 'react';
import { Modal, CloseIcon } from '@orchard/frontend-react-components';
import { useAppConfig } from '@theorchard/suite-config';
import PropTypes from 'prop-types';
import { formatMessage, getGoAppName } from 'src/utils';

const CLASS_NAME = 'Promo-modal';

const PromoModal = props => {
    const config = useAppConfig();
    const { shouldOpenModal, handleModalClose } = props;

    return (
        <div>
            <Modal
                className={`${CLASS_NAME} ${config.brand}`}
                onRequestClose={handleModalClose}
                isOpen={shouldOpenModal}
            >
                <div className={`${CLASS_NAME}-content`}>
                    <CloseIcon
                        className={`${CLASS_NAME}-close-icon`}
                        onClick={handleModalClose}
                    />
                    <img
                        className={`${CLASS_NAME}-content-logo`}
                        alt="Go Logo"
                        src={`https://cdn.theorchard.io/assets/workstation/go-popup/${config.brand}/go-icon.png`}
                    />
                    <div className={`${CLASS_NAME}-content-text`}>
                        {formatMessage('promoModal.orchardGO.description', {
                            goAppName: getGoAppName(config.brand),
                        })}
                    </div>
                    <div className={`${CLASS_NAME}-content-text`}>
                        {formatMessage('promoModal.orchardGO.downloadMessage', {
                            goAppName: getGoAppName(config.brand),
                        })}
                    </div>
                    <img
                        className={`${CLASS_NAME}-content-barcodes`}
                        alt="GO Barcodes"
                        src={`https://cdn.theorchard.io/assets/workstation/go-popup/${config.brand}/stores-qrs.png`}
                    />
                </div>
            </Modal>
        </div>
    );
};

PromoModal.propTypes = {
    shouldOpenModal: PropTypes.bool,
    handleModalClose: PropTypes.func,
};

export default PromoModal;
