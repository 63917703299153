/* globals process */
import TokenManagerService from '@theorchard/grass-token-manager';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setGqlClient, getApiUrl } from 'src/utils/api';
import { isProd } from 'src/utils/environment';
import fetch from 'unfetch';
import { GRAPHQL_ENDPOINT } from './constants';

const createClient = (user, config = {}) => {
    const clientName = 'frontend-workstation';
    const tokenManager = window.tokenManager || TokenManagerService();

    const httpLink = createHttpLink({
        uri: config.graphqlUrl || `${getApiUrl()}${GRAPHQL_ENDPOINT}`,
        fetch,
    });

    const middlewareLink = new ApolloLink((operation, forward) => {
        const headers = {
            session: tokenManager?.token || config?.session?.token?.token,
            'Orchard-Identity-Id': user?.identity?.id,
            'Orchard-Profile-Type': 'LabelProfile',
            'Orchard-Profile-Id': user.id,
            'apollographql-client-name': clientName,
            // eslint-disable-next-line
            'apollographql-client-version':
                config?.appVersion ||
                // eslint-disable-next-line camelcase
                process?.env?.npm_package_version ||
                '1.0.0',
        };

        operation.setContext({
            headers,
        });
        if (forward) return forward(operation);
        return null;
    });

    const link = middlewareLink.concat(httpLink);
    const client = new ApolloClient({
        connectToDevTools: !isProd(),
        name: clientName,
        link,
        cache: new InMemoryCache(),
    });

    setGqlClient(client);
    return client;
};

export default createClient;
