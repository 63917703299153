import React from 'react';
import { ChevronIcon } from '@orchard/frontend-react-components';
import PropTypes from 'prop-types';
import { HEADER_CLASSNAME } from 'src/constants/header';

const CLASSNAME_TITLE = `${HEADER_CLASSNAME}-dropdownTitle`;

const HeaderAccountDropdownTitle = ({
    contactName,
    accountName,
    accountId,
}) => (
    <div className={CLASSNAME_TITLE} id="HeaderAccountDetails">
        <div>
            <div>{contactName}</div>
            <div>
                <span className="font-weight-bold pr-1">{accountName}</span>
                <span>{`ID: ${accountId}`}</span>
            </div>
        </div>
        <ChevronIcon />
    </div>
);
HeaderAccountDropdownTitle.propTypes = {
    contactName: PropTypes.string,
    accountName: PropTypes.string,
    accountId: PropTypes.number,
};

export default HeaderAccountDropdownTitle;
