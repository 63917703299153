import { HOME_PAGE_TITLE } from '../constants/constants';

export const setPageTitle = (title, brandName = '') => {
    if (!title)
        throw new Error('Invalid arguments. Page title cannot be empty');
    window.document.title =
        title === HOME_PAGE_TITLE
            ? title
            : `${title} | ${HOME_PAGE_TITLE} ${brandName}`;
};
