import React from 'react';
import { Sentry } from '@theorchard/suite-frontend';
import PropTypes from 'prop-types';
import ErrorComponent from '../error';

export class ErrorBoundary extends React.Component {
    static propTypes = {
        children: PropTypes.element,
    };

    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error) {
        Sentry.captureException(error);

        this.setState({ error });
    }

    render() {
        const { children } = this.props;
        const { error } = this.state;

        if (error) return <ErrorComponent error={error} />;

        return children;
    }
}

const withErrorBoundary = Component => props =>
    (
        <ErrorBoundary>
            <Component {...props} />
        </ErrorBoundary>
    );

export default withErrorBoundary;
