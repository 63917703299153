import React from 'react';
import cx from 'classnames';
import { groupBy, flatMap } from 'lodash';
import PropTypes from 'prop-types';
import OptionGroupFooter from './option-group-footer';
import OptionGroupHeader from './option-group-header';

class SearchResults extends React.Component {
    static propTypes = {
        focusedOption: PropTypes.object,
        footerType: PropTypes.string,
        optionClassName: PropTypes.string,
        optionComponent: PropTypes.func,
        optionRenderer: PropTypes.func,
        valueArray: PropTypes.arrayOf(PropTypes.object),
        valueKey: PropTypes.string,
        onOptionRef: PropTypes.func,
        onSelect: PropTypes.func,
        onFooterClick: PropTypes.func,
        options: PropTypes.arrayOf(PropTypes.object),
        idPrefix: PropTypes.string,
        formatMessage: PropTypes.func,
    };

    static defaultProps = {
        options: [],
        formatMessage: msg => msg,
    };

    renderGroup = group => {
        const {
            focusedOption,
            optionClassName,
            optionComponent: Option,
            optionRenderer,
            valueArray,
            valueKey,
            onOptionRef,
            ...props
        } = this.props;

        return group.map((option, i) => {
            const isSelected = valueArray && valueArray.indexOf(option) > -1;
            const isFocused = option === focusedOption;
            const optionClass = cx(optionClassName, {
                'Select-option': true,
                'is-selected': isSelected,
                'is-focused': isFocused,
                'is-disabled': option.disabled,
            });

            return (
                <Option
                    {...props}
                    className={optionClass}
                    isDisabled={option.disabled}
                    isFocused={isFocused}
                    isSelected={isSelected}
                    key={`option-${option[valueKey]}`}
                    option={option}
                    optionIndex={i}
                    ref={ref => onOptionRef && onOptionRef(ref, isFocused)}
                >
                    {optionRenderer(option, i)}
                </Option>
            );
        });
    };

    render() {
        const { footerType, options, idPrefix, onFooterClick, formatMessage } =
            this.props;

        return flatMap(
            groupBy(options, ({ type }) => type),
            (group, type) => {
                const [{ count = 0, term } = {}] = group;

                const headerMessage = formatMessage(`${type}Plural`);

                return [
                    <OptionGroupHeader
                        key={`header-${type}`}
                        message={headerMessage}
                    />,
                    ...this.renderGroup(group),
                    <OptionGroupFooter
                        groupCount={group.length}
                        totalCount={count}
                        footerType={footerType}
                        formatMessage={formatMessage}
                        key={`footer-${type}`}
                        type={type}
                        idPrefix={idPrefix}
                        onClick={() =>
                            onFooterClick && onFooterClick({ type, term })
                        }
                    />,
                ];
            }
        );
    }
}

export default SearchResults;
