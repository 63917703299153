import React from 'react';
import PropTypes from 'prop-types';
import ErrorComponent from '../error';

const LoadingIndicator = ({ error }) =>
    error ? <ErrorComponent error={error} /> : <div />;
LoadingIndicator.propTypes = {
    error: PropTypes.shape({
        message: PropTypes.string,
    }),
};

export default LoadingIndicator;
