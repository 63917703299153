import gql from 'graphql-tag';

const GET_USER_APPLICATIONS_QUERY = gql`
    query getUserApplications($identityId: String!) {
        identityById(identityId: $identityId) {
            id
            applications {
                id
                name
                url
            }
        }
    }
`;

export default GET_USER_APPLICATIONS_QUERY;
